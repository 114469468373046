import { useState, useEffect } from "react";

import Title from "../../../../../topography/title/Title";
import Paragraph from "../../../../../topography/paragraph/Paragraph";

import "./Item.css";

export default function Item({ parentClass, itemData, picSrc }) {
  const [picHref, setPicHref] = useState(picSrc);

  useEffect(() => {
    let done = false;

    if (!done) {
      setPicHref(picSrc);
    }

    return () => {
      done = true;
    };
  }, [picSrc]);

  const itemClass = "item";
  const componentClass = `${parentClass}__${itemClass}`;
  const imgClass = `${itemClass}__img`;

  return (
    <li className={componentClass}>
      <img className={imgClass} src={picHref} alt="item icon" />
      <Title parentClass={itemClass} index={3}>
        {itemData.title.toUpperCase()}
      </Title>
      <Paragraph parentClass={itemClass}>{itemData.text}</Paragraph>
    </li>
  );
}
