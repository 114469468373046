import { useContext } from "react";
import { StagesContext } from "../../../../contexts/StagesContext";

import Title from "../../../topography/title/Title";
import Paragraph from "../../../topography/paragraph/Paragraph";
import Star from "../../../ui/star/Star";

import "./Stages.css";

export default function Stages({ parentClass }) {
  const { langData } = useContext(StagesContext);

  const componentClass = "stages";
  const itemClass = `item`;

  if (Boolean(langData)) {
    return (
      <section
        className={`${parentClass}__${componentClass} ${componentClass}`}
        id="stages">
        <Title parentClass={componentClass} index={2}>
          {langData.title.toUpperCase()}
        </Title>
        <ul className={`${componentClass}__${itemClass}s`}>
          {langData.itemsText.map((itemText, id) => (
            <li
              key={`item-${id}`}
              className={`${componentClass}__${itemClass} ${itemClass}`}>
              <Star parentClass={itemClass} innerText={id} />
              <Paragraph parentClass={itemClass}>{itemText}</Paragraph>
            </li>
          ))}
        </ul>
      </section>
    );
  }

  return null;
}
