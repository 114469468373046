import { FooterProvider } from "../../../contexts/FooterContext";
import { CoursesHeroProvider } from "../../../contexts/CoursesHeroContext";
import { SuitableProvider } from "../../../contexts/SuitableContext";
import { FindProvider } from "../../../contexts/FindContext";
import { StagesProvider } from "../../../contexts/StagesContext";
import { CuratorProvider } from "../../../contexts/CuratorContext";
import { FormatProvider } from "../../../contexts/FormatContext";
import { SignUpProvider } from "../../../contexts/SignUpContext";
// import { StudentsProvider } from "../../../contexts/StudentsContext";

import Footer from "../../footer/Footer";
import CoursesHero from "../courses/hero/CoursesHero";
import Suitable from "./suitable/Suitable";
import Find from "./find/Find";
import Stages from "./stages/Stages";
import Curator from "./curator/Curator";
import Format from "./format/Format";
import SignUp from "./sign-up/SignUp";
// import Students from "./students/Students";

import "./Courses.css";

export default function Courses() {
  const componentClass = "courses";

  return (
    <>
      <main className={componentClass} id={componentClass}>
        <CoursesHeroProvider>
          <CoursesHero parentClass={componentClass} />
        </CoursesHeroProvider>
        <FormatProvider>
          <Format parentClass={componentClass} />
        </FormatProvider>
        <SuitableProvider>
          <Suitable parentClass={componentClass} />
        </SuitableProvider>
        <SignUpProvider>
          <SignUp parentClass={componentClass} />
        </SignUpProvider>
        <FindProvider>
          <Find parentClass={componentClass} />
        </FindProvider>
        <StagesProvider>
          <Stages parentClass={componentClass} />
        </StagesProvider>
        {/* <StudentsProvider>
          <Students parentClass={componentClass} />
        </StudentsProvider> */}
        <CuratorProvider>
          <Curator parentClass={componentClass} />
        </CuratorProvider>
      </main>
      <FooterProvider>
        <Footer />
      </FooterProvider>
    </>
  );
}
