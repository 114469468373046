import { Link } from "react-router-dom";
import { useContext } from "react";
import { MenuVisibleContext } from "../../../../contexts/MenuVisibleContext";

import "./NavLink.css";

export default function NavLink({ parentClass, path, children }) {
  const { setIsMenuVisible } = useContext(MenuVisibleContext);

  const componentClass = `${parentClass}__link`;

  function handleClick(path) {
    setIsMenuVisible(false);

    setTimeout(() => {
      const elemId = path.match(/[^/#]+$/);
      const domElem = document.getElementById(elemId);

      if (Boolean(domElem)) {
        domElem.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }, 600);
  }

  return (
    <Link
      to={path}
      className={componentClass}
      onClick={() => handleClick(path)}>
      {children}
    </Link>
  );
}
