import { useContext } from "react";
import { CompanyHeroContext } from "../../../../contexts/CompanyHeroContext";

import BottomLine from "./bot-line/BottomLine";
import Body from "./body/Body";

import "./Hero.css";

export default function Hero({ parentClass }) {
  const { langData } = useContext(CompanyHeroContext);

  const componentClass = "hero";

  if (Boolean(langData)) {
    return (
      <div className={`${parentClass}__${componentClass}-bg`}>
        <section
          className={`${parentClass}__${componentClass} ${componentClass}`}
          id="companyHero">
          <Body parentClass={componentClass} />
          <BottomLine parentClass={componentClass} />
        </section>
      </div>
    );
  }

  return null;
}
