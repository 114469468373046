import { useContext } from "react";
import { CoursesHeroContext } from "../../../../../contexts/CoursesHeroContext";

import ColoredTitle from "../../../../topography/colored-title/ColoredTitle";
import ColoredParagraph from "../../../../topography/colored-paragraph/ColoredParagraph";
import NavLink from "../../../../ui/links/nav-link/NavLink";

import "./Content.css";

export default function Content({ parentClass }) {
  const { langData } = useContext(CoursesHeroContext);

  const componentClass = "content";
  const botBoxClass = `${componentClass}__bottom-box`;

  if (Boolean(langData)) {
    return (
      <div className={`${parentClass}__${componentClass} ${componentClass}`}>
        <ColoredTitle
          parentClass={componentClass}
          textAfter={langData.title.textAfter.toUpperCase()}
          textBefore={langData.title.textBefore.toUpperCase()}
          coloredText={langData.title.coloredText.toUpperCase()}
        />
        <div className={botBoxClass}>
          <ColoredParagraph
            parentClass={componentClass}
            textAfter={langData.subTitle.textAfter}
            textBefore={langData.subTitle.textBefore}
            coloredText={langData.subTitle.coloredText}
          />
          <NavLink parentClass={componentClass} path="/courses/#find">
            {langData.btnText.toUpperCase()}
          </NavLink>
        </div>
      </div>
    );
  }

  return null;
}
