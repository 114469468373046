import { useContext } from "react";
import { FooterContext } from "../../../contexts/FooterContext";

import NavLink from "../../ui/links/nav-link/NavLink";
// import NetworkLink from "../../ui/links/network-link/NetworkLink";
import Title from "../../topography/title/Title";

export default function InfoLinks({ parentClass }) {
  const { langData, footerData } = useContext(FooterContext);

  const componentClass = "info-links";

  if (Boolean(langData) && Boolean(footerData)) {
    return (
      <div className={`${parentClass}__${componentClass} ${componentClass}`}>
        <Title parentClass={componentClass} index={3}>
          {langData.titles.infoTitle}
        </Title>
        <ul className={`${componentClass}__items`}>
          {langData.infoLinkTexts.map((navLinkText, id) => (
            <li key={id} className={`${componentClass}__item`}>
              {/* <NetworkLink
                parentClass={componentClass}
                linkHref={footerData.infoLinkPaths[id]}>
                {navLinkText}
              </NetworkLink> */}
              <NavLink
                parentClass={componentClass}
                path={footerData.infoLinkPaths[id]}>
                {navLinkText}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
}
