import Title from "../../../../../topography/title/Title";
import Paragraph from "../../../../../topography/paragraph/Paragraph";
import Star from "../../../../../ui/star/Star";

import "./ContentItem.css";

export default function ContentItem({ parentClass, cardId, cardData }) {
  const componentClass = "item";

  return (
    <li className={`${parentClass}__${componentClass} ${componentClass}`}>
      <Star parentClass={componentClass} innerText={cardId} />
      <Title parentClass={componentClass} index={4}>
        {cardData.title}
      </Title>
      <Paragraph parentClass={componentClass}>{cardData.text}</Paragraph>
    </li>
  );
}
