import { useContext } from "react";
import { SuitableContext } from "../../../../contexts/SuitableContext";

import Title from "../../../topography/title/Title";
import SuitableItem from "./suitable-item/SuitableItem";

import "./Suitable.css";

export default function Suitable({ parentClass }) {
  const { langData } = useContext(SuitableContext);

  const componentClass = "suitable";

  if (Boolean(langData)) {
    return (
      <section
        className={`${parentClass}__${componentClass} ${componentClass}`}
        id={componentClass}>
        <Title parentClass={componentClass} index={2}>
          {langData.title.toUpperCase()}
        </Title>
        <ul className={`${componentClass}__items`}>
          {langData.itemsData.map((itemData, id) => (
            <SuitableItem
              key={id}
              parentClass={componentClass}
              itemId={id}
              itemData={itemData}
            />
          ))}
        </ul>
      </section>
    );
  }

  return null;
}
