import "./Terms.css";

const langData = {
  title: "Terms of Use",
  sections: [
    {
      title: "General Provisions",
      items: [
        "These rules regulate the relationship between the users of the website and the website owner. By using the website, you agree to comply with these terms.",
        "The website owner reserves the right to amend these terms at any time without prior notice to users.",
      ],
    },
    {
      title: "Use of Content",
      items: [
        "All content posted on the website (texts, images, videos, graphics, etc.) is the intellectual property of the website owner or other rights holders. The use of content is allowed only with the permission of the website owner.",
        "Copying, distributing, modifying, or using the website's content without proper authorization is prohibited.",
      ],
    },
    {
      title: "Registration on the Website",
      items: [
        "Access to certain website features may require registration. Users agree to provide accurate information during registration.",
        "Users are responsible for keeping their registration information confidential and must not share it with third parties.",
      ],
    },
    {
      title: "User Responsibilities",
      items: [
        "Users must use the website in accordance with applicable laws and these terms.",
        "It is prohibited to use the website for illegal activities, distribution of malicious software, spam, or any other content that may harm other users.",
      ],
    },
    {
      title: "Privacy",
      items: [
        "The website owner agrees to protect users' personal data in accordance with the applicable data protection laws.",
        "Details regarding the collection and use of personal data are described in the website's Privacy Policy.",
      ],
    },
    {
      title: "Disclaimer of Liability",
      items: [
        "The website owner is not responsible for possible errors, inaccuracies, or unavailability of information on the website.",
        "The website owner is not liable for any damages caused to users due to the use or inability to use the website.",
      ],
    },
    {
      title: "Final Provisions",
      items: [
        "All disputes arising in connection with the use of the website are resolved in accordance with applicable laws.",
        "If any provision of these terms is found to be invalid or illegal, the remaining provisions will continue to be in effect.",
      ],
    },
  ],
  subtitle: "These terms are mandatory for all website users.",
};

export default function TermsEn() {
  return (
    <>
      <h2 className="offer-section-title">{langData?.title}</h2>
      <ul className="offer-section-items">
        {langData?.sections.map((sectionData, i) => (
          <li key={`offer-item-${i}`} className="offer-section-item">
            <h2 className="offer-section-item-title">
              {i + 1 + ". " + sectionData?.title}
            </h2>
            {sectionData?.subtitle && (
              <p className="offer-section-item-subtitle">
                {sectionData?.subtitle}
              </p>
            )}
            {sectionData?.items && (
              <ul className="offer-section-item-subitems">
                {sectionData?.items.map((itemText, j) => (
                  <li
                    key={`offer-subitem-${j}`}
                    className="offer-section-item-subitem">
                    {itemText}
                  </li>
                ))}
              </ul>
            )}
            {sectionData?.p && (
              <p className="offer-section-item-p">{sectionData?.p}</p>
            )}
          </li>
        ))}
      </ul>
      <p
        className="offer-section-subtitle"
        style={{ marginBottom: 0, width: "100%" }}>
        {langData?.subtitle}
      </p>
    </>
  );
}
