import "./NetworkLink.css";

export default function NetworkLink({ children, linkHref, parentClass }) {
  const componentClass = "network-link";

  return (
    <a
      className={`${parentClass}__${componentClass}`}
      href={linkHref}
      target="_blank"
      rel="noopener noreferrer nofollow">
      {children}
    </a>
  );
}
