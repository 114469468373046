import "./ArrowBtn.css";

export default function ArrowBtn({ parentClass, onClick, isNext, isDisable }) {
  const btnClass = "arrow-btn";
  const componentClass = `${parentClass}__${btnClass}`;
  const nextClass = "next";
  const prevClass = "prev";

  return isDisable ? (
    <button
      disabled
      className={`${componentClass} ${isNext ? nextClass : prevClass} disabled`}
      type="button"
      onClick={onClick}>
      <span className={`${btnClass}__top-line`}></span>
      <span className={`${btnClass}__bot-line`}></span>
    </button>
  ) : (
    <button
      className={`${componentClass} ${isNext ? nextClass : prevClass}`}
      type="button"
      onClick={onClick}>
      <span className={`${btnClass}__top-line`}></span>
      <span className={`${btnClass}__bot-line`}></span>
    </button>
  );
}
