import { createContext, useState } from "react";

export const StartCardsVisibleContext = createContext(null);

export function StartCardsVisibleProvider({ children }) {
  const [visibleId, setVisibleId] = useState("courses");

  function toggleVisibleId(btnId) {
    setVisibleId(btnId);
  }

  return (
    <StartCardsVisibleContext.Provider value={{ visibleId, toggleVisibleId }}>
      {children}
    </StartCardsVisibleContext.Provider>
  );
}
