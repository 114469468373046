import { createContext, useState, useEffect } from "react";

export const IsMobileContext = createContext(null);

export function IsMobileProvider({ children }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  function checkMobileState() {
    setIsMobile(window.innerWidth <= 768);
    setScreenWidth(window.innerWidth);
  }

  useEffect(() => {
    let done = false;

    const regex = new RegExp(
      "Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini",
      "i"
    );

    if (!done && regex.test(navigator.userAgent)) {
      const head = document.head;

      const style = document.createElement("style");
      style.innerHTML = `
      
      .courses__hero .content__link,
      .courses-card__btn-link,
      .company-card__btn-link,
      .start .accordion-button,
      .start .accordion-button .collapsed,
      .form__btn,
      .investors__btn-link,
      .courses .accordion-body,
      .reviews__btn-link,
      .company .content__btn-link,
      .possibilities .accordion-button,
      .possibilities .accordion-button .collapsed,
      .why-mds .body__btn-link,
      .start .start__btn-link {
        padding-top: 4px !important;
      }
      
      .menu-btn__text,
      .lang-select__btn span {
        padding-top: 3px;
      }
      
      .sign-up__btn-link,
      .projects .btns__btn-link {
        padding-top: 34px;
      }

      .menu__network-link {
        padding-top: 8px;
        line-height: 1;
        height: 30px;
      }

      .find .accordion-header-tag {
        padding-top: 8px;
      }

      .reviews__tag,
      .works__tag {
        padding-top: 11px;
      }

      .body__tag {
        padding-top: 10px !important;
      }

      .head__tag {
        padding-top: 9px !important;
      }

      @media (max-width: 1024px) {
        .star__number:after {
          top: 45px;
        }

        .stages .star__number:after {
          top: -14px;
        }
      }
      `;

      head.append(style);
    }

    return () => {
      done = true;
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", checkMobileState);

    return () => {
      window.removeEventListener("resize", checkMobileState);
    };
  }, []);

  return (
    <IsMobileContext.Provider value={{ isMobile, setIsMobile, screenWidth }}>
      {children}
    </IsMobileContext.Provider>
  );
}
