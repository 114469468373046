import { useContext } from "react";
import { TeamContext } from "../../../../../contexts/TeamContext";

import Title from "../../../../topography/title/Title";
import Paragraph from "../../../../topography/paragraph/Paragraph";
import PersonImg from "./person-img/PersonImg";

import "./PersonCards.css";

export default function PersonCards({
  parentClass,
  percentage,
  nextPerson,
  prevPerson,
}) {
  const { langData, teamData } = useContext(TeamContext);

  const cardsClass = `cards`;
  const cardClass = `card`;
  const badgeClass = `badge`;
  const componentClass = `${parentClass}__${cardsClass}`;
  const containerCardClass = `${parentClass}__${cardClass}`;
  const badgeBoxClass = `${cardClass}__${badgeClass}`;

  if (Boolean(langData) && Boolean(teamData)) {
    return (
      <div className={`${parentClass}__${cardsClass}-wrap`}>
        <ul
          className={componentClass}
          style={{ transform: `translateX(${percentage}%)` }}>
          {langData.teamData.map((personData, id) => (
            <li key={id} className={containerCardClass}>
              <PersonImg
                parentClass={cardClass}
                picUrl={teamData.picturesData[id]}
              />
              <div className={badgeBoxClass}>
                <Title parentClass={badgeClass} index={3}>
                  {personData.position}
                </Title>
                <Paragraph parentClass={badgeClass}>
                  {personData.name}
                </Paragraph>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
