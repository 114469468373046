import { useContext } from "react";
import { CompanyWhyMdsContext } from "../../../../contexts/CompanyWhyMdsContext";
import { ContactCardProvider } from "../../../../contexts/ContactCardContext";

import Body from "./body/Body";
import ContactCard from "../../../ui/contact-card/ContactCard";

import "./WhyMds.css";

export default function WhyMds({ parentClass }) {
  const { langData } = useContext(CompanyWhyMdsContext);

  const componentClass = "why-mds";
  const formId = "companyWhyMdsContactForm";

  if (Boolean(langData)) {
    return (
      <section
        className={`${parentClass}__${componentClass} ${componentClass}`}
        id="companyWhyMds">
        <Body parentClass={componentClass} />
        <ContactCardProvider>
          <ContactCard parentClass={componentClass} formId={formId} />
        </ContactCardProvider>
      </section>
    );
  }

  return null;
}
