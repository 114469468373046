import { useContext } from "react";
import { IsMobileContext } from "../../../../../../contexts/IsMobileContext";

import "./FaqAccordionItem.css";

export default function FaqAccordionItem({
  itemData,
  btnTexts,
  itemId,
  height,
  openId,
  changeOpenId,
}) {
  const { isMobile } = useContext(IsMobileContext);

  const closeItemClass = "accordion-item";
  const openItemClass = "accordion-item open";

  const itemClass = openId === itemId ? openItemClass : closeItemClass;
  const btnText = openId === itemId ? btnTexts.closeText : btnTexts.openText;
  const itemNumber = itemId <= 8 ? `0${itemId + 1}.` : `${itemId + 1}.`;
  const itemHeight = height ? height : "auto";

  return (
    <li className={itemClass}>
      <h3 className="accordion-header">
        <span className="accordion-header-number">{itemNumber}</span>
        <div className="accordion-header-box">
          <span
            className="accordion-header-text"
            style={{ height: `${itemHeight}px` }}>
            {itemData.title}
          </span>
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#flush-collapse-${itemId}`}
            aria-expanded="false"
            aria-controls={`flush-collapse-${itemId}`}
            onClick={() => changeOpenId(itemId)}>
            {btnText}
          </button>
          {!isMobile && (
            <button
              className="accordion-button layer collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#flush-collapse-${itemId}`}
              aria-expanded="false"
              aria-controls={`flush-collapse-${itemId}`}
              onClick={() => changeOpenId(itemId)}>
              <div className="arrow-container">
                <span className="left-arrow-line"></span>
                <span className="right-arrow-line"></span>
              </div>
            </button>
          )}
        </div>
      </h3>
      <div
        id={`flush-collapse-${itemId}`}
        className="accordion-collapse collapse"
        data-bs-parent="#accordionFlushExample">
        {typeof itemData.text === "string" ? (
          <p className="accordion-body">{itemData.text}</p>
        ) : (
          <p className="accordion-body">
            {itemData.text.map((innerText, i) => (
              <span key={`inner-text-${i}`} style={{ display: "block" }}>
                {innerText}
              </span>
            ))}
          </p>
        )}
      </div>
    </li>
  );
}
