import { createContext, useState, useContext, useEffect } from "react";
import { getLang } from "../firebase/firestore";
import { LangIdContext } from "./LangIdContext";

export const FaqContext = createContext(null);

export function FaqProvider({ children }) {
  const [langData, setLangData] = useState(null);
  const { langId } = useContext(LangIdContext);

  const faqId = "faq";

  useEffect(() => {
    let done = false;

    if (!done) {
      getLang(setLangData, langId, faqId);
    }

    return () => {
      done = true;
    };
  }, [langId]);

  return (
    <FaqContext.Provider value={{ langData, setLangData }}>
      {children}
    </FaqContext.Provider>
  );
}
