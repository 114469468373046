import { createContext, useState, useContext, useEffect } from "react";
import { getLang, getProps } from "../firebase/firestore";
import { LangIdContext } from "./LangIdContext";

export const MenuContext = createContext(null);

export function MenuProvider({ children }) {
  const [langData, setLangData] = useState(null);
  const [menuData, setMenuData] = useState(null);
  const { langId } = useContext(LangIdContext);

  const menuId = "menu";

  useEffect(() => {
    let done = false;

    if (!done) {
      getLang(setLangData, langId, menuId);
    }

    return () => {
      done = true;
    };
  }, [langId]);

  useEffect(() => {
    let done = false;

    if (!done) {
      getProps(setMenuData, menuId);
    }

    return () => {
      done = true;
    };
  }, []);

  return (
    <MenuContext.Provider
      value={{ langData, setLangData, menuData, setMenuData }}>
      {children}
    </MenuContext.Provider>
  );
}
