import { useContext } from "react";
import { PossibilitiesContext } from "../../../../../../../contexts/PossibilitiesContext";

import "./AccordionHeader.css";

export default function AccordionHeader({ itemId }) {
  const { langData } = useContext(PossibilitiesContext);

  function handleClick(btnId) {
    const domElem = document.getElementById(btnId);

    !domElem.classList.contains("collapsed") &&
      setTimeout(() => {
        if (Boolean(domElem)) {
          domElem.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      }, 400);
  }

  if (Boolean(langData)) {
    return (
      <button
        id={`find-btn-${itemId}`}
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#collapse-${itemId}`}
        aria-expanded="true"
        aria-controls={`collapse-${itemId}`}
        onClick={() => handleClick(`find-btn-${itemId}`)}>
        {langData.btnTexts[itemId].toUpperCase()}
      </button>
    );
  }

  return null;
}
