import { createContext, useState, useContext, useEffect } from "react";
import { getLang } from "../firebase/firestore";
import { LangIdContext } from "./LangIdContext";

export const FindContext = createContext(null);

export function FindProvider({ children }) {
  const [langData, setLangData] = useState(null);
  const { langId } = useContext(LangIdContext);

  const findId = "find";

  useEffect(() => {
    let done = false;

    if (!done) {
      getLang(setLangData, langId, findId);
    }

    return () => {
      done = true;
    };
  }, [langId]);

  return (
    <FindContext.Provider value={{ langData, setLangData }}>
      {children}
    </FindContext.Provider>
  );
}
