import { useContext, useState } from "react";
import { ProjectsContext } from "../../../../contexts/ProjectsContext";
import { IsMobileContext } from "../../../../contexts/IsMobileContext";

import Btns from "./btns/Btns";
import Header from "./header/Header";
import Items from "./items/Items";

import "./Projects.css";

export default function Projects({ parentClass }) {
  const { langData, projectsData } = useContext(ProjectsContext);
  const { isMobile } = useContext(IsMobileContext);
  const [itemCurrentId, setItemCurrentId] = useState(0);

  const componentClass = "projects";

  function nextClick() {
    isMobile &&
      itemCurrentId < langData.itemTitles.length - 1 &&
      setItemCurrentId(itemCurrentId + 1);

    !isMobile &&
      itemCurrentId < langData.itemTitles.length - 2 &&
      setItemCurrentId(itemCurrentId + 2);
  }

  function prevClick() {
    isMobile && itemCurrentId > 0 && setItemCurrentId(itemCurrentId - 1);
    !isMobile && itemCurrentId > 0 && setItemCurrentId(itemCurrentId - 2);
  }

  if (Boolean(langData) && Boolean(projectsData)) {
    return (
      <section
        className={`${parentClass}__${componentClass} ${componentClass}`}
        id={componentClass}>
        <div className={`${componentClass}__container`}>
          <Header
            parentClass={componentClass}
            titleText={langData.titleText}
            tagText={langData.tagText}
            currentId={isMobile ? itemCurrentId : itemCurrentId / 2}
            itemsCount={
              isMobile
                ? langData.itemTitles.length
                : langData.itemTitles.length / 2
            }
          />
          <Items
            parentClass={componentClass}
            itemCurrentId={itemCurrentId}
            swipedNext={nextClick}
            swipedPrev={prevClick}
          />
          <Btns
            parentClass={componentClass}
            nextClick={nextClick}
            prevClick={prevClick}
            itemCurrentId={itemCurrentId}
          />
        </div>
      </section>
    );
  }

  return null;
}
