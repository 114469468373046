import { createContext, useState, useContext, useEffect } from "react";
import { getLang, getProps } from "../firebase/firestore";
import { LangIdContext } from "./LangIdContext";

export const PossibilitiesContext = createContext(null);

export function PossibilitiesProvider({ children }) {
  const [langData, setLangData] = useState(null);
  const [possibilitiesData, setPossibilitiesData] = useState(null);
  const { langId } = useContext(LangIdContext);

  const possibilitiesId = "possibilities";

  useEffect(() => {
    let done = false;

    if (!done) {
      getLang(setLangData, langId, possibilitiesId);
    }

    return () => {
      done = true;
    };
  }, [langId]);

  useEffect(() => {
    let done = false;

    if (!done) {
      getProps(setPossibilitiesData, possibilitiesId);
    }

    return () => {
      done = true;
    };
  }, []);

  return (
    <PossibilitiesContext.Provider
      value={{
        langData,
        setLangData,
        possibilitiesData,
        setPossibilitiesData,
      }}>
      {children}
    </PossibilitiesContext.Provider>
  );
}
