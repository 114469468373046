import { FooterProvider } from "../../../contexts/FooterContext";
import { FindProvider } from "../../../contexts/FindContext";

import Footer from "../../footer/Footer";
import Level from "./Level";

import "./Level.css";

const componentClass = "level";

export default function Level1() {
  return (
    <>
      <main className={componentClass} id={componentClass}>
        <FindProvider>
          <Level levelId={0} />
        </FindProvider>
      </main>
      <FooterProvider>
        <Footer bgc={false} />
      </FooterProvider>
    </>
  );
}
