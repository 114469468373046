import { useContext, useEffect, useState } from "react";
import { FaqContext } from "../../../../contexts/FaqContext";
import { LangIdContext } from "../../../../contexts/LangIdContext";
import { IsMobileContext } from "../../../../contexts/IsMobileContext";

import Title from "../../../topography/title/Title";
import FaqAccordion from "./faq-accordion/FaqAccordion";
import FaqAccordionItem from "./faq-accordion/faq-accordion-item/FaqAccordionItem";

import "./Faq.css";

export default function Faq({ parentClass }) {
  const { langData } = useContext(FaqContext);
  const { langId } = useContext(LangIdContext);
  const { screenWidth } = useContext(IsMobileContext);
  const [itemHeight, setItemHeight] = useState(0);
  const [openId, setOpenId] = useState(null);

  function changeOpenId(btnId) {
    setOpenId(openId !== btnId ? btnId : null);
  }

  useEffect(() => {
    if (Boolean(langData) && Boolean(langId)) {
      const textWidths = [];
      let lineHeight = 0;
      let fontSize = 0;

      const elems = document.querySelectorAll(".accordion-header-text");
      const container = document.querySelector(
        ".faq__accordion .accordion-header"
      );
      const number = document.querySelector(".accordion-header-number");

      elems.forEach((elem) => {
        const computedStyle = window.getComputedStyle(elem);
        lineHeight = Number(
          computedStyle.getPropertyValue("line-height").replace("px", " ")
        );
        fontSize = Number(
          computedStyle.getPropertyValue("font-size").replace("px", " ")
        );

        textWidths.push(elem.innerHTML.length * 0.51176 * fontSize);
      });

      const maxWidthText = Math.max(...textWidths) * 1.08;
      const maxWidthElem = (container.offsetWidth - number.offsetWidth) * 0.965;

      maxWidthText < maxWidthElem
        ? setItemHeight(Math.ceil(lineHeight))
        : setItemHeight(
            Math.ceil(lineHeight) * Math.ceil(maxWidthText / maxWidthElem)
          );
    }
  }, [langData, langId, screenWidth]);

  const componentClass = "faq";

  if (Boolean(langData)) {
    return (
      <div className={`${parentClass}__${componentClass}-bg`}>
        <section
          className={`${parentClass}__${componentClass} ${componentClass}`}
          id={componentClass}>
          <Title parentClass={componentClass} index={2}>
            {langData.title.toUpperCase()}
          </Title>
          <FaqAccordion parentClass={componentClass}>
            {langData.faqItems.map((faqItemData, id) => (
              <FaqAccordionItem
                key={id}
                itemData={faqItemData}
                btnTexts={langData.btns}
                itemId={id}
                height={itemHeight}
                openId={openId}
                changeOpenId={changeOpenId}
              />
            ))}
          </FaqAccordion>
        </section>
      </div>
    );
  }

  return null;
}
