import { useContext } from "react";
import { PossibilitiesContext } from "../../../../../contexts/PossibilitiesContext";

import Btn from "../../../../ui/btns/btn/Btn";

import "./Btns.css";

export default function Btns({ parentClass, visibleId, handleClick }) {
  const { langData } = useContext(PossibilitiesContext);

  const btnsClass = `${parentClass}__btns`;
  const btnClass = `${parentClass}__btn-box`;
  const currentBtnClass = `${parentClass}__btn-box current`;

  if (Boolean(langData)) {
    return (
      <ul className={btnsClass}>
        {langData.btnTexts.map((btnText, btnId) => (
          <li
            className={visibleId === btnId ? currentBtnClass : btnClass}
            key={`btn-${btnId}`}>
            <Btn parentClass={parentClass} onClick={() => handleClick(btnId)}>
              {btnText.toUpperCase()}
            </Btn>
          </li>
        ))}
      </ul>
    );
  }

  return null;
}
