import "./AccordCardArrow.css";

export default function AccordCardArrow({
  parentClass,
  handleClick,
  arrowId,
  isDisabled,
}) {
  const btnArrowClass = "btn-arrow";
  const componentClass = `${parentClass}__${btnArrowClass}`;

  return (
    <button
      disabled={isDisabled}
      className={`${componentClass} ${arrowId}`}
      type="button"
      onClick={handleClick}>
      <span className={`${btnArrowClass}__top-line`}></span>
      <span className={`${btnArrowClass}__bot-line`}></span>
    </button>
  );
}
