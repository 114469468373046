import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyChF7ZV32V102uBj9oqRuJYiDJhcfOPDO8",
  authDomain: "mds-website-c0334.firebaseapp.com",
  projectId: "mds-website-c0334",
  storageBucket: "mds-website-c0334.appspot.com",
  messagingSenderId: "971119960080",
  appId: "1:971119960080:web:dcd455b45239ccc8d4aa95",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
// const auth = getAuth(app);

export { app, db };
