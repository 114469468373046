import TextArrow from "../../ui/arrow/text-arrow/TextArrow";

import "./ArrowTitle.css";

export default function ArrowTitle({ parentClass, textBefore, textAfter }) {
  const componentClass = `${parentClass}__arrow-title`;

  return (
    <h3 className={componentClass}>
      {`${textBefore} `}
      <TextArrow parentClass={parentClass} />
      {` ${textAfter}`}
    </h3>
  );
}
