import { Route, Routes, BrowserRouter } from "react-router-dom";
import { LangIdProvider } from "../contexts/LangIdContext";
import { MenuVisibleProvider } from "../contexts/MenuVisibleContext";
import { MenuProvider } from "../contexts/MenuContext";
import { IsMobileProvider } from "../contexts/IsMobileContext";
import { HeaderProvider } from "../contexts/HeaderContext";
import { ContactFormProvider } from "../contexts/ContactFormContext";
import { motion, AnimatePresence } from "framer-motion";

import Header from "../components/header/Header";
import Menu from "../components/menu/Menu";
import Home from "../components/pages/home/Home";
import Courses from "../components/pages/courses/Courses";
import Company from "../components/pages/company/Company";
import Contact from "../components/pages/contact/Contact";
import Privacy from "../components/pages/privacy/Privacy";
import Refund from "../components/pages/refund/Refund";
import Offer from "../components/pages/offer/Offer";
import Terms from "../components/pages/terms/Terms";
import Pay from "../components/pages/pay/Pay";
import Level1 from "../components/pages/level/Level1";
import Level2 from "../components/pages/level/Level2";
import Level3 from "../components/pages/level/Level3";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import "./App.css";

export default function App() {
  const durationTime = 0.25;

  const variants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <LangIdProvider>
      <BrowserRouter>
        <MenuVisibleProvider>
          <IsMobileProvider>
            <HeaderProvider>
              <Header />
            </HeaderProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <AnimatePresence mode="wait" key="page-amin">
                    <motion.div
                      className="page-anim-box"
                      key="page-home"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{ duration: durationTime }}>
                      <Home />
                    </motion.div>
                  </AnimatePresence>
                }
              />
              <Route
                path="/menu"
                element={
                  <AnimatePresence mode="wait" key="page-amin">
                    <motion.div
                      className="page-anim-box"
                      key="page-manu"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{ duration: durationTime }}>
                      <MenuProvider>
                        <Menu />
                      </MenuProvider>
                    </motion.div>
                  </AnimatePresence>
                }
              />
              <Route
                path="/courses"
                element={
                  <AnimatePresence mode="wait" key="page-amin">
                    <motion.div
                      className="page-anim-box"
                      key="page-courses"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{ duration: durationTime }}>
                      <Courses />
                    </motion.div>
                  </AnimatePresence>
                }
              />
              <Route
                path="/company"
                element={
                  <AnimatePresence mode="wait" key="page-amin">
                    <motion.div
                      className="page-anim-box"
                      key="page-company"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{ duration: durationTime }}>
                      <Company />
                    </motion.div>
                  </AnimatePresence>
                }
              />
              <Route
                path="/contact"
                element={
                  <AnimatePresence mode="wait" key="page-amin">
                    <motion.div
                      className="page-anim-box"
                      key="page-contact"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{ duration: durationTime }}>
                      <ContactFormProvider>
                        <Contact />
                      </ContactFormProvider>
                    </motion.div>
                  </AnimatePresence>
                }
              />
              <Route
                path="/privacy"
                element={
                  <AnimatePresence mode="wait" key="page-amin">
                    <motion.div
                      className="page-anim-box"
                      key="page-privacy"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{ duration: durationTime }}>
                      <Privacy />
                    </motion.div>
                  </AnimatePresence>
                }
              />
              <Route
                path="/refund"
                element={
                  <AnimatePresence mode="wait" key="page-amin">
                    <motion.div
                      className="page-anim-box"
                      key="page-refund"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{ duration: durationTime }}>
                      <Refund />
                    </motion.div>
                  </AnimatePresence>
                }
              />
              <Route
                path="/offer"
                element={
                  <AnimatePresence mode="wait" key="page-amin">
                    <motion.div
                      className="page-anim-box"
                      key="page-offer"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{ duration: durationTime }}>
                      <Offer />
                    </motion.div>
                  </AnimatePresence>
                }
              />
              <Route
                path="/terms"
                element={
                  <AnimatePresence mode="wait" key="page-amin">
                    <motion.div
                      className="page-anim-box"
                      key="page-terms"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{ duration: durationTime }}>
                      <Terms />
                    </motion.div>
                  </AnimatePresence>
                }
              />
              <Route
                path="/pay"
                element={
                  <AnimatePresence mode="wait" key="page-amin">
                    <motion.div
                      className="page-anim-box"
                      key="page-pay"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{ duration: durationTime }}>
                      <Pay />
                    </motion.div>
                  </AnimatePresence>
                }
              />
              <Route
                path="/level1"
                element={
                  <AnimatePresence mode="wait" key="page-amin">
                    <motion.div
                      className="page-anim-box"
                      key="page-level1"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{ duration: durationTime }}>
                      <Level1 />
                    </motion.div>
                  </AnimatePresence>
                }
              />
              <Route
                path="/level2"
                element={
                  <AnimatePresence mode="wait" key="page-amin">
                    <motion.div
                      className="page-anim-box"
                      key="page-level2"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{ duration: durationTime }}>
                      <Level2 />
                    </motion.div>
                  </AnimatePresence>
                }
              />
              <Route
                path="/level3"
                element={
                  <AnimatePresence mode="wait" key="page-amin">
                    <motion.div
                      className="page-anim-box"
                      key="page-level3"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{ duration: durationTime }}>
                      <Level3 />
                    </motion.div>
                  </AnimatePresence>
                }
              />
            </Routes>
          </IsMobileProvider>
        </MenuVisibleProvider>
      </BrowserRouter>
    </LangIdProvider>
  );
}
