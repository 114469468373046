import { createContext, useState, useContext, useEffect } from "react";
import { getLang } from "../firebase/firestore";
import { LangIdContext } from "./LangIdContext";

export const ContactCardContext = createContext(null);

export function ContactCardProvider({ children }) {
  const [langData, setLangData] = useState(null);
  const { langId } = useContext(LangIdContext);

  const cardId = "contactCard";

  useEffect(() => {
    let done = false;

    if (!done) {
      getLang(setLangData, langId, cardId);
    }

    return () => {
      done = true;
    };
  }, [langId]);

  return (
    <ContactCardContext.Provider value={{ langData, setLangData }}>
      {children}
    </ContactCardContext.Provider>
  );
}
