import Title from "../../../../../topography/title/Title";
import Paragraph from "../../../../../topography/paragraph/Paragraph";

import arrowBabelIcon from "../../card-imgs/arrow-bagel-icon.webp";
import heartBabelIcon from "../../card-imgs/heart-bagel-icon.webp";
import starBabelIcon from "../../card-imgs/star-bagel-icon.webp";
import bagelIcon from "../../card-imgs/bagel-icon.webp";

import "./StartCard.css";

export default function StartCard({ parentClass, cardData, cardIndex }) {
  const cardClass = "card";
  const componentClass = `${parentClass}__${cardClass}`;
  const imgClass = `${cardClass}__img`;

  function setImgSrc(cardIndex) {
    switch (true) {
      case cardIndex === 0 || cardIndex % 3 === 0:
        return arrowBabelIcon;

      case cardIndex === 1 || cardIndex % 3 === 1:
        return starBabelIcon;

      case cardIndex === 2 || cardIndex % 3 === 2:
        return heartBabelIcon;

      default:
        return bagelIcon;
    }
  }

  if (Boolean(cardData)) {
    const imgSrc = setImgSrc(cardIndex);

    return (
      <li className={componentClass}>
        <img className={imgClass} src={imgSrc} alt="bagel icon" />
        <Title parentClass={cardClass} index={3}>
          {cardData.title.toUpperCase()}
        </Title>
        {typeof cardData.text === "string" ? (
          <Paragraph parentClass={cardClass}>{cardData.text}</Paragraph>
        ) : (
          <>
            <p className="card__text spec">{cardData.text.p}</p>
            <p className="card__text spec">
              <b>{cardData.text.subtitle}</b>
            </p>
            <ul>
              {cardData.text.items.map((itemText, i) => (
                <li className="card__text li" key={`card-item-${i}`}>
                  {itemText}
                </li>
              ))}
            </ul>
          </>
        )}
      </li>
    );
  }

  return null;
}
