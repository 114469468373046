import star from "./star.png";

import "./Item.css";

export default function Item({ parentClass, itemData, itemId }) {
  const componentClass = "item";

  return (
    <li className={`${parentClass}__${componentClass} ${componentClass}`}>
      <div
        className={`${componentClass}__wrap`}
        style={itemId % 2 !== 0 ? { backgroundColor: "#FF5C00" } : {}}>
        <h3 className={`${componentClass}__inner-text`}>
          {itemData.innerText.toUpperCase()}
        </h3>
        <img className={`${componentClass}__star`} alt="star-icon" src={star} />
      </div>
      <p className={`${componentClass}__sub-text`}>{itemData.subText}</p>
    </li>
  );
}
