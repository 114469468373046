import { useContext } from "react";
import { InvestorsContext } from "../../../../contexts/InvestorsContext";

import Title from "../../../topography/title/Title";
import BtnLink from "../../../ui/btns/btn-link/BtnLink";
import Img from "../../../img/Img";

import "./Investors.css";

export default function Investors({ parentClass }) {
  const { langData } = useContext(InvestorsContext);

  const componentClass = "investors";
  const containerClass = `${parentClass}__${componentClass} ${componentClass}`;
  const itemsClass = `${componentClass}__items`;
  const itemClass = `${componentClass}__item`;
  const imgBoxClass = `${componentClass}__img-box`;
  const contentClass = `${componentClass}__content`;
  const btnPath = "/contact";

  if (Boolean(langData)) {
    return (
      <section className={containerClass} id={componentClass}>
        <div className={contentClass}>
          <Title parentClass={componentClass} index={2}>
            {langData.title.toUpperCase()}
          </Title>
          <ul className={itemsClass}>
            {langData.itemsText.map((itemText, id) => (
              <li key={id} className={itemClass}>
                {itemText}
              </li>
            ))}
          </ul>
          <BtnLink parentClass={componentClass} path={btnPath}>
            {langData.btnText.toUpperCase()}
          </BtnLink>
        </div>
        <div className={imgBoxClass}>
          <Img parentClass={componentClass} />
        </div>
      </section>
    );
  }

  return null;
}
