import { useState, useContext, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { TeamContext } from "../../../../../contexts/TeamContext";
import { IsMobileContext } from "../../../../../contexts/IsMobileContext";

import PersonCards from "../person-cards/PersonCards";
import PersonBtns from "../person-btns/PersonBtns";

import "./TeamBody.css";

export default function TeamBody({ parentClass }) {
  const { langData } = useContext(TeamContext);
  const { screenWidth } = useContext(IsMobileContext);
  const [percentage, setPercentage] = useState(0);

  const myRef = useRef();

  const refPassthrough = (el) => {
    handlers.ref(el);
    myRef.current = el;
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      nextPerson();
    },
    onSwipedRight: () => {
      prevPerson();
    },
  });

  const componentClass = "body";
  const percentageStep = initPctStep(screenWidth);
  const cardCountInRow = initCardCount(screenWidth);
  const maxTranslateX =
    (langData.teamData.length - cardCountInRow) * percentageStep;

  function prevPerson() {
    percentage < 0
      ? setPercentage(percentage + percentageStep)
      : setPercentage(maxTranslateX * -1);
  }

  function nextPerson() {
    percentage > maxTranslateX * -1
      ? setPercentage(percentage - percentageStep)
      : setPercentage(0);
  }

  function initPctStep(screenWidth) {
    switch (true) {
      case screenWidth > 1280:
        return 25;

      case screenWidth <= 1280 && screenWidth > 768:
        return 33.334;

      case screenWidth <= 768 && screenWidth > 520:
        return 50;

      case screenWidth <= 520:
        return 100;

      default:
        return 25;
    }
  }

  function initCardCount(screenWidth) {
    switch (true) {
      case screenWidth > 1280:
        return 4;

      case screenWidth <= 1280 && screenWidth > 768:
        return 3;

      case screenWidth <= 768 && screenWidth > 520:
        return 2;

      case screenWidth <= 520:
        return 1;

      default:
        return 4;
    }
  }

  return (
    <div
      className={`${parentClass}__${componentClass} ${componentClass}`}
      ref={refPassthrough}>
      <PersonCards parentClass={componentClass} percentage={percentage} />
      <PersonBtns
        parentClass={componentClass}
        nextPerson={nextPerson}
        prevPerson={prevPerson}
      />
    </div>
  );
}
