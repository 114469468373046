import "./Refund.css";

const langData = {
  title: "Refund Policy",
  subtitle:
    "We strive to provide quality education and ensure customer satisfaction. However, if for any reason you are not satisfied with our courses or need to cancel your participation, we offer a transparent refund policy.",
  sections: [
    {
      title: "Refunds for Unused Courses",
      subtitle:
        "Refunds are only available for classes that have not yet been attended by the user. If you cancel your registration for the course more than 24 hours before the start of the first class, 100% of the paid amount will be refunded. If you cancel less than 24 hours before the course starts, no refund will be provided.",
    },
    {
      title: "Partial Refunds",
      subtitle:
        "If the course consists of multiple classes and you have already attended part of them, you are entitled to a partial refund for the remaining classes. The refund amount is calculated proportionally based on the number of unused classes. Refunds for completed classes will not be provided.",
    },
    {
      title: "Refund Procedure",
      subtitle:
        "To initiate a refund request, you must send an email to the support team, specifying your name, the course you registered for, and the reason for cancellation. We review all requests within 5 business days of receiving them. Refunds will be processed within 10 business days after the request is approved and will be issued to the same account from which the payment was made.",
    },
    {
      title: "Refund Denial",
      subtitle: "We reserve the right to deny refunds in the following cases:",
      items: [
        "If the class was conducted and the client did not attend without prior notice.",
        "If the course's rules of conduct were violated.",
      ],
    },
    {
      title: "Changes to the Refund Policy",
      subtitle:
        "We reserve the right to make changes to this policy at any time. All changes will be posted on this page. We recommend regularly reviewing the policy to stay informed of the latest terms.",
    },
  ],
};

export default function RefundEn() {
  return (
    <>
      <h2 className="refund-section-title">{langData?.title}</h2>
      <p className="refund-section-subtitle">{langData?.subtitle}</p>
      <ul className="refund-section-items">
        {langData?.sections.map((sectionData, i) => (
          <li key={`refund-item-${i}`} className="refund-section-item">
            <h2 className="refund-section-item-title">
              {i + 1 + ". " + sectionData?.title}
            </h2>
            {sectionData?.subtitle && (
              <p className="refund-section-item-subtitle">
                {sectionData?.subtitle}
              </p>
            )}
            {sectionData?.items && (
              <ul className="refund-section-item-subitems">
                {sectionData?.items.map((itemText, j) => (
                  <li
                    key={`refund-subitem-${j}`}
                    className="refund-section-item-subitem">
                    {itemText}
                  </li>
                ))}
              </ul>
            )}
            {sectionData?.p && (
              <p className="refund-section-item-p">{sectionData?.p}</p>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}
