import { createContext, useState, useContext, useEffect } from "react";
import { getLang, getProps } from "../firebase/firestore";
import { LangIdContext } from "./LangIdContext";

export const ProjectsContext = createContext(null);

export function ProjectsProvider({ children }) {
  const [langData, setLangData] = useState(null);
  const [projectsData, setProjectsData] = useState(null);
  const { langId } = useContext(LangIdContext);

  const projectsId = "projects";

  useEffect(() => {
    let done = false;

    if (!done) {
      getLang(setLangData, langId, projectsId);
    }

    return () => {
      done = true;
    };
  }, [langId]);

  useEffect(() => {
    let done = false;

    if (!done) {
      getProps(setProjectsData, projectsId);
    }

    return () => {
      done = true;
    };
  }, []);

  return (
    <ProjectsContext.Provider
      value={{ langData, setLangData, projectsData, setProjectsData }}>
      {children}
    </ProjectsContext.Provider>
  );
}
