import { useContext } from "react";
import { PossibilitiesContext } from "../../../../../contexts/PossibilitiesContext";
import { motion, AnimatePresence } from "framer-motion";

import Item from "./item/Item";

import "./Items.css";

export default function Items({ parentClass, visibleId }) {
  const { langData, possibilitiesData } = useContext(PossibilitiesContext);

  const variants = {
    enter: { opacity: 0, x: 35 },
    center: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -35 },
  };

  if (Boolean(langData) && Boolean(possibilitiesData)) {
    return (
      <AnimatePresence mode="wait" key={`anim-box`}>
        <motion.div
          key={`item-anim-${visibleId}`}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: 0.25 }}>
          <ul className={`${parentClass}__items`}>
            {langData.sectionsData[visibleId].itemsData.map(
              (itemData, itemId) => (
                <Item
                  key={`item-${itemId}`}
                  parentClass={parentClass}
                  itemData={itemData}
                  picSrc={
                    possibilitiesData.sectionsData[visibleId].itemPics[itemId]
                  }
                />
              )
            )}
          </ul>
        </motion.div>
      </AnimatePresence>
    );
  }

  return null;
}
