import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const MenuVisibleContext = createContext(null);

export function MenuVisibleProvider({ children }) {
  const { pathname } = useLocation();
  const [isMenuVisible, setIsMenuVisible] = useState(checkMenuState(pathname));
  const [history, setHistory] = useState([]);

  function checkMenuState(pathname) {
    return pathname.includes("menu");
  }

  useEffect(() => {
    let done = false;

    if (!done && pathname !== "/menu") {
      setHistory([pathname, ...history].splice(0, 5));
    }

    return () => {
      done = true;
    };
  }, [pathname]);

  return (
    <MenuVisibleContext.Provider
      value={{
        isMenuVisible,
        setIsMenuVisible,
        history,
      }}>
      {children}
    </MenuVisibleContext.Provider>
  );
}
