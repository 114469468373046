import "./Terms.css";

const langData = {
  title: "Правила використання сайту",
  sections: [
    {
      title: "Загальні положення",
      items: [
        "Ці правила регулюють відносини між користувачами сайту та власником сайту. Використовуючи сайт, ви погоджуєтесь дотримуватися цих правил.",
        "Власник сайту залишає за собою право змінювати ці правила в будь-який час без попереднього повідомлення користувачів.",
      ],
    },
    {
      title: "Використання контенту",
      items: [
        "Весь контент, розміщений на сайті (тексти, зображення, відео, графіка тощо), є інтелектуальною власністю власника сайту або інших правовласників. Використання контенту можливе лише з дозволу власника сайту.",
        "Забороняється копіювання, розповсюдження, зміна або використання контенту сайту без відповідного дозволу.",
      ],
    },
    {
      title: "Реєстрація на сайті",
      items: [
        "Для доступу до певних функцій сайту може вимагатися реєстрація. Користувач зобов'язується надавати достовірну інформацію під час реєстрації.",
        "Користувач зобов'язується зберігати конфіденційність своїх реєстраційних даних і не передавати їх третім особам.",
      ],
    },
    {
      title: "Обов'язки користувачів",
      items: [
        "Користувачі зобов'язані використовувати сайт відповідно до чинного законодавства та цих правил.",
        "Забороняється використання сайту для незаконної діяльності, поширення шкідливого програмного забезпечення, спаму або іншого контенту, що може зашкодити іншим користувачам.",
      ],
    },
    {
      title: "Конфіденційність",
      items: [
        "Власник сайту зобов'язується захищати персональні дані користувачів відповідно до чинного законодавства про захист персональних даних.",
        "Деталі про збір та використання персональних даних описані в Політиці конфіденційності сайту.",
      ],
    },
    {
      title: "Відмова від відповідальності",
      items: [
        "Власник сайту не несе відповідальності за можливі помилки, неточності або недоступність інформації на сайті.",
        "Власник сайту не несе відповідальності за збитки, завдані користувачам через використання сайту або неможливість його використання.",
      ],
    },
    {
      title: "Завершальні положення",
      items: [
        "Усі суперечки, що виникають у зв'язку з використанням сайту, вирішуються відповідно до чинного законодавства.",
        "Якщо будь-яке положення цих правил виявиться недійсним або незаконним, інші положення залишаються в силі.",
      ],
    },
  ],
  subtitle: "Ці правила є обов'язковими для всіх користувачів сайту.",
};

export default function TermsUk() {
  return (
    <>
      <h2 className="offer-section-title">{langData?.title}</h2>
      <ul className="offer-section-items">
        {langData?.sections.map((sectionData, i) => (
          <li key={`offer-item-${i}`} className="offer-section-item">
            <h2 className="offer-section-item-title">
              {i + 1 + ". " + sectionData?.title}
            </h2>
            {sectionData?.subtitle && (
              <p className="offer-section-item-subtitle">
                {sectionData?.subtitle}
              </p>
            )}
            {sectionData?.items && (
              <ul className="offer-section-item-subitems">
                {sectionData?.items.map((itemText, j) => (
                  <li
                    key={`offer-subitem-${j}`}
                    className="offer-section-item-subitem">
                    {itemText}
                  </li>
                ))}
              </ul>
            )}
            {sectionData?.p && (
              <p className="offer-section-item-p">{sectionData?.p}</p>
            )}
          </li>
        ))}
      </ul>
      <p
        className="offer-section-subtitle"
        style={{ marginBottom: 0, width: "100%" }}>
        {langData?.subtitle}
      </p>
    </>
  );
}
