import "./Mail.css";

export default function Mail({ parentClass, email, children }) {
  const componentClass = "mail";

  return (
    <a className={`${parentClass}-${componentClass}`} href={`mailto:${email}`}>
      {children}
    </a>
  );
}
