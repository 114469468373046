import { useContext } from "react";
import { MenuContext } from "../../../contexts/MenuContext";

import NetworkLink from "../../ui/links/network-link/NetworkLink";

import "./MenuMsgrLinks.css";

export default function MenuMsgrLinks({ parentClass }) {
  const { langData, menuData } = useContext(MenuContext);

  const componentClass = "msgr-items";
  const itemClass = "msgr-item";

  if (Boolean(langData) && Boolean(menuData)) {
    const linksData = [
      {
        text: langData.msgrLinks.instaText,
        href: menuData.msgrLinks.instaHref,
      },
      // {
      //   text: langData.msgrLinks.facebookText,
      //   href: menuData.msgrLinks.facebookHref,
      // },
      {
        text: langData.msgrLinks.linkedinText,
        href: menuData.msgrLinks.linkedinHref,
      },
    ];

    return (
      <ul className={`${parentClass}__${componentClass}`}>
        {linksData.map((linkData, index) => (
          <li key={index} className={`${parentClass}__${itemClass}`}>
            <NetworkLink parentClass={parentClass} linkHref={linkData.href}>
              {linkData.text.toUpperCase()}
            </NetworkLink>
          </li>
        ))}
      </ul>
    );
  }

  return null;
}
