export function BlackBlueLogoImg() {
  return (
    <svg
      width="720"
      height="240"
      viewBox="0 0 720 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M308.995 103.98V21.6215L380.035 5.1445L453.133 28.824L308.995 103.98Z"
        fill="#4638FF"
      />
      <path
        d="M527.737 103.98V44.8694L598.778 5.1445L671.875 28.824L527.737 103.98Z"
        fill="#4638FF"
      />
      <path
        d="M11.0913 99.1553L11.0935 36.8862L70.2836 5.1445L122.856 36.8862L11.0913 99.1553Z"
        fill="#4638FF"
      />
      <path
        d="M3.8503 236.505C1.82311 236.505 0.133789 235.154 0.133789 232.789V45.2738C0.133789 42.9088 0.809519 40.8816 2.49884 39.1923L39.3261 2.36506C41.0154 0.67574 42.3668 0 45.0697 0H94.0601C96.4251 0 98.1144 0.67574 99.8038 2.36506L136.631 39.1923C138.32 40.8816 138.996 42.9088 138.996 45.2738V232.789C138.996 235.154 137.645 236.505 135.617 236.505H116.697C114.332 236.505 112.98 235.154 112.98 232.789V38.8544C112.98 31.0835 107.913 26.3534 100.142 26.3534H39.3261C31.2173 26.3534 26.4872 31.0835 26.4872 38.8544V232.789C26.4872 235.154 24.7979 236.505 22.7707 236.505H3.8503ZM230.219 236.505C228.192 236.505 226.503 235.154 226.503 232.789V66.2214L186.635 26.3534H141.361C138.996 26.3534 137.645 24.6641 137.645 22.6369V3.71652C137.645 1.35146 138.996 0 141.361 0H193.392C195.757 0 197.784 0.67574 199.474 2.36506L250.153 53.0447C251.843 55.0719 252.856 56.4233 252.856 59.1263V232.789C252.856 235.154 251.505 236.505 249.14 236.505H230.219Z"
        fill="black"
      />
      <path
        d="M289.359 236.505C287.332 236.505 285.642 235.154 285.642 232.789V45.2738C285.642 42.9088 286.318 40.8816 288.007 39.1923L324.835 2.36506C326.524 0.67574 328.551 0 330.916 0H435.316C437.681 0 439.371 0.67574 441.06 2.36506L477.887 39.1923C479.577 40.8816 480.252 42.5709 480.252 45.2738V172.311C480.252 175.014 479.577 176.703 477.887 178.392L422.14 234.14C420.112 235.829 418.423 236.505 416.058 236.505H289.359ZM311.996 210.152H409.301L454.237 165.554V38.8544C454.237 31.0835 449.169 26.3534 441.398 26.3534H324.835C316.726 26.3534 311.996 31.0835 311.996 38.8544V210.152Z"
        fill="black"
      />
      <path
        d="M549.562 236.505C547.197 236.505 545.17 235.829 543.48 234.14L506.653 197.313C504.964 195.623 504.288 193.596 504.288 191.231V162.851C504.288 160.486 505.977 159.134 508.005 159.134H526.925C529.29 159.134 530.642 160.486 530.642 162.851V197.651C530.642 205.422 535.709 210.152 543.48 210.152H630.649L669.504 171.297V143.255C669.504 135.146 664.774 130.416 657.003 130.416H549.9C547.197 130.416 545.508 129.74 543.818 128.051L506.991 91.2234C504.964 89.1962 504.288 87.5069 504.288 85.1418V58.4505C504.288 55.7476 505.302 54.0583 506.991 52.369L556.995 2.36506C558.684 0.67574 560.711 0 563.076 0H650.583C652.948 0 654.976 0.67574 656.665 2.36506L693.492 39.1923C695.181 40.8816 695.857 42.9088 695.857 45.2738V73.6544C695.857 76.0195 694.168 77.3709 692.141 77.3709H673.22C671.193 77.3709 669.504 76.0195 669.504 73.6544V38.8544C669.504 31.0835 664.774 26.3534 656.665 26.3534H569.496L530.642 65.2078V91.2234C530.642 99.3321 535.372 104.062 543.143 104.062H650.583C653.286 104.062 654.638 104.738 656.327 106.427L693.154 143.255C695.181 145.282 695.857 146.971 695.857 149.336V178.392C695.857 180.757 694.844 182.447 693.154 184.136L643.15 234.14C641.461 235.829 639.772 236.505 637.407 236.505H549.562Z"
        fill="black"
      />
    </svg>
  );
}

export function WhiteBlackLogoImg() {
  return (
    <svg
      width="720"
      height="240"
      viewBox="0 0 720 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M309.499 104.453V22.0942L380.54 5.61719L453.637 29.2967L309.499 104.453Z"
        fill="#121619"
      />
      <path
        d="M528.241 104.453V45.3421L599.282 5.61719L672.38 29.2967L528.241 104.453Z"
        fill="#121619"
      />
      <path
        d="M11.5957 99.628L11.5979 37.3589L70.788 5.61719L123.361 37.3589L11.5957 99.628Z"
        fill="#121619"
      />
      <path
        d="M4.35469 236.978C2.3275 236.978 0.638184 235.626 0.638184 233.261V45.7465C0.638184 43.3814 1.31391 41.3542 3.00323 39.6649L39.8304 2.83772C41.5198 1.1484 42.8712 0.472656 45.5741 0.472656H94.5645C96.9295 0.472656 98.6188 1.1484 100.308 2.83772L137.135 39.6649C138.825 41.3542 139.5 43.3814 139.5 45.7465V233.261C139.5 235.626 138.149 236.978 136.122 236.978H117.201C114.836 236.978 113.485 235.626 113.485 233.261V39.3271C113.485 31.5562 108.417 26.8261 100.646 26.8261H39.8304C31.7217 26.8261 26.9916 31.5562 26.9916 39.3271V233.261C26.9916 235.626 25.3023 236.978 23.2751 236.978H4.35469ZM230.724 236.978C228.697 236.978 227.007 235.626 227.007 233.261V66.6941L187.139 26.8261H141.865C139.5 26.8261 138.149 25.1368 138.149 23.1096V4.18918C138.149 1.82412 139.5 0.472656 141.865 0.472656H193.897C196.262 0.472656 198.289 1.1484 199.978 2.83772L250.658 53.5174C252.347 55.5446 253.361 56.896 253.361 59.5989V233.261C253.361 235.626 252.009 236.978 249.644 236.978H230.724Z"
        fill="white"
      />
      <path
        d="M289.863 236.978C287.836 236.978 286.147 235.626 286.147 233.261V45.7465C286.147 43.3814 286.822 41.3542 288.512 39.6649L325.339 2.83772C327.028 1.1484 329.056 0.472656 331.421 0.472656H435.821C438.186 0.472656 439.875 1.1484 441.564 2.83772L478.392 39.6649C480.081 41.3542 480.757 43.0436 480.757 45.7465V172.783C480.757 175.486 480.081 177.176 478.392 178.865L422.644 234.613C420.617 236.302 418.927 236.978 416.562 236.978H289.863ZM312.5 210.624H409.805L454.741 166.026V39.3271C454.741 31.5562 449.673 26.8261 441.902 26.8261H325.339C317.23 26.8261 312.5 31.5562 312.5 39.3271V210.624Z"
        fill="white"
      />
      <path
        d="M550.066 236.978C547.701 236.978 545.674 236.302 543.985 234.613L507.158 197.785C505.468 196.096 504.792 194.069 504.792 191.704V163.323C504.792 160.958 506.482 159.607 508.509 159.607H527.429C529.794 159.607 531.146 160.958 531.146 163.323V198.123C531.146 205.894 536.214 210.624 543.985 210.624H631.154L670.008 171.77V143.727C670.008 135.618 665.278 130.888 657.507 130.888H550.404C547.701 130.888 546.012 130.213 544.323 128.523L507.495 91.6961C505.468 89.6689 504.792 87.9795 504.792 85.6145V58.9232C504.792 56.2203 505.806 54.531 507.495 52.8416L557.499 2.83772C559.189 1.1484 561.216 0.472656 563.581 0.472656H651.088C653.453 0.472656 655.48 1.1484 657.169 2.83772L693.996 39.6649C695.686 41.3542 696.362 43.3814 696.362 45.7465V74.1271C696.362 76.4921 694.672 77.8436 692.645 77.8436H673.725C671.697 77.8436 670.008 76.4921 670.008 74.1271V39.3271C670.008 31.5562 665.278 26.8261 657.169 26.8261H570L531.146 65.6805V91.6961C531.146 99.8048 535.876 104.535 543.647 104.535H651.088C653.791 104.535 655.142 105.211 656.831 106.9L693.659 143.727C695.686 145.754 696.362 147.444 696.362 149.809V178.865C696.362 181.23 695.348 182.919 693.659 184.609L643.655 234.613C641.965 236.302 640.276 236.978 637.911 236.978H550.066Z"
        fill="white"
      />
    </svg>
  );
}

export function WhiteBlueLogoImg() {
  return (
    <svg
      width="208"
      height="52"
      viewBox="0 0 208 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3327 1.55061L19.3079 6.57542C19.4781 8.5758 21.37 10.3639 22.9097 11.3219C25.2163 12.757 30.922 15.7095 30.922 15.7095L32.2574 11.7035L41.2234 26.9647C41.2234 26.9647 24.7883 23.4007 20.239 21.2417C16.1419 19.2973 13.4562 17.7059 12.9629 12.9204L1.20991 24.6734C0.356625 25.5267 0.356625 26.9102 1.20991 27.7634L24.3327 50.8862C25.186 51.7395 26.5694 51.7395 27.4227 50.8862L30.4812 47.8278C30.4748 47.8278 30.4685 47.8279 30.4621 47.828C33.546 44.7441 32.7599 42.7488 28.5272 39.8916C24.3161 37.0491 20.5535 35.5197 20.515 35.5041L19.1796 39.5101L10.2136 24.2488C10.2136 24.2488 26.6486 27.8129 31.1979 29.9718C35.7384 32.1265 37.4319 34.2131 37.7362 40.5728L50.5455 27.7634C51.3988 26.9101 51.3988 25.5267 50.5455 24.6734L27.4227 1.55061C26.5694 0.697322 25.186 0.697321 24.3327 1.55061ZM12.8808 11.215L15.9647 5.0473C15.9647 5.04731 15.9647 5.04729 15.9647 5.0473L12.8808 11.215ZM20.5904 3.50537C20.5903 3.50538 20.5904 3.50536 20.5904 3.50537Z"
        fill="#EEEAE3"
      />
      <path
        d="M64.1854 28.3449V8.87835H68.655L68.7289 12.7938L67.9901 13.0893C68.2117 12.449 68.5442 11.858 68.9874 11.3163C69.4307 10.7499 69.9602 10.2697 70.5758 9.87569C71.1914 9.45705 71.844 9.13692 72.5335 8.91529C73.2231 8.66903 73.9249 8.5459 74.639 8.5459C75.698 8.5459 76.6337 8.71828 77.4464 9.06304C78.2837 9.38318 78.9732 9.90032 79.5149 10.6144C80.0813 11.3286 80.5 12.2397 80.7708 13.3479L80.069 13.2001L80.3645 12.6091C80.6354 12.0181 81.0171 11.4886 81.5096 11.0207C82.0021 10.5282 82.5562 10.0973 83.1719 9.72794C83.7875 9.33392 84.4278 9.03842 85.0927 8.84141C85.7822 8.64441 86.4594 8.5459 87.1243 8.5459C88.6018 8.5459 89.8331 8.84141 90.8181 9.43243C91.8032 10.0234 92.5419 10.9222 93.0345 12.1289C93.527 13.3356 93.7732 14.8254 93.7732 16.5985V28.3449H89.1928V16.894C89.1928 15.909 89.0574 15.0963 88.7865 14.456C88.5403 13.8158 88.1463 13.3479 87.6045 13.0524C87.0873 12.7322 86.4348 12.5722 85.6467 12.5722C85.0311 12.5722 84.4401 12.683 83.8737 12.9046C83.3319 13.1016 82.864 13.3971 82.47 13.7911C82.076 14.1605 81.7682 14.5915 81.5466 15.084C81.3249 15.5765 81.2141 16.1183 81.2141 16.7093V28.3449H76.6337V16.857C76.6337 15.9213 76.4983 15.1456 76.2274 14.5299C75.9565 13.8896 75.5625 13.4094 75.0454 13.0893C74.5282 12.7445 73.9003 12.5722 73.1615 12.5722C72.5459 12.5722 71.9672 12.683 71.4254 12.9046C70.8836 13.1016 70.4157 13.3848 70.0217 13.7542C69.6277 14.1236 69.3199 14.5545 69.0983 15.0471C68.8766 15.5396 68.7658 16.0813 68.7658 16.6723V28.3449H64.1854Z"
        fill="#EEEAE3"
      />
      <path
        d="M106.168 28.714C104.567 28.714 103.114 28.2708 101.809 27.3842C100.504 26.4977 99.4573 25.2911 98.6693 23.7643C97.8813 22.2375 97.4873 20.5013 97.4873 18.5559C97.4873 16.6105 97.8813 14.8744 98.6693 13.3476C99.4573 11.8208 100.529 10.6264 101.883 9.76454C103.237 8.90263 104.764 8.47168 106.463 8.47168C107.448 8.47168 108.347 8.61944 109.16 8.91495C109.973 9.18583 110.687 9.57984 111.302 10.097C111.918 10.6141 112.423 11.2051 112.817 11.87C113.235 12.5349 113.519 13.2491 113.666 14.0125L112.669 13.7539V8.87801H117.249V28.3446H112.632V23.6904L113.703 23.5057C113.531 24.1706 113.211 24.8232 112.743 25.4634C112.3 26.0791 111.733 26.6332 111.044 27.1257C110.379 27.5936 109.628 27.9753 108.79 28.2708C107.978 28.5663 107.104 28.714 106.168 28.714ZM107.424 24.6877C108.483 24.6877 109.418 24.4292 110.231 23.912C111.044 23.3949 111.672 22.6807 112.115 21.7696C112.583 20.8338 112.817 19.7626 112.817 18.5559C112.817 17.3739 112.583 16.3273 112.115 15.4161C111.672 14.505 111.044 13.7908 110.231 13.2737C109.418 12.7566 108.483 12.498 107.424 12.498C106.365 12.498 105.429 12.7566 104.616 13.2737C103.828 13.7908 103.213 14.505 102.77 15.4161C102.326 16.3273 102.105 17.3739 102.105 18.5559C102.105 19.7626 102.326 20.8338 102.77 21.7696C103.213 22.6807 103.828 23.3949 104.616 23.912C105.429 24.4292 106.365 24.6877 107.424 24.6877Z"
        fill="#EEEAE3"
      />
      <path
        d="M126.765 22.3977L125.878 18.1498L135.113 8.87817H141.171L126.765 22.3977ZM122.517 28.3448V1.01025H127.06V28.3448H122.517ZM135.667 28.3448L128.501 19.6643L131.715 16.9308L141.282 28.3448H135.667Z"
        fill="#EEEAE3"
      />
      <path
        d="M150.572 28.7142C148.898 28.7142 147.408 28.3941 146.103 27.7538C144.798 27.0889 143.8 26.2886 143.111 25.3528L144.515 24.1338C145.179 25.0204 146.041 25.7099 147.1 26.2024C148.159 26.6703 149.353 26.9042 150.683 26.9042C151.422 26.9042 152.099 26.8304 152.715 26.6826C153.331 26.5102 153.848 26.2763 154.266 25.9808C154.71 25.6853 155.054 25.3282 155.301 24.9096C155.547 24.4663 155.67 23.9738 155.67 23.432C155.67 22.4716 155.214 21.659 154.303 20.9941C153.885 20.6986 153.355 20.4277 152.715 20.1814C152.075 19.9352 151.324 19.6889 150.462 19.4426C149.156 19.0486 148.048 18.6792 147.137 18.3345C146.226 17.9897 145.5 17.5588 144.958 17.0416C144.564 16.623 144.268 16.1797 144.071 15.7118C143.874 15.2193 143.776 14.6899 143.776 14.1235C143.776 13.3355 143.936 12.6213 144.256 11.9811C144.576 11.3162 145.032 10.7375 145.623 10.2449C146.214 9.75243 146.903 9.38305 147.691 9.13679C148.479 8.86591 149.353 8.73047 150.314 8.73047C151.225 8.73047 152.124 8.86591 153.01 9.13679C153.897 9.38305 154.722 9.74012 155.485 10.208C156.249 10.6759 156.914 11.2177 157.48 11.8333L156.224 13.2C155.732 12.6583 155.19 12.1781 154.599 11.7594C154.032 11.3408 153.404 11.0206 152.715 10.799C152.025 10.5528 151.262 10.4296 150.425 10.4296C149.76 10.4296 149.132 10.5158 148.541 10.6882C147.974 10.8606 147.482 11.1192 147.063 11.4639C146.645 11.784 146.312 12.1657 146.066 12.609C145.844 13.0276 145.734 13.4955 145.734 14.0127C145.734 14.4067 145.807 14.7638 145.955 15.0839C146.103 15.3794 146.337 15.6626 146.657 15.9335C147.076 16.2782 147.63 16.5737 148.319 16.82C149.033 17.0663 149.908 17.3371 150.942 17.6327C151.878 17.8789 152.703 18.1252 153.417 18.3714C154.131 18.6177 154.747 18.9132 155.264 19.258C156.052 19.7505 156.63 20.3292 157 20.9941C157.394 21.659 157.591 22.4224 157.591 23.2843C157.591 24.3432 157.295 25.2789 156.704 26.0916C156.113 26.9042 155.288 27.5445 154.229 28.0124C153.17 28.4803 151.951 28.7142 150.572 28.7142Z"
        fill="#EEEAE3"
      />
      <path
        d="M170.427 28.7142C168.506 28.7142 166.794 28.2833 165.292 27.4214C163.815 26.5595 162.645 25.3651 161.783 23.8383C160.946 22.3115 160.527 20.5754 160.527 18.63C160.527 16.7092 160.946 15.01 161.783 13.5325C162.645 12.0303 163.815 10.8606 165.292 10.0233C166.794 9.16142 168.506 8.73047 170.427 8.73047C172.347 8.73047 174.047 9.16142 175.524 10.0233C177.026 10.8606 178.196 12.0303 179.033 13.5325C179.871 15.01 180.289 16.7092 180.289 18.63C180.289 20.5754 179.871 22.3115 179.033 23.8383C178.196 25.3651 177.026 26.5595 175.524 27.4214C174.047 28.2833 172.347 28.7142 170.427 28.7142ZM170.427 26.8673C171.953 26.8673 173.308 26.5102 174.49 25.7961C175.697 25.0819 176.632 24.1092 177.297 22.8779C177.987 21.6466 178.332 20.2307 178.332 18.63C178.332 17.0786 177.987 15.6995 177.297 14.4929C176.632 13.2616 175.697 12.3012 174.49 11.6117C173.308 10.9221 171.953 10.5774 170.427 10.5774C168.875 10.5774 167.496 10.9221 166.29 11.6117C165.107 12.3012 164.172 13.2616 163.482 14.4929C162.817 15.6995 162.485 17.0786 162.485 18.63C162.485 20.2307 162.817 21.6466 163.482 22.8779C164.172 24.1092 165.107 25.0819 166.29 25.7961C167.496 26.5102 168.875 26.8673 170.427 26.8673Z"
        fill="#EEEAE3"
      />
      <path
        d="M185.76 28.3446V5.40581C185.76 4.59316 185.945 3.87902 186.314 3.26338C186.683 2.62311 187.213 2.11828 187.902 1.74889C188.592 1.37951 189.392 1.19482 190.303 1.19482C191.091 1.19482 191.781 1.33026 192.372 1.60114C192.963 1.87202 193.468 2.25372 193.886 2.74624L193.185 4.22378C192.717 3.70664 192.224 3.36188 191.707 3.1895C191.19 2.99249 190.697 2.89399 190.229 2.89399C189.663 2.89399 189.183 2.99249 188.789 3.1895C188.42 3.3865 188.124 3.68201 187.902 4.07603C187.705 4.44541 187.607 4.90099 187.607 5.44275V28.3446H186.942C186.696 28.3446 186.474 28.3446 186.277 28.3446C186.105 28.3446 185.932 28.3446 185.76 28.3446ZM182.029 10.8358V9.13661H192.852V10.8358H182.029Z"
        fill="#EEEAE3"
      />
      <path
        d="M199.914 28.3449V4.00244H201.761V28.3449H199.914ZM195.297 10.8361V9.1369H206.933V10.8361H195.297Z"
        fill="#EEEAE3"
      />
      <path
        d="M66.0968 47.7009V37.0264H67.3117V47.7009H66.0968ZM64.1854 40.4118V39.2455H69.4498V40.4118H64.1854Z"
        fill="#EEEAE3"
      />
      <path
        d="M74.588 47.8626C73.7457 47.8626 72.9952 47.679 72.3365 47.3119C71.6886 46.9447 71.181 46.4318 70.8139 45.773C70.4467 45.1143 70.2631 44.3584 70.2631 43.5053C70.2631 42.8466 70.3657 42.2527 70.5709 41.7235C70.7761 41.1836 71.0622 40.7138 71.4294 40.3143C71.8074 39.9147 72.2555 39.607 72.7738 39.391C73.2922 39.1642 73.8537 39.0508 74.4585 39.0508C75.0308 39.0508 75.5491 39.1534 76.0135 39.3586C76.4886 39.5638 76.8936 39.8499 77.2283 40.2171C77.5739 40.5734 77.8331 41 78.0058 41.4967C78.1786 41.9935 78.2542 42.5388 78.2326 43.1328L78.2164 43.6997H71.1702L70.9921 42.6792H77.2931L77.0016 42.9546V42.5658C76.98 42.1771 76.8666 41.8045 76.6614 41.4481C76.4562 41.081 76.1647 40.784 75.7867 40.5572C75.4195 40.3305 74.9768 40.2171 74.4585 40.2171C73.8537 40.2171 73.33 40.3413 72.8872 40.5896C72.4445 40.8272 72.1043 41.1836 71.8668 41.6587C71.6292 42.1339 71.5104 42.7224 71.5104 43.4243C71.5104 44.0938 71.64 44.677 71.8991 45.1737C72.1583 45.6597 72.5255 46.0376 73.0006 46.3076C73.4866 46.5775 74.0697 46.7125 74.75 46.7125C75.1496 46.7125 75.5437 46.6477 75.9325 46.5182C76.332 46.3778 76.7694 46.1078 77.2445 45.7083L77.8763 46.5829C77.6279 46.8313 77.3309 47.0527 76.9854 47.2471C76.6398 47.4307 76.2618 47.5818 75.8515 47.7006C75.4411 47.8086 75.02 47.8626 74.588 47.8626Z"
        fill="#EEEAE3"
      />
      <path
        d="M83.5217 47.8625C82.7226 47.8625 82.0099 47.6735 81.3835 47.2956C80.7572 46.9068 80.2605 46.3831 79.8933 45.7243C79.5369 45.0548 79.3588 44.2989 79.3588 43.4566C79.3588 42.6143 79.5369 41.8638 79.8933 41.2051C80.2605 40.5356 80.7572 40.0118 81.3835 39.6339C82.0099 39.2559 82.728 39.0669 83.5379 39.0669C84.2614 39.0669 84.9201 39.2127 85.514 39.5043C86.108 39.785 86.5831 40.1792 86.9395 40.6867L86.2268 41.5614C86.0216 41.2915 85.7732 41.0539 85.4817 40.8487C85.2009 40.6435 84.8931 40.487 84.5584 40.379C84.2344 40.2602 83.9104 40.2008 83.5865 40.2008C83.0141 40.2008 82.5012 40.3466 82.0476 40.6381C81.5941 40.9189 81.2323 41.3023 80.9624 41.7882C80.7032 42.2741 80.5736 42.8303 80.5736 43.4566C80.5736 44.0829 80.7032 44.6445 80.9624 45.1412C81.2323 45.6272 81.5941 46.0105 82.0476 46.2913C82.5012 46.5721 83.0087 46.7124 83.5703 46.7124C83.8942 46.7124 84.2128 46.6638 84.526 46.5667C84.8391 46.4587 85.1361 46.3075 85.4169 46.1131C85.6976 45.9187 85.9568 45.6758 86.1944 45.3842L86.9071 46.2589C86.5399 46.7448 86.0486 47.1336 85.4331 47.4252C84.8175 47.7167 84.1804 47.8625 83.5217 47.8625Z"
        fill="#EEEAE3"
      />
      <path
        d="M88.4843 47.7005V35.7139H89.6991V41.0917L89.4561 41.3347C89.5749 40.8919 89.7963 40.5032 90.1203 40.1684C90.4442 39.8228 90.8222 39.5529 91.2541 39.3585C91.6969 39.1641 92.145 39.0669 92.5986 39.0669C93.2465 39.0669 93.7864 39.1911 94.2184 39.4395C94.6503 39.6878 94.9689 40.0712 95.1741 40.5895C95.39 41.1079 95.498 41.7666 95.498 42.5657V47.7005H94.267V42.6305C94.267 42.069 94.1914 41.61 94.0402 41.2537C93.8998 40.8865 93.6784 40.6165 93.3761 40.4438C93.0737 40.271 92.6958 40.19 92.2422 40.2008C91.8859 40.2008 91.5511 40.2656 91.2379 40.3952C90.9248 40.514 90.6548 40.6813 90.428 40.8973C90.2013 41.1133 90.0231 41.3617 89.8935 41.6424C89.7639 41.9232 89.6991 42.2256 89.6991 42.5495V47.7005H89.0998C89.0242 47.7005 88.9378 47.7005 88.8406 47.7005C88.7434 47.7005 88.6246 47.7005 88.4843 47.7005Z"
        fill="#EEEAE3"
      />
      <path
        d="M97.8171 47.7005V39.2451H99.0157L99.0643 41.0107L98.8699 41.0917C98.9779 40.7245 99.1885 40.3898 99.5017 40.0874C99.8148 39.785 100.187 39.5421 100.619 39.3585C101.062 39.1641 101.516 39.0669 101.98 39.0669C102.606 39.0669 103.13 39.1911 103.551 39.4395C103.972 39.6878 104.291 40.0712 104.507 40.5895C104.723 41.1079 104.831 41.7666 104.831 42.5657V47.7005H103.6V42.6305C103.6 42.069 103.524 41.61 103.373 41.2537C103.233 40.8865 103.011 40.6165 102.709 40.4438C102.407 40.271 102.034 40.19 101.591 40.2008C101.224 40.2008 100.884 40.2656 100.571 40.3952C100.258 40.514 99.9876 40.6813 99.7608 40.8973C99.5341 41.1133 99.3559 41.3617 99.2263 41.6424C99.0967 41.9232 99.0319 42.2256 99.0319 42.5495V47.7005H98.4326C98.357 47.7005 98.2706 47.7005 98.1734 47.7005C98.0762 47.7005 97.9574 47.7005 97.8171 47.7005Z"
        fill="#EEEAE3"
      />
      <path
        d="M110.892 47.8625C110.05 47.8625 109.299 47.6735 108.64 47.2956C107.992 46.9176 107.479 46.3939 107.101 45.7243C106.734 45.0548 106.551 44.2935 106.551 43.4404C106.551 42.5981 106.734 41.8476 107.101 41.1889C107.479 40.5302 107.992 40.0118 108.64 39.6339C109.299 39.2559 110.05 39.0669 110.892 39.0669C111.723 39.0669 112.463 39.2559 113.111 39.6339C113.77 40.0118 114.283 40.5302 114.65 41.1889C115.028 41.8476 115.217 42.5981 115.217 43.4404C115.217 44.2935 115.028 45.0548 114.65 45.7243C114.283 46.3939 113.77 46.9176 113.111 47.2956C112.463 47.6735 111.723 47.8625 110.892 47.8625ZM110.892 46.7124C111.486 46.7124 112.015 46.5721 112.479 46.2913C112.944 45.9997 113.305 45.611 113.565 45.125C113.824 44.6283 113.953 44.0667 113.953 43.4404C113.953 42.8141 113.824 42.258 113.565 41.772C113.305 41.2861 112.944 40.9081 112.479 40.6381C112.015 40.3574 111.486 40.217 110.892 40.217C110.298 40.217 109.763 40.3574 109.288 40.6381C108.824 40.9081 108.457 41.2915 108.187 41.7882C107.928 42.2741 107.803 42.8249 107.814 43.4404C107.803 44.0667 107.928 44.6283 108.187 45.125C108.457 45.611 108.824 45.9997 109.288 46.2913C109.763 46.5721 110.298 46.7124 110.892 46.7124Z"
        fill="#EEEAE3"
      />
      <path
        d="M117.022 47.7005V35.7139H118.236V47.7005H117.022Z"
        fill="#EEEAE3"
      />
      <path
        d="M124.211 47.8625C123.369 47.8625 122.618 47.6735 121.959 47.2956C121.312 46.9176 120.799 46.3939 120.421 45.7243C120.053 45.0548 119.87 44.2935 119.87 43.4404C119.87 42.5981 120.053 41.8476 120.421 41.1889C120.799 40.5302 121.312 40.0118 121.959 39.6339C122.618 39.2559 123.369 39.0669 124.211 39.0669C125.042 39.0669 125.782 39.2559 126.43 39.6339C127.089 40.0118 127.602 40.5302 127.969 41.1889C128.347 41.8476 128.536 42.5981 128.536 43.4404C128.536 44.2935 128.347 45.0548 127.969 45.7243C127.602 46.3939 127.089 46.9176 126.43 47.2956C125.782 47.6735 125.042 47.8625 124.211 47.8625ZM124.211 46.7124C124.805 46.7124 125.334 46.5721 125.798 46.2913C126.263 45.9997 126.624 45.611 126.884 45.125C127.143 44.6283 127.272 44.0667 127.272 43.4404C127.272 42.8141 127.143 42.258 126.884 41.772C126.624 41.2861 126.263 40.9081 125.798 40.6381C125.334 40.3574 124.805 40.217 124.211 40.217C123.617 40.217 123.083 40.3574 122.607 40.6381C122.143 40.9081 121.776 41.2915 121.506 41.7882C121.247 42.2741 121.123 42.8249 121.133 43.4404C121.123 44.0667 121.247 44.6283 121.506 45.125C121.776 45.611 122.143 45.9997 122.607 46.2913C123.083 46.5721 123.617 46.7124 124.211 46.7124Z"
        fill="#EEEAE3"
      />
      <path
        d="M133.953 51.4261C133.327 51.4261 132.733 51.3289 132.171 51.1345C131.61 50.9401 131.145 50.6972 130.778 50.4056L131.264 49.3851C131.48 49.5579 131.728 49.7091 132.009 49.8387C132.301 49.9791 132.614 50.087 132.949 50.1626C133.283 50.249 133.624 50.2922 133.969 50.2922C134.595 50.2922 135.125 50.1734 135.556 49.9359C135.999 49.7091 136.334 49.3689 136.561 48.9154C136.798 48.4618 136.917 47.9003 136.917 47.2308V45.611L137.112 45.5624C137.014 45.9727 136.804 46.3561 136.48 46.7124C136.156 47.058 135.762 47.3388 135.297 47.5547C134.833 47.7599 134.342 47.8625 133.823 47.8625C133.035 47.8625 132.328 47.6735 131.701 47.2956C131.086 46.9176 130.6 46.3993 130.244 45.7405C129.887 45.071 129.709 44.3151 129.709 43.4728C129.709 42.6197 129.887 41.8638 130.244 41.2051C130.611 40.5356 131.102 40.0118 131.718 39.6339C132.333 39.2559 133.024 39.0669 133.791 39.0669C134.126 39.0669 134.45 39.1101 134.763 39.1965C135.076 39.2721 135.362 39.3855 135.621 39.5367C135.891 39.6878 136.134 39.8606 136.35 40.055C136.566 40.2494 136.744 40.46 136.885 40.6867C137.036 40.9135 137.144 41.1457 137.209 41.3833L136.869 41.3347L136.917 39.2451H138.132V47.1822C138.132 47.8625 138.035 48.4618 137.84 48.9802C137.646 49.5093 137.36 49.9521 136.982 50.3084C136.615 50.6756 136.172 50.9509 135.654 51.1345C135.146 51.3289 134.579 51.4261 133.953 51.4261ZM133.969 46.6962C134.541 46.6962 135.049 46.5613 135.492 46.2913C135.945 46.0105 136.302 45.6272 136.561 45.1412C136.82 44.6553 136.95 44.0937 136.95 43.4566C136.95 42.8303 136.82 42.2741 136.561 41.7882C136.312 41.3023 135.961 40.9189 135.508 40.6381C135.054 40.3574 134.541 40.217 133.969 40.217C133.386 40.217 132.868 40.3574 132.414 40.6381C131.971 40.9189 131.62 41.3023 131.361 41.7882C131.102 42.2741 130.972 42.8303 130.972 43.4566C130.972 44.0721 131.097 44.6283 131.345 45.125C131.604 45.611 131.955 45.9943 132.398 46.2751C132.851 46.5559 133.375 46.6962 133.969 46.6962Z"
        fill="#EEEAE3"
      />
      <path
        d="M141.921 51.4261L143.557 47.4413L143.67 48.5914L139.426 39.2451H140.803L143.703 45.7243C143.789 45.8971 143.875 46.1023 143.962 46.3399C144.048 46.5667 144.129 46.7934 144.205 47.0202L143.751 46.9716C143.827 46.788 143.897 46.599 143.962 46.4047C144.037 46.2103 144.108 46.0213 144.172 45.8377L146.715 39.2451H148.109L144.691 47.7005L143.233 51.4261H141.921Z"
        fill="#EEEAE3"
      />
    </svg>
  );
}
