import { HeroProvider } from "../../../contexts/HeroContext";
import { StartProvider } from "../../../contexts/StartContext";
import { WhyMdsProvider } from "../../../contexts/WhyMdsContext";
import { TeamProvider } from "../../../contexts/TeamContext";
import { InvestorsProvider } from "../../../contexts/InvestorsContext";
import { FaqProvider } from "../../../contexts/FaqContext";
import { SignUpProvider } from "../../../contexts/SignUpContext";
import { FooterProvider } from "../../../contexts/FooterContext";

import Hero from "./hero/Hero";
import Start from "./start/Start";
import WhyMds from "./why-mds/WhyMds";
import Team from "./team/Team";
import Investors from "./investors/Investors";
import Faq from "./faq/Faq";
import SignUp from "./sign-up/SignUp";
import Footer from "../../footer/Footer";

import "./Home.css";

export default function Home() {
  const componentClass = "home";

  return (
    <>
      <main className={componentClass} id={componentClass}>
        <HeroProvider>
          <Hero parentClass={componentClass} />
        </HeroProvider>
        <StartProvider>
          <Start parentClass={componentClass} />
        </StartProvider>
        <WhyMdsProvider>
          <WhyMds parentClass={componentClass} />
        </WhyMdsProvider>
        <TeamProvider>
          <Team parentClass={componentClass} />
        </TeamProvider>
        <InvestorsProvider>
          <Investors parentClass={componentClass} />
        </InvestorsProvider>
        <FaqProvider>
          <Faq parentClass={componentClass} />
        </FaqProvider>
        <SignUpProvider>
          <SignUp parentClass={componentClass} />
        </SignUpProvider>
      </main>
      <FooterProvider>
        <Footer />
      </FooterProvider>
    </>
  );
}
