import AccordBtn from "../accord-btn/AccordBtn";
import AccordBody from "../accord-body/AccordBody";
import AccordCard from "../accord-card/AccordCard";

import "./AccordItem.css";

export default function AccordItem({ itemId }) {
  return (
    <div className="accordion-item">
      <AccordBtn itemId={itemId} />
      <div
        id={`collapse-${itemId}`}
        className="accordion-collapse collapse"
        data-bs-parent="#accordion">
        <AccordBody>
          <AccordCard itemId={itemId} />
        </AccordBody>
      </div>
    </div>
  );
}
