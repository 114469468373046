import "./Title.css";

export default function Title({ parentClass, index, children }) {
  const titleClass = "title";
  const componentClass = `${parentClass}__${titleClass}`;

  switch (index) {
    case 1:
      return <h1 className={componentClass}>{children}</h1>;

    case 2:
      return <h2 className={componentClass}>{children}</h2>;

    case 3:
      return <h3 className={componentClass}>{children}</h3>;

    case 4:
      return <h4 className={componentClass}>{children}</h4>;

    case 5:
      return <h5 className={componentClass}>{children}</h5>;

    case 6:
      return <h6 className={componentClass}>{children}</h6>;

    default:
      return <h6 className={componentClass}>{children}</h6>;
  }
}
