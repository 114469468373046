import { useContext } from "react";
import { LangIdContext } from "../../../contexts/LangIdContext";
import { motion, AnimatePresence } from "framer-motion";

import okImg from "./ok-icon.webp";

import "./PostPopup.css";

export default function PostPopup({ parentClass, isLoading, isLoaded }) {
  const { langId } = useContext(LangIdContext);

  const componentClass = "post-popup";
  const innerInfoClass = `inner-info`;
  const popupWrapClass = `${parentClass}__${componentClass} ${componentClass}`;
  const popupInnerInfoClass = `${componentClass}__${innerInfoClass} ${innerInfoClass}`;

  const enTitleText = "Success!";
  const ukTitleText = "Відправлено!";

  const titleText = langId === "uk" ? ukTitleText : enTitleText;

  isLoading && document.querySelector("body").classList.add("scroll-locked");
  !isLoading &&
    document.querySelector("body").classList.remove("scroll-locked");

  const variants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
  };

  return (
    (isLoading || isLoaded) && (
      <AnimatePresence mode="wait">
        <motion.div
          key="post-popup"
          variants={variants}
          initial="enter"
          animate="center"
          transition={{ duration: 0.25 }}>
          <div className={popupWrapClass}>
            <div className={popupInnerInfoClass}>
              {isLoading && !isLoaded && (
                <AnimatePresence mode="wait">
                  <motion.div
                    key="spiner"
                    variants={variants}
                    initial="enter"
                    animate="center"
                    transition={{ duration: 0.1 }}>
                    <div className="spinner-border" role="status"></div>
                  </motion.div>
                </AnimatePresence>
              )}
              {isLoading && isLoaded && (
                <AnimatePresence mode="wait">
                  <motion.div
                    key="done"
                    variants={variants}
                    initial="enter"
                    animate="center"
                    transition={{ duration: 0.1 }}>
                    <img
                      className={`${innerInfoClass}__img`}
                      src={okImg}
                      alt="success icon"
                    />
                    <h3 className={`${innerInfoClass}__title`}>{titleText}</h3>
                  </motion.div>
                </AnimatePresence>
              )}
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    )
  );
}
