import "./Pay.css";

const langData = {
  title:
    "How to Pay for Your Online Course via Internet Acquiring from Sens Bank",
  sections: [
    {
      title: "Proceed to the Payment Page",
      subtitle:
        "After selecting and registering for your online course, you will be prompted to proceed to the payment page. At this stage, the system will redirect you to a secure page to complete your payment via internet acquiring from Sens Bank.",
    },
    {
      title: "Filling in the Payment Form",
      subtitle: "On the payment page, you can choose one of two methods:",
      items: [
        "Payment by Card: Enter your card number, expiration date, and CVV code. You may also be asked to provide the cardholder’s name and billing address.",
        "Payment via Apple Pay or Google Pay: For convenience, you can also use Apple Pay or Google Pay. Simply select the relevant payment method, and the system will automatically retrieve your payment information from the connected service.",
      ],
      p: "Note: For any selected option, it is necessary to provide the email address to which you will receive the lesson invitation.",
    },
    {
      title: "Payment Security",
      subtitle:
        "To ensure the security of your transaction, Sens Bank uses modern encryption technologies. If you are paying by card, 3D Secure (Verified by Visa or MasterCard SecureCode) will provide additional protection: you will receive a one-time code to confirm the payment.",
    },
    {
      title: "Payment Confirmation and Processing",
      subtitle:
        "After entering and confirming your details, your payment will be processed via internet acquiring from Sens Bank. If everything is successful, you will receive a confirmation of the successful payment.",
    },
    {
      title: "Receiving Access to the Course",
      subtitle:
        "After successfully completing the payment, you will receive a confirmation email. Access to the online course will be provided immediately or according to the course conditions.",
    },
  ],
};

export default function PayEn() {
  return (
    <>
      <h2 className="offer-section-title">{langData?.title}</h2>
      <ul className="offer-section-items">
        {langData?.sections.map((sectionData, i) => (
          <li key={`offer-item-${i}`} className="offer-section-item">
            <h2 className="offer-section-item-title">
              {i + 1 + ". " + sectionData?.title}
            </h2>
            {sectionData?.subtitle && (
              <p className="offer-section-item-subtitle">
                {sectionData?.subtitle}
              </p>
            )}
            {sectionData?.items && (
              <ul className="offer-section-item-subitems">
                {sectionData?.items.map((itemText, j) => (
                  <li
                    key={`offer-subitem-${j}`}
                    className="offer-section-item-subitem">
                    {itemText}
                  </li>
                ))}
              </ul>
            )}
            {sectionData?.p && (
              <p className="offer-section-item-p">{sectionData?.p}</p>
            )}
          </li>
        ))}
      </ul>
      <p
        className="offer-section-subtitle"
        style={{ marginBottom: 0, width: "100%" }}>
        {langData?.subtitle}
      </p>
    </>
  );
}
