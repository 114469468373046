import { createContext, useState, useContext, useEffect } from "react";
import { getLang } from "../firebase/firestore";
import { LangIdContext } from "./LangIdContext";

export const CompanyHeroContext = createContext(null);

export function CompanyHeroProvider({ children }) {
  const [langData, setLangData] = useState(null);
  const { langId } = useContext(LangIdContext);

  const companyHeroId = "companyHero";

  useEffect(() => {
    let done = false;

    if (!done) {
      getLang(setLangData, langId, companyHeroId);
    }

    return () => {
      done = true;
    };
  }, [langId]);

  return (
    <CompanyHeroContext.Provider value={{ langData, setLangData }}>
      {children}
    </CompanyHeroContext.Provider>
  );
}
