import Title from "../../../../topography/title/Title";
import Tag from "../../../../ui/tag/Tag";
import CountBar from "../../../../ui/count-bar/CountBar";

import "./Header.css";

export default function Header({
  parentClass,
  titleText,
  tagText,
  currentId,
  itemsCount,
}) {
  const componentClass = "head";

  return (
    <div className={`${parentClass}__${componentClass} ${componentClass}`}>
      <div className={`${componentClass}__topography`}>
        <Title parentClass={componentClass} index={2}>
          {titleText.toUpperCase()}
        </Title>
        <Tag parentClass={componentClass}>{tagText.toUpperCase()}</Tag>
      </div>
      <CountBar
        parentClass={componentClass}
        itemsCount={itemsCount}
        currentNumber={currentId}
      />
    </div>
  );
}
