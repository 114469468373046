import { useContext } from "react";
import { FooterContext } from "../../contexts/FooterContext";
import { WhiteBlueLogoImg } from "../logo/logo-imgs/LogoImgs";

import VisaImg from "../icons/visa/VisaImg";
import MacterCardImg from "../icons/master-card/MasterCardImg";
import Logo from "../logo/Logo";
import NavLinks from "./nav-links/NavLinks";
import NetworkLinks from "./network-links/NetworkLinks";
import InfoLinks from "./info-links/InfoLinks";
import ContactLinks from "./contact-links/ContactLinks";
import MsgrLinks from "./msgr-links/MsgrLinks";

import "./Footer.css";

export default function Footer({ bgc }) {
  const { langData, footerData } = useContext(FooterContext);

  const componentClass = "footer";
  const navClass = `${componentClass}__nav`;

  if (Boolean(langData) && Boolean(footerData)) {
    return (
      <footer
        className={`${bgc ? componentClass + " black" : componentClass}`}
        id={componentClass}>
        <div className={`${componentClass}__bg`}>
          <nav className={navClass}>
            <Logo parentClass={componentClass} path={footerData.logoPath}>
              <WhiteBlueLogoImg />
            </Logo>
            <MsgrLinks parentClass={componentClass} />
            <NavLinks parentClass={componentClass} />
            <NetworkLinks parentClass={componentClass} />
            <ContactLinks parentClass={componentClass} />
            <InfoLinks parentClass={componentClass} />
          </nav>
          <div className="footer__botbar">
            <p className="footer__botbar__company-text">{langData?.companyText}</p>
            <div className={`${componentClass}__paycard-icons`}>
              <MacterCardImg parentClass={componentClass} />
              <VisaImg parentClass={componentClass} />
            </div>
          </div>
        </div>
      </footer>
    );
  }

  return null;
}
