import { useContext } from "react";
import { StartContext } from "../../../../contexts/StartContext";
import { StartCardsVisibleProvider } from "../../../../contexts/StartCardsVisibleContext";
import { IsMobileContext } from "../../../../contexts/IsMobileContext";

import Title from "../../../topography/title/Title";
import Paragraph from "../../../topography/paragraph/Paragraph";
import StartBtns from "./start-btns/StartBtns";
import StartCards from "./start-cards/StartCards";
import Accordion from "./accordion/Accordion";

import "./Start.css";

export default function Start({ parentClass }) {
  const { langData } = useContext(StartContext);
  const { isMobile } = useContext(IsMobileContext);

  const componentClass = "start";

  if (Boolean(langData)) {
    return (
      <section
        className={`${parentClass}__${componentClass} ${componentClass}`} id="start">
        <Title parentClass={componentClass} index={2}>
          {langData.title.toUpperCase()}
        </Title>
        <Paragraph parentClass={componentClass}>
          {<strong className="bold">{langData.text.slice(0, 3)}</strong>}
          {langData.text.slice(3)}
        </Paragraph>
        {isMobile ? (
          <StartCardsVisibleProvider>
            <Accordion parentClass={componentClass} />
          </StartCardsVisibleProvider>
        ) : (
          <StartCardsVisibleProvider>
            <StartBtns parentClass={componentClass} />
            <StartCards parentClass={componentClass} />
          </StartCardsVisibleProvider>
        )}
      </section>
    );
  }

  return null;
}
