import { useContext } from "react";
import { FooterContext } from "../../../contexts/FooterContext";

import NetworkLink from "../../ui/links/network-link/NetworkLink";
import Title from "../../topography/title/Title";

export default function NetworkLinks({ parentClass }) {
  const { langData, footerData } = useContext(FooterContext);

  const componentClass = "network-links";

  if (Boolean(langData) && Boolean(footerData)) {
    return (
      <div className={`${parentClass}__${componentClass} ${componentClass}`}>
        <Title parentClass={componentClass} index={3}>
          {langData.titles.networkTitle}
        </Title>
        <ul className={`${componentClass}__items`}>
          {langData.networkLinkTexts.map(
            (navLinkText, id) =>
              ![1, 2].includes(id) && (
                <li key={id} className={`${componentClass}__item`}>
                  <NetworkLink
                    parentClass={componentClass}
                    linkHref={footerData.networkLinkPaths[id]}>
                    {navLinkText}
                  </NetworkLink>
                </li>
              )
          )}
        </ul>
      </div>
    );
  }

  return null;
}
