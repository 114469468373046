import "./ColoredTitle.css";

export default function ColoredTitle({ parentClass, textBefore, coloredText, textAfter }) {
  const componentClass = `${parentClass}__colored-title`;
  const innerTextClass = `${parentClass}__colored-text`;

  return (
    <h1 className={componentClass}>
      {`${textBefore} `}
      <span className={innerTextClass}>{coloredText}</span>
      {` ${textAfter}`}
    </h1>
  );
}
