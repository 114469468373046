import { useContext } from "react";
import { StartContext } from "../../../../../contexts/StartContext";
import { StartCardsVisibleContext } from "../../../../../contexts/StartCardsVisibleContext";
import { motion, AnimatePresence } from "framer-motion";

import StartCard from "./start-card/StartCard";

import "./StartCards.css";

export default function StartCards({ parentClass }) {
  const { langData } = useContext(StartContext);
  const { visibleId } = useContext(StartCardsVisibleContext);

  const variants = {
    enter: { opacity: 0, x: 35 },
    center: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -35 },
  };

  if (Boolean(langData)) {
    return (
      <ul className={`${parentClass}__cards`}>
        {langData[visibleId].cardsData.map((cardData, index) => (
          <AnimatePresence mode="wait" key={index}>
            <motion.div
              key={`${visibleId}-${index}`}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ duration: 0.35 }}>
              <StartCard
                parentClass={parentClass}
                cardData={cardData}
                cardIndex={index}
              />
            </motion.div>
          </AnimatePresence>
        ))}
      </ul>
    );
  }

  return null;
}
