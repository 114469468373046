import { useContext } from "react";
import { TeamContext } from "../../../../contexts/TeamContext";

import Title from "../../../topography/title/Title";
import TeamBody from "./tean-body/TeamBody";

import "./Team.css";

export default function Team({ parentClass }) {
  const { langData } = useContext(TeamContext);

  const componentClass = "team";
  const containerClass = `${parentClass}__${componentClass} ${componentClass}`;

  if (Boolean(langData)) {
    return (
      <section className={containerClass} id={componentClass}>
        <Title parentClass={componentClass} index={2}>
          {langData.title.toUpperCase()}
        </Title>
        <TeamBody parentClass={componentClass} />
        <div className={`${parentClass}__bottom-line`}></div>
      </section>
    );
  }

  return null;
}
