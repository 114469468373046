import { useContext } from "react";
import { MenuContext } from "../../../contexts/MenuContext";

import NavLink from "../../ui/links/nav-link/NavLink";

import "./MenuNav.css";

export default function MenuNav({ parentClass }) {
  const { langData, menuData } = useContext(MenuContext);

  const navClass = "nav";
  const componentClass = `${parentClass}__${navClass} ${navClass}`;
  const itemsClass = `${navClass}__items`;
  const itemClass = `${navClass}__item`;

  if (Boolean(langData) && Boolean(menuData)) {
    const linksData = [
      {
        text: langData.navLinks.toHomeText,
        href: menuData.navLinks.toHomePath,
      },
      {
        text: langData.navLinks.toCoursesText,
        href: menuData.navLinks.toCoursesPath,
      },
      {
        text: langData.navLinks.toCompanyText,
        href: menuData.navLinks.toCompanyPath,
      },
      {
        text: langData.navLinks.toTeamText,
        href: menuData.navLinks.toTeamPath,
      },
      {
        text: langData.navLinks.toAnswersText,
        href: menuData.navLinks.toAnswersPath,
      },
      {
        text: langData.navLinks.toContactText,
        href: menuData.navLinks.toContactPath,
      },
    ];

    return (
      <nav className={componentClass}>
        <ul className={itemsClass}>
          {linksData.map((linkData, index) => (
            <li key={index} className={itemClass}>
              <NavLink parentClass={navClass} path={linkData.href}>
                {linkData.text}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    );
  }

  return null;
}
