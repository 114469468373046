import { WhiteBlueLogoImg } from "../logo/logo-imgs/LogoImgs";
import { IsMobileContext } from "../../contexts/IsMobileContext";
import { useContext } from "react";

import Logo from "../logo/Logo";
import MenuBtn from "../ui/btns/menu-btn/MenuBtn";
import LangSelect from "../ui/btns/lang-select/LangSelect";

import "./Header.css";

export default function Header() {
  const { isMobile } = useContext(IsMobileContext);

  const componentClass = "header";

  return (
    <header className={componentClass} id={componentClass}>
      <Logo parentClass={componentClass}>
        <WhiteBlueLogoImg />
      </Logo>
      <MenuBtn parentClass={componentClass} />
      {!isMobile && <LangSelect parentClass={componentClass} />}
    </header>
  );
}
