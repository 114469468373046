import "./Privacy.css";

const langData = {
  title: "Privacy Policy",
  subtitle:
    "This Privacy Policy outlines how our website collects, uses, stores, and protects the personal data of users who register for programming courses or engage in other activities on our website.",
  sections: [
    {
      title: "Collection of Personal Data",
      subtitle: "We collect and process the following personal data of users:",
      items: [
        "First and last name.",
        "Email address.",
        "Phone number.",
        "Data required for course registration.",
        "Other data necessary for providing services or interacting with the website.",
      ],
      p: "Personal data is collected only with the user's voluntary consent. You have the right to refuse to provide data, but this may limit your ability to use certain website features.",
    },
    {
      title: "Use of Personal Data",
      subtitle:
        "The collected personal data is used for the following purposes:",
      items: [
        "Registration for programming courses.",
        "Providing information about our services.",
        "Processing applications and communicating with users.",
        "Sending updates, promotions, or other marketing materials (with the user's consent).",
      ],
      p: "We do not share personal data with third parties without your consent, except as required by Ukrainian law.",
    },
    {
      title: "Protection of Personal Data",
      subtitle:
        "We take all necessary measures to protect personal data from unauthorized access, alteration, disclosure, or destruction. This includes using modern encryption methods and information security tools.",
    },
    {
      title: "Payment Information and Processing",
      subtitle:
        "All payment information is processed and collected by our service provider, Sense Bank. We do not store or process payment data on our website. Sense Bank ensures the security of payment information in accordance with the Payment Card Industry (PCI) standards and other applicable regulations.",
    },
    {
      title: "Cookies",
      subtitle:
        "Our website uses cookies to enhance the user experience and provide more personalized services. Cookies allow us to store your preferences and browsing history on the website. You can disable cookies through your browser settings, but this may affect the functionality of certain parts of the website.",
    },
    {
      title: "User Rights",
      subtitle: "Users have the right to:",
      items: [
        "Access their personal data.",
        "Correct inaccuracies in their data.",
        "Request the deletion of their personal data.",
        "Withdraw consent for data processing.",
      ],
      p: "To exercise your rights, you can contact us via the contact information provided on the website.",
    },
    {
      title: "Changes to the Privacy Policy",
      subtitle:
        "We reserve the right to make changes to this Privacy Policy at any time. All changes will be published on this page. We recommend regularly reviewing this Policy to stay informed about updates.",
    },
    {
      title: "Contact Information",
      subtitle:
        "If you have any questions about this Privacy Policy or the processing of your personal data, please contact us via the email or phone number provided on our website.",
    },
  ],
};

export default function PrivacyEn() {
  return (
    <>
      <h2 className="privacy-section-title">{langData?.title}</h2>
      <p className="privacy-section-subtitle">{langData?.subtitle}</p>
      <ul className="privacy-section-items">
        {langData?.sections.map((sectionData, i) => (
          <li key={`privacy-item-${i}`} className="privacy-section-item">
            <h2 className="privacy-section-item-title">
              {i + 1 + ". " + sectionData?.title}
            </h2>
            <p className="privacy-section-item-subtitle">
              {sectionData?.subtitle}
            </p>
            {sectionData?.items && (
              <ul className="privacy-section-item-subitems">
                {sectionData?.items.map((itemText, j) => (
                  <li
                    key={`privacy-subitem-${j}`}
                    className="privacy-section-item-subitem">
                    {itemText}
                  </li>
                ))}
              </ul>
            )}
            {sectionData?.p && (
              <p className="privacy-section-item-p">{sectionData?.p}</p>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}
