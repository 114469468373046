import "./Location.css";

export default function Mail({ parentClass, locationHref, children }) {
  const componentClass = "location";

  return (
    <a
      className={`${parentClass}-${componentClass}`}
      href={locationHref}
      target="_blank"
      rel="noopener noreferrer nofollow">
      {children}
    </a>
  );
}
