import { createContext, useState, useContext, useEffect } from "react";
import { getLang, getProps } from "../firebase/firestore";
import { LangIdContext } from "./LangIdContext";

export const FooterContext = createContext(null);

export function FooterProvider({ children }) {
  const [langData, setLangData] = useState(null);
  const [footerData, setFooterData] = useState(null);
  const { langId } = useContext(LangIdContext);

  const footerId = "footer";

  useEffect(() => {
    let done = false;

    if (!done) {
      getLang(setLangData, langId, footerId);
    }

    return () => {
      done = true;
    };
  }, [langId]);

  useEffect(() => {
    let done = false;

    if (!done) {
      getProps(setFooterData, footerId);
    }

    return () => {
      done = true;
    };
  }, []);

  return (
    <FooterContext.Provider
      value={{ langData, setLangData, footerData, setFooterData }}>
      {children}
    </FooterContext.Provider>
  );
}
