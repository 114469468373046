import { useContext } from "react";
import { FooterProvider } from "../../../contexts/FooterContext";
import { LangIdContext } from "../../../contexts/LangIdContext";

import Footer from "../../footer/Footer";
import PayEn from "./PayEn";
import PayUk from "./PayUk";

import "./Pay.css";

const componentClass = "pay";

export default function Pay() {
  const { langId } = useContext(LangIdContext);

  return (
    <>
      <main className={componentClass} id={componentClass}>
        {langId === "en" && <PayEn />}
        {langId === "uk" && <PayUk />}
      </main>
      <FooterProvider>
        <Footer bgc={true} />
      </FooterProvider>
    </>
  );
}
