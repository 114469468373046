import bagels from "./courses-hero-bagels.webp";
import LazyImg from "../../../../lazy-img/LazyImg";

import "./Bagels.css";

export default function Bagels({ parentClass }) {
  const componentClass = "bagels";

  return (
    <div className={`${parentClass}__${componentClass} ${componentClass}`}>
      <LazyImg
        parentClass={componentClass}
        imgSrc={bagels}
        altImgText="MDS bagels"
        targetId="bagels-lazy-img"
      />
    </div>
  );
}
