import { createContext, useState, useContext, useEffect } from "react";
import { getLang, getProps } from "../firebase/firestore";
import { LangIdContext } from "./LangIdContext";

export const TeamContext = createContext(null);

export function TeamProvider({ children }) {
  const [langData, setLangData] = useState(null);
  const [teamData, setTeamData] = useState(null);
  const { langId } = useContext(LangIdContext);

  const teamId = "team";

  useEffect(() => {
    let done = false;

    if (!done) {
      getLang(setLangData, langId, teamId);
    }

    return () => {
      done = true;
    };
  }, [langId]);

  useEffect(() => {
    let done = false;

    if (!done) {
      getProps(setTeamData, teamId);
    }

    return () => {
      done = true;
    };
  }, []);

  return (
    <TeamContext.Provider
      value={{ langData, setLangData, teamData, setTeamData }}>
      {children}
    </TeamContext.Provider>
  );
}
