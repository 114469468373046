import { useContext } from "react";
import { CuratorContext } from "../../../../contexts/CuratorContext";
import { ContactCardProvider } from "../../../../contexts/ContactCardContext";

import ContactCard from "../../../ui/contact-card/ContactCard";
// import Title from "../../../topography/title/Title";
// import Img from "../../../img/Img";

import "./Curator.css";

export default function Curator({ parentClass }) {
  const { langData } = useContext(CuratorContext);

  const formId = `coursesCuratorContactForm`;
  const componentClass = "curator";
  // const bodyClass = "body";
  // const aboutClass = "about";

  if (Boolean(langData)) {
    return (
      <section
        className={`${parentClass}__${componentClass} ${componentClass}`}
        id={componentClass}>
        {/* <div className={`${componentClass}__${bodyClass} ${bodyClass}`}>
          <Title index={2} parentClass={bodyClass}>
            {langData.title.toUpperCase()}
          </Title>
          <Img parentClass={bodyClass} />
          <div className={`${bodyClass}__${aboutClass} ${aboutClass}`}>
            <Title index={3} parentClass={aboutClass}>
              {langData.name.toUpperCase()}
            </Title>
            <Title index={4} parentClass={aboutClass}>
              {langData.specialty}
            </Title>
          </div>
        </div> */}
        <ContactCardProvider>
          <ContactCard parentClass={componentClass} formId={formId} />
        </ContactCardProvider>
      </section>
    );
  }

  return null;
}
