import { useContext } from "react";
import { SignUpContext } from "../../../../contexts/SignUpContext";

import signUpBagel from "./sign-up-bagel.webp";
import BtnLink from "../../../ui/btns/btn-link/BtnLink";

import "./SignUp.css";

export default function SignUp({ parentClass }) {
  const { langData } = useContext(SignUpContext);

  const componentClass = "sign-up";
  const containerBtnClass = `${componentClass}__container-btn`;
  const containerImgClass = `${componentClass}__container-img`;
  const imgClass = `${componentClass}__img`;

  if (Boolean(langData)) {
    return (
      <section
        className={`${parentClass}__${componentClass} ${componentClass}`}
        id="signUp">
        <div className={containerImgClass}>
          <img className={imgClass} src={signUpBagel} alt="MDS bagels" />
        </div>
        <div className={containerBtnClass}>
          <BtnLink parentClass={componentClass} path="/contact">
            {langData.btnText.toUpperCase()}
          </BtnLink>
        </div>
      </section>
    );
  }

  return null;
}
