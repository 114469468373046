import { useContext } from "react";
import { FooterProvider } from "../../../contexts/FooterContext";
import { LangIdContext } from "../../../contexts/LangIdContext";

import Footer from "../../footer/Footer";
import OfferEn from "./OfferEn";
import OfferUk from "./OfferUk";

import "./Offer.css";

const componentClass = "offer";

export default function Offer() {
  const { langId } = useContext(LangIdContext);

  return (
    <>
      <main className={componentClass} id={componentClass}>
        {langId === "en" && <OfferEn />}
        {langId === "uk" && <OfferUk />}
      </main>
      <FooterProvider>
        <Footer bgc={true} />
      </FooterProvider>
    </>
  );
}
