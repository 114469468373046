import AccordItem from "./accord-item/AccordItem";

import "./Accordion.css";

export default function Accordion({ parentClass }) {
  const itemIds = ["courses", "startup"];

  return (
    <div className={`${parentClass}__accordion accordion`} id="accordion">
      {itemIds.map((itemId) => (
        <AccordItem key={itemId} itemId={itemId} />
      ))}
    </div>
  );
}
