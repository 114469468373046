import { useState, useEffect } from "react";
import VideoPlaceholder from "./video-placeholder/VideoPlaceholder";
import { motion, AnimatePresence } from "framer-motion";

import "./Video.css";

export default function Video({
  parentClass,
  videoSrc,
  placeholderHref,
  targetLazyImgId,
  currentId,
}) {
  const [isPlay, setIsPlay] = useState(false);
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);

  const componentClass = "video";
  const containerClass = `${parentClass}__${componentClass}-container`;
  const iframeClass = `${parentClass}__${componentClass}-iframe`;

  function playVideo() {
    if (isPlay === false) {
      setIsPlay(true);
      setTimeout(() => setIsPlaceholderVisible(false), 300);
    }
  }

  const variantsIframe = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
  };

  const durationIframe = 0.25;

  useEffect(() => {
    const element = document.documentElement;

    element.addEventListener("fullscreenchange", () => {
      if (!document.fullscreenElement && isPlay) {
        setTimeout(() => {
          const domElem = document.getElementById(currentId);
          domElem.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }, 600);
      }
    });
  }, [isPlay, currentId]);

  return (
    <div className={containerClass} id="video-container">
      {isPlaceholderVisible && (
        <VideoPlaceholder
          parentClass={`${parentClass}__${componentClass}`}
          handleClick={playVideo}
          isPlaceholderVisible={isPlaceholderVisible}
          placeholderHref={placeholderHref}
          targetLazyImgId={targetLazyImgId}
        />
      )}
      {isPlay && (
        <AnimatePresence mode="popLayout">
          <motion.div
            key="iframe"
            variants={variantsIframe}
            initial="enter"
            animate="center"
            transition={{ duration: durationIframe }}>
            <iframe
              className={iframeClass}
              src={videoSrc + "&controls=1&autoplay=1&mute=0"}
              title="Maksoft"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              frameBorder="0"></iframe>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}
