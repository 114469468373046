import "./PersonBtns.css";

export default function PersonBtns({ parentClass, nextPerson, prevPerson }) {
  const btnsClass = `${parentClass}__btns`;
  const btnClass = `${parentClass}__btn`;

  return (
    <div className={btnsClass}>
      <button
        className={`${btnClass} prevs`}
        type="button"
        onClick={prevPerson}>
        <span className={`btn-arrow__top-line`}></span>
        <span className={`btn-arrow__bot-line`}></span>
      </button>
      <button className={`${btnClass} next`} type="button" onClick={nextPerson}>
        <span className={`btn-arrow__top-line`}></span>
        <span className={`btn-arrow__bot-line`}></span>
      </button>
    </div>
  );
}
