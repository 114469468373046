import "./Btn.css";

export default function Btn({ parentClass, children, onClick }) {
  const componentClass = "btn";

  return (
    <button
      className={`${parentClass}__${componentClass}`}
      type="button"
      onClick={onClick}>
      {children}
    </button>
  );
}
