import { useContext, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { ProjectsContext } from "../../../../../contexts/ProjectsContext";
import { IsMobileContext } from "../../../../../contexts/IsMobileContext";
import { motion, AnimatePresence } from "framer-motion";

import Title from "../../../../topography/title/Title";
import LazyImg from "../../../../lazy-img/LazyImg";

import "./Items.css";

export default function Items({
  parentClass,
  itemCurrentId,
  swipedNext,
  swipedPrev,
}) {
  const { langData, projectsData } = useContext(ProjectsContext);
  const { isMobile } = useContext(IsMobileContext);

  const myRef = useRef();

  const refPassthrough = (el) => {
    handlers.ref(el);
    myRef.current = el;
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      isMobile && swipedNext();
    },
    onSwipedRight: () => {
      isMobile && swipedPrev();
    },
  });

  const itemsClass = "items";
  const itemClass = "item";

  const variants = {
    enter: { opacity: 0, x: 35 },
    center: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -35 },
  };

  const variantsMob = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  if (Boolean(langData) && Boolean(projectsData)) {
    const showItems = isMobile
      ? [langData.itemTitles[itemCurrentId]]
      : langData.itemTitles.slice(itemCurrentId, itemCurrentId + 2);

    return (
      <AnimatePresence mode="wait" key={`anim-box`}>
        <motion.div
          ref={refPassthrough}
          className="anim-box"
          key={`item-anim-${itemCurrentId}`}
          variants={isMobile ? variantsMob : variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: 0.35 }}>
          <ul className={`${parentClass}__${itemsClass}`}>
            {showItems.map((itemTitle, itemId) => (
              <li
                key={`item-${itemId}`}
                className={`${parentClass}__${itemClass} ${itemClass}`}>
                <LazyImg
                  parentClass={itemClass}
                  imgSrc={projectsData.itemImgs[itemCurrentId + itemId]}
                  altImgText="project"
                  targetId={`project-${itemCurrentId + itemId}`}
                />
                {!isMobile && (
                  <Title parentClass={itemClass} index={3}>
                    {itemTitle}
                  </Title>
                )}
              </li>
            ))}
          </ul>
        </motion.div>
      </AnimatePresence>
    );
  }
}
