import BtnLink from "../../../../ui/btns/btn-link/BtnLink";
import timeIcon from "./timeIcon.png";
import NavLink from "../../../../ui/links/nav-link/NavLink";

import "./Card.css";

function setTime(cardId) {
  switch (cardId) {
    case 0:
      return "19:00-19:45";

    case 1:
      return "20:00-20:45";

    case 2:
      return "21:00-21:45";

    default:
      return "";
  }
}

function setPriceNumber(cardId) {
  switch (cardId) {
    case 0:
      return "77";

    case 1:
      return "88";

    case 2:
      return "99";

    default:
      return "";
  }
}

function setPath(cardId) {
  switch (cardId) {
    case 0:
      return "/level1";

    case 1:
      return "/level2";

    case 2:
      return "/level3";

    default:
      return "";
  }
}

const currentDayId = 0;

export default function Card({ cardId, langData }) {
  return (
    <NavLink parentClass={"find__card"} path={setPath(cardId)}>
      <div className="find__card-top">
        <h3 className="card__level-title">{`${langData?.levelText.toUpperCase()} ${
          cardId + 1
        }`}</h3>
        <p className="card__level-name">{langData?.levelName[cardId]}</p>
        <div className="card__level-time">
          <img
            src={timeIcon}
            alt="time-icon"
            className="card__level-time-icon"
          />
          <span className="card__level-time-text">
            {langData?.timeText.toUpperCase()}
          </span>
          <span className="card__level-time-text bold">{setTime(cardId)}</span>
        </div>
        <div className="card__level-line"></div>
      </div>
      <div className="find__card-mid">
        {/* <h4 className="card__level-tags-title">{langData?.tagsTitle}</h4> */}
        {/* <ul className="card__level-tags">
          {langData?.levelTags[cardId].map((tagText, i) => (
            <li className="card__level-tag" key={`card-tag-${i}`}>
              {tagText.toUpperCase()}
            </li>
          ))}
        </ul> */}
        <ul className="card__level-days">
          {langData?.days[cardId].map((dayData, i) => (
            <li
              className={
                i === currentDayId
                  ? "card__level-day current"
                  : "card__level-day"
              }
              key={`card-tag-${i}`}>
              <h4 className="card__level-day-title">
                {dayData?.title.toUpperCase()}
              </h4>
              <p className="card__level-day-text">{dayData?.text}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="find__card-bot">
        <div className="card__level-line"></div>
        <p className="card__level-price-title">
          {langData?.priceTitle[cardId]}
        </p>
        <p className="card__level-price-number">
          {setPriceNumber(cardId) + " " + langData?.priceCur.toUpperCase()}
        </p>
        <BtnLink parentClass={"find__card"} path={"/contact"}>
          {langData?.btnText.toUpperCase()}
        </BtnLink>
      </div>
    </NavLink>
  );
}
