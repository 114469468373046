import { useContext } from "react";
import { WhyMdsContext } from "../../../../contexts/WhyMdsContext";
import { ContactCardProvider } from "../../../../contexts/ContactCardContext";

import CreepingLine from "../../../topography/creeping-line/CreepingLine";
import Content from "./content/Content";
import ContactCard from "../../../ui/contact-card/ContactCard";

import "./WhyMds.css";

export default function WhyMds({ parentClass }) {
  const { langData } = useContext(WhyMdsContext);

  const componentClass = "why-mds";
  const containerClass = `${componentClass}__container`;
  const formId = "homeWhyMdsContactForm";

  if (Boolean(langData)) {
    return (
      <section
        className={`${parentClass}__${componentClass} ${componentClass}`}
        id="whyMds">
        <CreepingLine parentClass={componentClass}>
          {langData.lineText}
        </CreepingLine>
        <div className={containerClass}>
          <Content parentClass={componentClass} />
          <ContactCardProvider>
            <ContactCard parentClass={componentClass} formId={formId} />
          </ContactCardProvider>
        </div>
      </section>
    );
  }

  return null;
}
