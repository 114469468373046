import "./CountBar.css";

export default function CountBar({ parentClass, itemsCount, currentNumber }) {
  const itemClass = `${parentClass}__count-item`;
  const itemCurrentClass = `${parentClass}__count-item current`;

  const countItems = [];

  for (let id = 0; id < itemsCount; id++) {
    countItems.push(
      <li
        key={`count-item-${id}`}
        className={currentNumber === id ? itemCurrentClass : itemClass}></li>
    );
  }

  return (
    <ul
      className={`${parentClass}__count-bar`}
      style={{ gridTemplate: `1fr / repeat(${itemsCount}, auto)` }}>
      {countItems.map((item) => item)}
    </ul>
  );
}
