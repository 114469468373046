import { useContext } from "react";
import { Link } from "react-router-dom";
import { MenuVisibleContext } from "../../../../contexts/MenuVisibleContext";
import { HeaderContext } from "../../../../contexts/HeaderContext";

import "./MenuBtn.css";

export default function MenuBtn({ parentClass }) {
  const { isMenuVisible, setIsMenuVisible, history } =
    useContext(MenuVisibleContext);
  const { langData } = useContext(HeaderContext);

  const componentClass = "menu-btn";
  const textClass = "text";
  const lineIds = ["top-line", "mid-line", "bot-line"];

  const lineBoxClass = isMenuVisible
    ? `${componentClass}__line-box menu-visible`
    : `${componentClass}__line-box`;

  if (Boolean(langData)) {
    const btnText = isMenuVisible
      ? langData.menuBtn.closeText.toUpperCase()
      : langData.menuBtn.openText.toUpperCase();

    const menuBtnPath = isMenuVisible ? history[0] || "/" : "/menu";

    return (
      <Link
        to={menuBtnPath}
        className={`${parentClass}__${componentClass} ${componentClass}`}
        onClick={() => {
          setIsMenuVisible(!isMenuVisible);
        }}>
        <div className={lineBoxClass}>
          {lineIds.map((lineId) => (
            <div key={lineId} className={`${componentClass}__${lineId}`}></div>
          ))}
        </div>
        <div className={`${componentClass}__${textClass}`}>{btnText}</div>
      </Link>
    );
  }
}
