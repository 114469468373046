import { FooterProvider } from "../../../contexts/FooterContext";
import { CompanyHeroProvider } from "../../../contexts/CompanyHeroContext";
import { PossibilitiesProvider } from "../../../contexts/PossibilitiesContext";
import { CompanyWhyMdsProvider } from "../../../contexts/CompanyWhyMdsContext";
import { TeamProvider } from "../../../contexts/TeamContext";
import { CompanyStartProvider } from "../../../contexts/CompanyStartContext";
import { ProjectsProvider } from "../../../contexts/ProjectsContext";

import Hero from "./hero/Hero";
import Possibilities from "./possibilities/Possibilities";
import Footer from "../../footer/Footer";
import WhyMds from "./why-mds/WhyMds";
import Start from "./start/Start";
import Team from "./team/Team";
import Projects from "./projects/Projects";

import "./Company.css";

export default function Company() {
  const componentClass = "company";

  return (
    <>
      <main className={componentClass} id={componentClass}>
        <CompanyHeroProvider>
          <Hero parentClass={componentClass} />
        </CompanyHeroProvider>
        <PossibilitiesProvider>
          <Possibilities parentClass={componentClass} />
        </PossibilitiesProvider>
        <CompanyWhyMdsProvider>
          <WhyMds parentClass={componentClass} />
        </CompanyWhyMdsProvider>
        <TeamProvider>
          <Team parentClass={componentClass} />
        </TeamProvider>
        <ProjectsProvider>
          <Projects parentClass={componentClass} />
        </ProjectsProvider>
        <CompanyStartProvider>
          <Start parentClass={componentClass} />
        </CompanyStartProvider>
      </main>
      <FooterProvider>
        <Footer />
      </FooterProvider>
    </>
  );
}
