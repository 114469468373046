import "./Offer.css";

const langData = {
  title: "Public Offer for the Provision of Online Course Services",
  subtitle:
    "This Public Offer is an official proposal from MAKSOFT TECHNOLOGY, hereinafter referred to as the 'Company', and contains all the essential terms for providing programming online course services.",
  sections: [
    {
      title: "General Provisions",
      items: [
        "This Offer is a public proposal in accordance with Article 641 of the Civil Code of Ukraine and defines the terms of the Agreement between the Company and individuals wishing to receive online education services (hereinafter referred to as the 'Client').",
        "The acceptance of the offer (agreement to the terms) is made by registering for the course through the company's website or another specified communication channel and by making a payment for a lesson or the entire course.",
      ],
    },
    {
      title: "Subject of the Agreement",
      items: [
        "The Company agrees to provide the Client with access to online programming lessons, and the Client agrees to pay for these services in accordance with the terms of this Offer.",
        "Services include providing access to one online lesson after the corresponding payment is made or access to the entire course after full payment.",
      ],
    },
    {
      title: "Terms of Service",
      items: [
        "Training is conducted online according to a pre-agreed schedule.",
        "The Client has the option to pay for each lesson separately or pay for the entire course at once.",
        "**In the case of paying for each lesson separately**:",
        [
          "The Client receives access to the educational materials and the lesson only after confirming payment for that specific lesson.",
          "After paying for each lesson, the Client gains the opportunity to watch the recorded lesson or participate in the live session, if provided by the course.",
        ],
        "**In the case of paying for the entire course at once**:",
        [
          "The Client receives access to all course materials and lessons immediately after confirming the payment.",
        ],
      ],
    },
    {
      title: "Rights and Obligations of the Parties",
      items: [
        "The Company is obliged to:",
        [
          "Provide access to quality educational materials and consultations.",
          "Ensure the technical feasibility of participation in the course through available platforms.",
        ],
        "The Client is obliged to:",
        [
          "Timely pay for services.",
          "Complete all tasks within the course and comply with the course's internal rules.",
          "Not transfer access to the materials to third parties.",
        ],
      ],
    },
    {
      title: "Payment for Services",
      items: [
        "Payment for the course or individual lessons is made in the national currency of Ukraine — hryvnias — according to the tariffs listed on the Company's website.",
        "Payment must be made before the start of each lesson or before the start of the entire course in case of full payment. Failure to fulfill this obligation allows the Company to deny access to the course.",
      ],
    },
    {
      title: "Liability of the Parties",
      items: [
        "The Company is not responsible for technical malfunctions of the Client's equipment or internet connection that may impede access to the course.",
        "If services are not provided due to the fault of the Company, the Client is entitled to a refund of the paid fees or to reschedule the course to another period.",
      ],
    },
    {
      title: "Term and Termination of the Agreement",
      items: [
        "The Agreement comes into effect from the moment the Client accepts the offer and remains valid until the course is completed.",
        "The Client has the right to terminate the Agreement unilaterally by submitting a written request to the Company no later than 3 working days before the start of the course or lesson.",
      ],
    },
    {
      title: "Other Conditions",
      items: [
        "The Company reserves the right to make changes to the terms of this Offer, and new terms come into effect from the moment they are published on the Company's official website.",
        "The Client agrees to the processing of their personal data in accordance with the Company's privacy policy.",
      ],
    },
  ],
  subtitle2:
    "This Offer constitutes the full and unconditional agreement between the Company and the Client.",
};

export default function OfferEn() {
  return (
    <>
      <h2 className="offer-section-title">{langData?.title}</h2>
      <p className="offer-section-subtitle">{langData?.subtitle}</p>
      <ul className="offer-section-items">
        {langData?.sections.map((sectionData, i) => (
          <li key={`offer-item-${i}`} className="offer-section-item">
            <h2 className="offer-section-item-title">
              {i + 1 + ". " + sectionData?.title}
            </h2>
            {sectionData?.subtitle && (
              <p className="offer-section-item-subtitle">
                {sectionData?.subtitle}
              </p>
            )}
            {sectionData?.items && (
              <ul className="offer-section-item-subitems">
                {sectionData?.items.map((itemText, j) => (
                  <li
                    key={`offer-subitem-${j}`}
                    className="offer-section-item-subitem"
                    style={
                      typeof itemText === "string" ? {} : { listStyle: "none" }
                    }>
                    {typeof itemText === "string" ? (
                      itemText
                    ) : (
                      <ul className="offer-section-item-subitems">
                        {itemText.map((itemText, j) => (
                          <li
                            style={{ listStyle: "square" }}
                            key={`offer-subitem-${j}`}
                            className="offer-section-item-subitem">
                            {itemText}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
            {sectionData?.p && (
              <p className="offer-section-item-p">{sectionData?.p}</p>
            )}
          </li>
        ))}
      </ul>
      <p
        className="offer-section-subtitle"
        style={{ marginBottom: 0, width: "100%" }}>
        {langData?.subtitle2}
      </p>
    </>
  );
}
