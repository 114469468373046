import { useContext } from "react";
import { FindContext } from "../../../contexts/FindContext";
import { IsMobileContext } from "../../../contexts/IsMobileContext";

import BtnLink from "../../ui/btns/btn-link/BtnLink";
import timeIcon from "./timeIcon.png";
import timeIconBlack from "./timeIconBlack.png";

import "./Level.css";

function setTime(cardId) {
  switch (cardId) {
    case 0:
      return "19:00-19:45";

    case 1:
      return "20:00-20:45";

    case 2:
      return "21:00-21:45";

    default:
      return "";
  }
}

function setPriceNumber(cardId) {
  switch (cardId) {
    case 0:
      return "77";

    case 1:
      return "88";

    case 2:
      return "99";

    default:
      return "";
  }
}

export default function Level({ levelId }) {
  const { langData } = useContext(FindContext);
  const { isMobile } = useContext(IsMobileContext);

  const componentClass = "level-body";

  if (Boolean(langData)) {
    return (
      <section className="level-body" id={componentClass}>
        <div className={`${componentClass}__header-bg`}></div>
        {isMobile ? (
          <div className={`${componentClass}__mobile mobile`}>
            <h3 className="mobile__title">{`${
              levelId + 1
            } ${langData?.levelText.toUpperCase()} `}</h3>
            <p className="cmobile__level-name">
              {langData?.levelName[levelId]}
            </p>
            <div className="mobile__price">
              <p className="mobile__price-title">{langData?.priceTitleDef}</p>
              <p className="mobile__price-number">
                {setPriceNumber(levelId) +
                  " " +
                  langData?.priceCur.toUpperCase()}
              </p>
            </div>
            <div className="mobile__card">
              <h3 className="card__level-title">{`${langData?.levelText.toUpperCase()} ${
                levelId + 1
              }`}</h3>
              <p className="card__level-name">{langData?.levelName[levelId]}</p>
              <div className="card__level-time">
                <img
                  src={timeIcon}
                  alt="time-icon"
                  className="card__level-time-icon"
                />
                <span className="card__level-time-text">
                  {langData?.timeText.toUpperCase()}
                </span>
                <span className="card__level-time-text bold">
                  {setTime(levelId)}
                </span>
              </div>
              <div className="card__level-line"></div>
              <h4 className="card__level-tags-title">{langData?.tagsTitle}</h4>
              <ul className="card__level-tags">
                {langData?.levelTags[levelId].map((tagText, i) => (
                  <li className="card__level-tag" key={`card-tag-${i}`}>
                    {tagText.toUpperCase()}
                  </li>
                ))}
              </ul>
            </div>
            <p className="cmobile__level-description">
              {langData?.levelDescription[levelId]}
            </p>
            <div className="mobile-card__level-time">
              <img
                src={timeIconBlack}
                alt="time-icon"
                className="card__level-time-icon"
              />
              <span className="card__level-time-text">
                {langData?.timeText.toUpperCase()}
              </span>
              <span className="card__level-time-text bold">
                {setTime(levelId)}
              </span>
            </div>
            <p className="mobile__level-date">
              {langData?.nextText.toUpperCase()}{" "}
              {langData?.nextTextDate[levelId].toUpperCase()}
            </p>
            <BtnLink parentClass={"mobile"} path="/contact">
              {langData.btnText.toUpperCase()}
            </BtnLink>
          </div>
        ) : (
          <div className={`${componentClass}__desktop desktop`}>
            <div className="desktop__card">
              <h3 className="card__level-title">{`${langData?.levelText.toUpperCase()} ${
                levelId + 1
              }`}</h3>
              <p className="card__level-name">{langData?.levelName[levelId]}</p>
              <div className="card__level-time">
                <img
                  src={timeIcon}
                  alt="time-icon"
                  className="card__level-time-icon"
                />
                <span className="card__level-time-text">
                  {langData?.timeText.toUpperCase()}
                </span>
                <span className="card__level-time-text bold">
                  {setTime(levelId)}
                </span>
              </div>
              <div className="card__level-line"></div>
              <h4 className="card__level-tags-title">{langData?.tagsTitle}</h4>
              <ul className="card__level-tags">
                {langData?.levelTags[levelId].map((tagText, i) => (
                  <li className="card__level-tag" key={`card-tag-${i}`}>
                    {tagText.toUpperCase()}
                  </li>
                ))}
              </ul>
            </div>
            <div className="desktop__topography">
              <h3 className="desktop__title">{`${
                levelId + 1
              } ${langData?.levelText.toUpperCase()} `}</h3>
              <p className="desktop__level-name">
                {langData?.levelName[levelId]}
              </p>
            </div>
            <div className="desktop__price-wrap">
              <div className="desktop__price">
                <p className="desktop__price-title">
                  {langData?.priceTitleDef}
                </p>
                <p className="desktop__price-number">
                  {setPriceNumber(levelId) +
                    " " +
                    langData?.priceCur.toUpperCase()}
                </p>
              </div>
            </div>

            <p className="desktop__level-description">
              {langData?.levelDescription[levelId]}
            </p>
            <div className="desktop-card__level-date-wrap">
              <div className="desktop-card__level-time">
                <img
                  src={timeIconBlack}
                  alt="time-icon"
                  className="card__level-time-icon"
                />
                <span className="card__level-time-text">
                  {langData?.timeText.toUpperCase()}
                </span>
                <span className="card__level-time-text bold">
                  {setTime(levelId)}
                </span>
              </div>
              <p className="desktop__level-date">
                {langData?.nextText.toUpperCase()}{" "}
                {langData?.nextTextDate[levelId].toUpperCase()}
              </p>
            </div>
            <BtnLink parentClass={"desktop"} path="/contact">
              {langData.btnText.toUpperCase()}
            </BtnLink>
          </div>
        )}
      </section>
    );
  }

  return null;
}
