import { useContext } from "react";
import { FindContext } from "../../../../contexts/FindContext";

import Title from "../../../topography/title/Title";
import BtnLink from "../../../ui/btns/btn-link/BtnLink";
import Card from "./card/Card";

import "./Find.css";

export default function Find({ parentClass }) {
  const { langData } = useContext(FindContext);

  const componentClass = "find";

  if (Boolean(langData)) {
    return (
      <section
        className={`${parentClass}__${componentClass} ${componentClass}`}
        id="find">
        <Title parentClass={componentClass} index={2}>
          {langData.title.toUpperCase()}
        </Title>
        <div className={`${componentClass}__cards`}>
          {langData?.levelName.map((a, index) => (
            <Card key={`card-${index}`} cardId={index} langData={langData} />
          ))}
        </div>
        <div className={`${componentClass}__botbar`}>
          <p className={`${componentClass}__botbar__divider`}>
            {langData.botbar.divider.toUpperCase()}
          </p>
          <p className={`${componentClass}__botbar__text`}>
            {langData.botbar.text}
            <span className={`${componentClass}__botbar__colored-text`}>
              {langData.botbar.coloredText.toUpperCase()}
            </span>
          </p>
        </div>
        <BtnLink parentClass={componentClass} path="/contact">
          {langData.botbar.btnText.toUpperCase()}
        </BtnLink>
      </section>
    );
  }

  return null;
}
