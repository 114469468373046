import { useContext } from "react";
import { HeroContext } from "../../../../contexts/HeroContext";

import ColoredTitle from "../../../topography/colored-title/ColoredTitle";
import CoursesCard from "./courses-card/CoursesCard";
import CompanyCard from "./company-card/CompanyCard";

import "./Hero.css";

export default function Hero({ parentClass }) {
  const { langData } = useContext(HeroContext);

  const componentClass = "hero";
  const cardsClass = `${componentClass}__cards`;

  if (Boolean(langData)) {
    return (
      <div className={`${parentClass}__${componentClass}-bg`}>
        <section
          className={`${parentClass}__${componentClass} ${componentClass}`}
          id="homeHero">
          <ColoredTitle
            parentClass={componentClass}
            textBefore={langData.title.textBefore}
            coloredText={langData.title.coloredText}
            textAfter={langData.title.textAfter}
          />
          <div className={cardsClass}>
            <CoursesCard parentClass={componentClass} />
            <CompanyCard parentClass={componentClass} />
          </div>
        </section>
      </div>
    );
  }
}
