import AccordionHeader from "./accordion-header/AccordionHeader";
import AccordionBody from "./accordion-body/AccordionBody";
import Items from "../../items/Items";

import "./AccordionItem.css";

export default function AccordionItem({ itemId }) {
  const componentClass = "possibilities";
  

  return (
    <div className="accordion-item">
      <AccordionHeader itemId={itemId} />
      <div
        id={`collapse-${itemId}`}
        className="accordion-collapse collapse"
        data-bs-parent="#accordion">
        <AccordionBody>
          <Items parentClass={componentClass} visibleId={itemId} />
        </AccordionBody>
      </div>
    </div>
  );
}
