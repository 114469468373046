import { Link } from "react-router-dom";
import { useContext } from "react";
import { MenuVisibleContext } from "../../contexts/MenuVisibleContext";

import "./Logo.css";

export default function Logo({ children, parentClass }) {
  const { setIsMenuVisible } = useContext(MenuVisibleContext);

  const componentClass = "logo";

  function handleClick() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setIsMenuVisible(false);
  }

  return (
    <Link
      to="/"
      className={`${parentClass}__${componentClass} ${componentClass}`}
      onClick={handleClick}>
      {children}
    </Link>
  );
}
