import { createContext, useState, useContext, useEffect } from "react";
import { getLang } from "../firebase/firestore";
import { LangIdContext } from "./LangIdContext";

export const CuratorContext = createContext(null);

export function CuratorProvider({ children }) {
  const [langData, setLangData] = useState(null);
  const { langId } = useContext(LangIdContext);

  const curatorId = "curator";

  useEffect(() => {
    let done = false;

    if (!done) {
      getLang(setLangData, langId, curatorId);
    }

    return () => {
      done = true;
    };
  }, [langId]);

  return (
    <CuratorContext.Provider value={{ langData, setLangData }}>
      {children}
    </CuratorContext.Provider>
  );
}
