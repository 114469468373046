export default function VisaImg({ parentClass }) {
  const componentClass = "visa-icon";
  return (
    <svg
      className={`${parentClass}__${componentClass}`}
      width="51"
      height="33"
      viewBox="0 0 51 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.2012 22.899L21.3362 10.3704H24.7511L22.6145 22.899H19.2012ZM34.9523 10.6405C34.2759 10.3866 33.2156 10.1143 31.8917 10.1143C28.5173 10.1143 26.1405 11.8135 26.1201 14.2489C26.1011 16.0492 27.8171 17.0535 29.1124 17.6528C30.4417 18.267 30.8885 18.6586 30.8821 19.2069C30.8736 20.0467 29.8206 20.4302 28.839 20.4302C27.4723 20.4302 26.7461 20.2403 25.6246 19.7728L25.1844 19.5736L24.7052 22.3783C25.5029 22.7281 26.9777 23.031 28.5093 23.0467C32.099 23.0467 34.4294 21.3669 34.4559 18.7661C34.4686 17.3408 33.5589 16.2563 31.5886 15.3621C30.395 14.7825 29.664 14.3957 29.6718 13.8088C29.6718 13.2881 30.2905 12.7312 31.6276 12.7312C32.7441 12.7139 33.5532 12.9574 34.1835 13.2112L34.4895 13.3558L34.9523 10.6405ZM43.7401 10.3702H41.1014C40.284 10.3702 39.6721 10.5933 39.3132 11.4092L34.2416 22.8908H37.8275C37.8275 22.8908 38.4137 21.347 38.5463 21.008C38.9382 21.008 42.4218 21.0134 42.9198 21.0134C43.022 21.4521 43.3353 22.8908 43.3353 22.8908H46.5041L43.7401 10.3698V10.3702ZM39.5534 18.4604C39.8359 17.7386 40.9141 14.9581 40.9141 14.9581C40.894 14.9914 41.1944 14.2327 41.3668 13.7623L41.5976 14.8425C41.5976 14.8425 42.2515 17.8332 42.3882 18.4602H39.5534V18.4604ZM16.3022 10.3702L12.9589 18.9142L12.6028 17.1779C11.9803 15.1764 10.0412 13.0079 7.87329 11.9223L10.9304 22.8791L14.5435 22.875L19.9198 10.3701L16.3022 10.37"
        fill="#0E4595"
      />
      <path
        d="M9.83933 10.1143H4.33271L4.28906 10.3749C8.57316 11.412 11.4078 13.9182 12.5847 16.9298L11.3874 11.1724C11.1807 10.379 10.5812 10.1422 9.83946 10.1146"
        fill="#F2AE14"
      />
    </svg>
  );
}
