import "./Star.css";

export default function Star({ parentClass, innerText }) {
  const componentClass = "star";
  const numberClass = `${componentClass}__number`;

  return (
    <div className={`${parentClass}__${componentClass} ${componentClass}`}>
      <div className={numberClass}>{innerText + 1}</div>
    </div>
  );
}
