import "./Offer.css";

const langData = {
  title: "Публічна оферта на надання послуг онлайн-курсів",
  subtitle:
    "Ця Публічна оферта є офіційною пропозицією компанії MAKSOFT TECHNOLOGY, іменованою надалі як 'Компанія', і містить усі істотні умови надання послуг з проведення онлайн-курсів програмування.",
  sections: [
    {
      title: "Загальні положення",
      items: [
        "Ця Оферта є публічною пропозицією відповідно до ст. 641 Цивільного кодексу України та визначає умови укладення Договору між Компанією та фізичними особами, які бажають отримати послуги онлайн-навчання (далі — 'Клієнт').",
        "Акцепт оферти (прийняття умов) здійснюється шляхом реєстрації на курс через сайт компанії або інший визначений канал комунікації та здійснення оплати за урок або весь курс.",
      ],
    },
    {
      title: "Предмет Договору",
      items: [
        "Компанія зобов’язується надати Клієнту доступ до онлайн-уроків з програмування, а Клієнт зобов’язується сплатити за ці послуги відповідно до умов даної Оферти.",
        "Послуги включають надання доступу до одного онлайн-уроку після здійснення відповідної оплати або доступ до всього курсу при повній оплаті.",
      ],
    },
    {
      title: "Порядок надання послуг",
      items: [
        "Навчання проводиться онлайн за попередньо узгодженим розкладом.",
        "Клієнт має можливість вибору між оплатою кожного уроку окремо або оплатою всього курсу за раз.",
        "У разі оплати за кожен урок окремо:",
        [
          "Клієнт отримує доступ до навчальних матеріалів та уроку тільки після підтвердження оплати за цей конкретний урок.",
          "Після оплати кожного уроку Клієнт отримує можливість переглянути відеозапис уроку або взяти участь у прямому ефірі відповідного заняття, якщо це передбачено курсом.",
        ],
        "У разі оплати всього курсу за раз:",
        [
          "Клієнт отримує доступ до всіх навчальних матеріалів і уроків курсу одразу після підтвердження оплати.",
        ],
      ],
    },
    {
      title: "Права та обов’язки сторін",
      items: [
        "Компанія зобов’язана:",
        [
          "Надати доступ до якісних навчальних матеріалів та консультацій.",
          "Підтримувати технічну можливість участі у навчанні через доступні платформи.",
        ],
        "Клієнт зобов’язаний:",
        [
          "Своєчасно оплачувати послуги.",
          "Виконувати всі завдання в рамках курсу та дотримуватися внутрішніх правил курсу.",
          "Не передавати доступ до матеріалів третім особам.",
        ],
      ],
    },
    {
      title: "Оплата послуг",
      items: [
        "Оплата за курс або окремі уроки здійснюється в національній валюті України — гривнях — згідно з тарифами, вказаними на сайті Компанії.",
        "Оплата має бути здійснена до початку кожного уроку або до початку всього курсу у разі повної оплати. У разі невиконання цього зобов'язання Компанія залишає за собою право відмовити в доступі до навчання.",
      ],
    },
    {
      title: "Відповідальність сторін",
      items: [
        "Компанія не несе відповідальності за технічні несправності обладнання чи інтернет-з'єднання Клієнта, що можуть перешкоджати доступу до навчання.",
        "У разі ненадання послуг з вини Компанії, Клієнт має право на повернення оплачених коштів або продовження курсу на інший період.",
      ],
    },
    {
      title: "Термін дії та розірвання договору",
      items: [
        "Договір набирає чинності з моменту акцепту оферти Клієнтом і діє до завершення курсу.",
        "Клієнт має право розірвати договір в односторонньому порядку шляхом подання письмової заяви на адресу Компанії не пізніше ніж за 3 робочі дні до початку курсу або уроку.",
      ],
    },
    {
      title: "Інші умови",
      items: [
        "Компанія залишає за собою право вносити зміни до умов цієї Оферти, при цьому нові умови набирають чинності з моменту їх публікації на офіційному сайті Компанії.",
        "Клієнт погоджується з обробкою своїх персональних даних відповідно до політики конфіденційності Компанії.",
      ],
    },
  ],
  subtitle2:
    "Ця Оферта є повним і безумовним договором між Компанією та Клієнтом.",
};

export default function OfferUk() {
  return (
    <>
      <h2 className="offer-section-title">{langData?.title}</h2>
      <p className="offer-section-subtitle">{langData?.subtitle}</p>
      <ul className="offer-section-items">
        {langData?.sections.map((sectionData, i) => (
          <li key={`offer-item-${i}`} className="offer-section-item">
            <h2 className="offer-section-item-title">
              {i + 1 + ". " + sectionData?.title}
            </h2>
            {sectionData?.subtitle && (
              <p className="offer-section-item-subtitle">
                {sectionData?.subtitle}
              </p>
            )}
            {sectionData?.items && (
              <ul className="offer-section-item-subitems">
                {sectionData?.items.map((itemText, j) => (
                  <li
                    key={`offer-subitem-${j}`}
                    className="offer-section-item-subitem"
                    style={
                      typeof itemText === "string" ? {} : { listStyle: "none" }
                    }>
                    {typeof itemText === "string" ? (
                      itemText
                    ) : (
                      <ul className="offer-section-item-subitems">
                        {itemText.map((itemText, j) => (
                          <li
                            style={{ listStyle: "square" }}
                            key={`offer-subitem-${j}`}
                            className="offer-section-item-subitem">
                            {itemText}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
            {sectionData?.p && (
              <p className="offer-section-item-p">{sectionData?.p}</p>
            )}
          </li>
        ))}
      </ul>
      <p
        className="offer-section-subtitle"
        style={{ marginBottom: 0, width: "100%" }}>
        {langData?.subtitle2}
      </p>
    </>
  );
}
