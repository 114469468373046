import { createContext, useContext, useState, useEffect } from "react";
import { getLang } from "../firebase/firestore";
import { LangIdContext } from "./LangIdContext";

export const HeroContext = createContext(null);

export function HeroProvider({ children }) {
  const { langId } = useContext(LangIdContext);
  const [langData, setLangData] = useState(null);

  const heroId = "hero";

  useEffect(() => {
    let done = false;

    if (!done) {
      getLang(setLangData, langId, heroId);
    }

    return () => {
      done = true;
    };
  }, [langId]);

  return (
    <HeroContext.Provider
      value={{ langData, setLangData}}>
      {children}
    </HeroContext.Provider>
  );
}
