import { useContext } from "react";
import { HeroContext } from "../../../../../contexts/HeroContext";

import Title from "../../../../topography/title/Title";
import BtnLink from "../../../../ui/btns/btn-link/BtnLink";
import Img from "../../../../img/Img";

import "./CompanyCard.css";

export default function CompanyCard({ parentClass }) {
  const { langData } = useContext(HeroContext);

  const cardClass = "card";
  const containerClass = `company-${cardClass}`;
  const componentClass = `${parentClass}__${cardClass} ${containerClass}`;

  if (Boolean(langData)) {
    return (
      <div className={componentClass}>
        <Title parentClass={containerClass} index={3}>
          {langData.company.title}
        </Title>
        <Img parentClass={containerClass} />
        <BtnLink parentClass={containerClass} path="/company">
          {langData.company.btnText.toUpperCase()}
        </BtnLink>
      </div>
    );
  }

  return null;
}
