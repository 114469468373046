import Title from "../../../../topography/title/Title";
import Paragraph from "../../../../topography/paragraph/Paragraph";

import "./SuitableItem.css";

export default function SuitableItem({ parentClass, itemData, itemId }) {
  const componentClass = "item";

  return (
    <li className={`${parentClass}__${componentClass} ${componentClass}`}>
      <span className={`${componentClass}__number`}>{itemId + 1 + "."}</span>
      <Title parentClass={componentClass} index={3}>
        {itemData.title}
      </Title>
      <Paragraph parentClass={componentClass}>{itemData.text}</Paragraph>
    </li>
  );
}
