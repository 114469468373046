import { useContext, useState } from "react";
import { PossibilitiesContext } from "../../../../contexts/PossibilitiesContext";
import { IsMobileContext } from "../../../../contexts/IsMobileContext";

import Title from "../../../topography/title/Title";
import Btns from "./btns/Btns";
import Items from "./items/Items";
import Accordion from "./accordion/Accordion";

import "./Possibilities.css";

export default function Possibilities({ parentClass }) {
  const { langData, possibilitiesData } = useContext(PossibilitiesContext);
  const [sectionId, setSectionId] = useState(0);
  const { isMobile } = useContext(IsMobileContext);

  function toggleSectionId(id) {
    setSectionId(id);
  }

  const componentClass = "possibilities";

  if (Boolean(langData) && Boolean(possibilitiesData)) {
    return (
      <section
        className={`${parentClass}__${componentClass} ${componentClass}`}
        id={componentClass}>
        <Title parentClass={componentClass} index={2}>
          {langData.title.toUpperCase()}
        </Title>
        {isMobile ? (
          <Accordion parentClass={componentClass} />
        ) : (
          <>
            <Btns
              parentClass={componentClass}
              visibleId={sectionId}
              handleClick={toggleSectionId}
            />
            <Items parentClass={componentClass} visibleId={sectionId} />
          </>
        )}
      </section>
    );
  }

  return null;
}
