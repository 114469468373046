import { useState, useContext, useRef } from "react";
import { StartContext } from "../../../../../../contexts/StartContext";
import { motion, AnimatePresence } from "framer-motion";
import { useSwipeable } from "react-swipeable";

import Title from "../../../../../topography/title/Title";
import Paragraph from "../../../../../topography/paragraph/Paragraph";
import arrowBabelIcon from "../../card-imgs/arrow-bagel-icon.webp";
import heartBabelIcon from "../../card-imgs/heart-bagel-icon.webp";
import starBabelIcon from "../../card-imgs/star-bagel-icon.webp";
import bagelIcon from "../../card-imgs/bagel-icon.webp";
import AccordCardArrow from "./accord-card-arrow/AccordCardArrow";

import "./AccordCard.css";

export default function AccordCard({ itemId }) {
  const { langData } = useContext(StartContext);
  const [cardId, setCardId] = useState(0);
  const myRef = useRef();

  const refPassthrough = (el) => {
    handlers.ref(el);
    myRef.current = el;
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setNextCard();
    },
    onSwipedRight: () => {
      setPrevsCard();
    },
  });

  const componentClass = "accordion-card";
  const imgClass = `${componentClass}__img`;

  function setImgSrc(cardId) {
    switch (true) {
      case cardId === 0 || cardId % 3 === 0:
        return arrowBabelIcon;

      case cardId === 1 || cardId % 3 === 1:
        return starBabelIcon;

      case cardId === 2 || cardId % 3 === 2:
        return heartBabelIcon;

      default:
        return bagelIcon;
    }
  }

  function setNextCard() {
    cardId < langData[itemId].cardsData.length - 1 && setCardId(cardId + 1);
  }

  function setPrevsCard() {
    cardId > 0 && setCardId(cardId - 1);
  }

  const variants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  };

  if (Boolean(langData)) {
    const imgSrc = setImgSrc(cardId);
    const cardData = langData[itemId].cardsData[cardId];

    return (
      <AnimatePresence mode="wait">
        <motion.div
          ref={refPassthrough}
          key={`${itemId}-${cardId}`}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: 0.25 }}>
          <div className={componentClass}>
            <img className={imgClass} src={imgSrc} alt="bagel icon" />
            <Title parentClass={componentClass} index={3}>
              {cardData.title.toUpperCase()}
            </Title>
            {typeof cardData.text === "string" ? (
              <Paragraph parentClass={componentClass}>
                {cardData.text}
              </Paragraph>
            ) : (
              <>
                <p className="accordion-card__text spec">{cardData.text.p}</p>
                <p className="accordion-card__text spec">
                  <b>{cardData.text.subtitle}</b>
                </p>
                <ul>
                  {cardData.text.items.map((itemText, i) => (
                    <li
                      className="accordion-card__text li"
                      key={`card-item-${i}`}>
                      {itemText}
                    </li>
                  ))}
                </ul>
              </>
            )}
            <div className={`${componentClass}__arrows`}>
              <AccordCardArrow
                handleClick={setPrevsCard}
                isDisabled={cardId === 0}
                parentClass={componentClass}
                arrowId="prevs"
              />
              <AccordCardArrow
                handleClick={setNextCard}
                isDisabled={langData[itemId].cardsData.length - 1 === cardId}
                parentClass={componentClass}
                arrowId="next"
              />
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    );
  }

  return null;
}
