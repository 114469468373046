import { useContext } from "react";
import { ProjectsContext } from "../../../../../contexts/ProjectsContext";
import { IsMobileContext } from "../../../../../contexts/IsMobileContext";

import BtnLink from "../../../../ui/btns/btn-link/BtnLink";
import ArrowBtn from "../../../../ui/arrow/arrow-btn/ArrowBtn";

import "./Btns.css";

export default function Btns({
  parentClass,
  nextClick,
  prevClick,
  itemCurrentId,
}) {
  const { langData } = useContext(ProjectsContext);
  const { isMobile } = useContext(IsMobileContext);

  const componentClass = "btns";

  if (Boolean(langData)) {
    return (
      <div className={`${parentClass}__${componentClass}`}>
        <BtnLink parentClass={componentClass} path={`/contact`}>
          {langData.btnText.toUpperCase()}
        </BtnLink>
        {!isMobile && (
          <>
            <ArrowBtn
              parentClass={componentClass}
              onClick={prevClick}
              isNext={false}
              isDisable={itemCurrentId === 0}
            />
            <ArrowBtn
              parentClass={componentClass}
              onClick={nextClick}
              isNext={true}
              isDisable={itemCurrentId === langData.itemTitles.length - 2}
            />
          </>
        )}
      </div>
    );
  }
}
