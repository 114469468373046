import { useContext } from "react";
import { WhyMdsContext } from "../../../../../contexts/WhyMdsContext";

import Title from "../../../../topography/title/Title";
import ContentItem from "./content-item/ContentItem";
import Video from "../../../../ui/video/Video";
import placeholderHref from "./why-mds-video-placeholder.webp";

import "./Content.css";

export default function Content({ parentClass }) {
  const { langData } = useContext(WhyMdsContext);

  const componentClass = "content";
  const videoSrc =
    "https://www.youtube.com/embed/XwDRRKhsjXw?si=fRYYsq58ePHG0lO_";

  if (Boolean(langData)) {
    return (
      <div className={`${parentClass}__${componentClass} ${componentClass}`}>
        <Title parentClass={componentClass} index={3}>
          {langData.title}
        </Title>
        <ul className="content__items">
          {langData.infoCards.map((cardData, cardId) => (
            <ContentItem
              key={cardId}
              parentClass={componentClass}
              cardId={cardId}
              cardData={cardData}
            />
          ))}
        </ul>
        <Video
          parentClass={componentClass}
          videoSrc={videoSrc}
          placeholderHref={placeholderHref}
          targetLazyImgId="home-lazy-img"
          currentId="video-container"
        />
      </div>
    );
  }

  return null;
}
