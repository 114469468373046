import "./ColoredParagraph.css";

export default function ColoredParagraph({ parentClass, textBefore, coloredText, textAfter }) {
  const componentClass = `${parentClass}__colored-text-box`;
  const innerTextClass = `${parentClass}__colored-innertext`;

  return (
    <p className={componentClass}>
      {`${textBefore} `}
      <span className={innerTextClass}>{coloredText}</span>
      {`${textAfter}`}
    </p>
  );
}
