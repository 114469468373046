import "./CreepingLine.css";

export default function CreepingLine({ parentClass, children }) {
  const lineClass = "creeping-line";
  const componentClass = `${parentClass}__${lineClass} ${lineClass}`;
  const containerClass = `${lineClass}__box`;
  const textClass = `${lineClass}__text`;
  const textCount = 5;

  function initCreepingLineText(textCount) {
    const textArr = [];

    for (let index = 0; index < textCount; index++) {
      textArr.push(
        <span key={index} className={textClass}>
          {children}
        </span>
      );
    }

    return textArr;
  }

  return (
    <div className={componentClass}>
      <h2 className={containerClass}>{initCreepingLineText(textCount)}</h2>
    </div>
  );
}
