import { Link } from "react-router-dom";

import "./BtnLink.css";

export default function BtnLink({ parentClass, path, children }) {
  const componentClass = "btn-link";

  return (
    <Link to={path} className={`${parentClass}__${componentClass}`}>
      {children}
    </Link>
  );
}
