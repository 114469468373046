import { createContext, useState, useContext, useEffect } from "react";
import { getLang } from "../firebase/firestore";
import { LangIdContext } from "./LangIdContext";

export const CoursesHeroContext = createContext(null);

export function CoursesHeroProvider({ children }) {
  const [langData, setLangData] = useState(null);
  const { langId } = useContext(LangIdContext);

  const coursesId = "coursesHero";

  useEffect(() => {
    let done = false;

    if (!done) {
      getLang(setLangData, langId, coursesId);
    }

    return () => {
      done = true;
    };
  }, [langId]);

  return (
    <CoursesHeroContext.Provider value={{ langData, setLangData }}>
      {children}
    </CoursesHeroContext.Provider>
  );
}
