import LazyImg from "../../../lazy-img/LazyImg";

import "./VideoPlaceholder.css";

export default function VideoPlaceholder({
  parentClass,
  handleClick,
  isPlaceholderVisible,
  placeholderHref,
  targetLazyImgId,
}) {
  const componentClass = "placeholdeer";
  const containerClass = `${parentClass}-${componentClass}`;
  const btnClass = `${parentClass}-${componentClass}-btn`;

  if (isPlaceholderVisible) {
    return (
      <div className={containerClass}>
        <LazyImg
          parentClass={componentClass}
          imgSrc={placeholderHref}
          altImgText="video placeholder"
          targetId={targetLazyImgId}
        />
        <button
          className={btnClass}
          type="button"
          onClick={handleClick}></button>
      </div>
    );
  }
}
