import { useContext } from "react";
import { TeamContext } from "../../../../contexts/TeamContext";

import ColoredTitle from "../../../topography/colored-title/ColoredTitle";
import TeamBody from "../../home/team/tean-body/TeamBody";

import "./Team.css";

export default function Team({ parentClass }) {
  const { langData } = useContext(TeamContext);

  const componentClass = "team";
  const containerClass = `${parentClass}__${componentClass} ${componentClass}`;

  if (Boolean(langData)) {
    return (
      <section className={containerClass} id={componentClass}>
        <ColoredTitle
          parentClass={componentClass}
          textBefore={langData.coloredTitle.textBefore.toUpperCase()}
          coloredText={langData.coloredTitle.coloredText.toUpperCase()}
          textAfter={langData.coloredTitle.textAfter.toUpperCase()}
        />
        <TeamBody parentClass={componentClass} />
      </section>
    );
  }

  return null;
}
