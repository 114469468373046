import "./Pay.css";

const langData = {
  title:
    "Як оплатити ваш онлайн-курс за допомогою інтернет-еквайрингу від Sens Bank",
  sections: [
    {
      title: "Перехід на сторінку оплати",
      subtitle:
        "Після вибору та реєстрації на ваш онлайн-курс вам буде запропоновано перейти до сторінки оплати. На цьому етапі система перенаправить вас на захищену сторінку для проведення платежу через інтернет-еквайринг від Sens Bank",
    },
    {
      title: "Заповнення платіжної форми",
      subtitle: "На сторінці оплати ви можете вибрати один з двох способів:",
      items: [
        "Оплата карткою: введіть номер картки, термін її дії та CVV-код. Можливо, також буде потрібно вказати ім'я власника картки та адресу для виставлення рахунку.",
        "Оплата через Apple Pay або Google Pay: для зручності ви також можете скористатися Apple Pay або Google Pay. Просто виберіть відповідний спосіб оплати, і система автоматично підхопить ваші платіжні дані з підключеного сервісу.",
      ],
      p: "Увага: При будь-якому обраному варіанті необхідно вказати електронну адресу, на яку ви отримаєте запрошення на урок.",
    },
    {
      title: "Безпека платежу",
      subtitle:
        "Для забезпечення безпеки вашої транзакції, Sens Bank використовує сучасні технології шифрування. Якщо ви здійснюєте оплату карткою, підтримка 3D Secure (Verified by Visa або MasterCard SecureCode) гарантує додатковий захист: вам буде надіслано одноразовий код для підтвердження платежу.",
    },
    {
      title: "Підтвердження та обробка платежу",
      subtitle:
        "Після введення та підтвердження даних ваш платіж буде оброблено через інтернет-еквайринг від Sens Bank. Якщо все пройшло успішно, ви отримаєте підтвердження про успішну оплату.",
    },
    {
      title: "Отримання доступу до курсу",
      subtitle:
        "Після успішного завершення оплати ви отримаєте підтвердження на вашу електронну адресу. Доступ до онлайн-курсу буде надано миттєво або за умовами курсу.",
    },
  ],
};

export default function PayUk() {
  return (
    <>
      <h2 className="offer-section-title">{langData?.title}</h2>
      <ul className="offer-section-items">
        {langData?.sections.map((sectionData, i) => (
          <li key={`offer-item-${i}`} className="offer-section-item">
            <h2 className="offer-section-item-title">
              {i + 1 + ". " + sectionData?.title}
            </h2>
            {sectionData?.subtitle && (
              <p className="offer-section-item-subtitle">
                {sectionData?.subtitle}
              </p>
            )}
            {sectionData?.items && (
              <ul className="offer-section-item-subitems">
                {sectionData?.items.map((itemText, j) => (
                  <li
                    key={`offer-subitem-${j}`}
                    className="offer-section-item-subitem">
                    {itemText}
                  </li>
                ))}
              </ul>
            )}
            {sectionData?.p && (
              <p className="offer-section-item-p">{sectionData?.p}</p>
            )}
          </li>
        ))}
      </ul>
      <p
        className="offer-section-subtitle"
        style={{ marginBottom: 0, width: "100%" }}>
        {langData?.subtitle}
      </p>
    </>
  );
}
