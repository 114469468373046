import "./Privacy.css";

const langData = {
  title: "Політика конфіденційності",
  subtitle:
    "Ця Політика конфіденційності визначає, як наш сайт збирає, використовує, зберігає та захищає персональні дані користувачів, які реєструються на курси з програмування або здійснюють інші взаємодії на нашому вебсайті.",
  sections: [
    {
      title: "Збір персональних даних",
      subtitle: "Ми збираємо та обробляємо такі персональні дані користувачів:",
      items: [
        "Ім'я та прізвище.",
        "Електронна адреса.",
        "Номер телефону.",
        "Дані, необхідні для реєстрації на курсах.",
        "Інші дані, які можуть бути необхідні для надання послуг або взаємодії з сайтом.",
      ],
      p: "Персональні дані збираються лише за умови добровільної згоди користувача. Ви маєте право відмовитися від надання даних, проте це може обмежити можливість користування деякими функціями сайту.",
    },
    {
      title: "Використання персональних даних",
      subtitle: "Зібрані персональні дані використовуються для таких цілей:",
      items: [
        "Реєстрація на курси з програмування.",
        "Надання інформації про наші послуги.",
        "Обробка заявок та спілкування з користувачами.",
        "Надсилання оновлень, промоакцій або інших маркетингових матеріалів (за згодою користувача).",
      ],
      p: "Ми не передаємо персональні дані третім сторонам без вашої згоди, за винятком випадків, передбачених законодавством України.",
    },
    {
      title: "Захист персональних даних",
      subtitle:
        "Ми вживаємо всіх необхідних заходів для захисту персональних даних від несанкціонованого доступу, зміни, розголошення або знищення. Це включає використання сучасних методів шифрування та засобів захисту інформації.",
    },
    {
      title: "Захист інформації та обробка платежів",
      subtitle:
        "Вся інформація, необхідна для здійснення оплати, обробляється та збирається на стороні постачальника послуг – Sense Bank. Ми не зберігаємо та не обробляємо платіжні дані на нашому сайті. Sense Bank забезпечує безпеку обробки платіжної інформації відповідно до стандартів безпеки платіжних карток та інших відповідних нормативних вимог.",
    },
    {
      title: "Файли cookies",
      subtitle:
        "Наш сайт використовує файли cookies для поліпшення роботи та надання більш персоналізованих послуг. Cookies дозволяють зберігати ваші налаштування та історію використання сайту. Ви можете відмовитися від використання cookies через налаштування свого браузера, але це може вплинути на функціональність деяких частин сайту.",
    },
    {
      title: "Права користувачів",
      subtitle: "Користувачі мають право:",
      items: [
        "Отримувати доступ до своїх персональних даних.",
        "Виправляти неточності у своїх даних.",
        "Вимагати видалення своїх персональних даних.",
        "Відкликати згоду на обробку персональних даних.",
      ],
      p: "Для реалізації своїх прав ви можете звертатися до нас через контактну інформацію, вказану на сайті.",
    },
    {
      title: "Зміни до Політики конфіденційності",
      subtitle:
        "Ми залишаємо за собою право вносити зміни до цієї Політики конфіденційності у будь-який час. Усі зміни будуть опубліковані на цій сторінці. Рекомендуємо регулярно переглядати цю Політику, щоб бути в курсі оновлень.",
    },
    {
      title: "Контактна інформація",
      subtitle:
        "Якщо у вас виникли питання щодо цієї Політики конфіденційності або обробки ваших персональних даних, звертайтеся до нас за електронною адресою або номером телефону, вказаними на нашому сайті.",
    },
  ],
};

export default function PrivacyUk() {
  return (
    <>
      <h2 className="privacy-section-title">{langData?.title}</h2>
      <p className="privacy-section-subtitle">{langData?.subtitle}</p>
      <ul className="privacy-section-items">
        {langData?.sections.map((sectionData, i) => (
          <li key={`privacy-item-${i}`} className="privacy-section-item">
            <h2 className="privacy-section-item-title">
              {i + 1 + ". " + sectionData?.title}
            </h2>
            <p className="privacy-section-item-subtitle">
              {sectionData?.subtitle}
            </p>
            {sectionData?.items && (
              <ul className="privacy-section-item-subitems">
                {sectionData?.items.map((itemText, j) => (
                  <li
                    key={`privacy-subitem-${j}`}
                    className="privacy-section-item-subitem">
                    {itemText}
                  </li>
                ))}
              </ul>
            )}
            {sectionData?.p && (
              <p className="privacy-section-item-p">{sectionData?.p}</p>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}
