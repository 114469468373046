import { useEffect, useState } from "react";

import * as THREE from "three";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import "./BagelsTree.css";

export default function BagelsTree() {
  const [isCanvasInit, setIsCanvasInit] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let done = false;

    if (!done) {
      setIsCanvasInit(true);
    }

    return () => {
      done = true;
    };
  }, []);

  if (isCanvasInit) {
    // canvas
    const canvas = document.querySelector("#bagels");
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      canvas,
      alpha: true,
      premultipliedAlpha: false,
      logarithmicDepthBuffer: true,
    });
    renderer.shadowMap.enabled = true;

    // scene
    const scene = new THREE.Scene();

    // camera
    const width = 10;
    const height = 10;
    const camera = new THREE.OrthographicCamera(
      width / -2,
      width / 2,
      height / 2,
      height / -2,
      -10,
      20
    );

    // light
    const color = 0xffffff;
    const intensity = 2800;
    const light = new THREE.PointLight(color, intensity);
    light.position.set(0, 5, 30);
    light.castShadow = true;
    light.shadow.mapSize.width = 2048;
    light.shadow.mapSize.height = 2048;
    light.shadow.radius = 2;
    light.shadow.blurSamples = 32;
    scene.add(light);

    // toruses
    const toruses = new useLoader(GLTFLoader, "./models/toruses.glb");
    toruses.scene.children.forEach((mesh) => {
      mesh.castShadow = true;
      mesh.receiveShadow = true;
    });
    scene.add(toruses.scene);

    // flutter
    const topLine = initTopLine(),
      midLine = initMidLine(),
      midBox = initMidBox(),
      verticalLine = initVerticalLine();
    [topLine, midLine, midBox, verticalLine].forEach((line) => {
      line.receiveShadow = true;
      scene.add(line);
    });

    // render
    const canvasSide = checkWidth(window.innerWidth);
    renderer.setSize(canvasSide, canvasSide);
    renderer.render(scene, camera);

    if (!isLoaded) {
      setIsLoaded(true);
    }

    // animation
    function render(time) {
      time *= 0.0008;

      toruses.scene.children[0].rotation.x = time * 0.5;
      toruses.scene.children[0].rotation.z = time;

      toruses.scene.children[1].rotation.x = -time * 0.4;
      toruses.scene.children[1].rotation.z = time * 1.2;

      toruses.scene.children[2].rotation.x = time * 0.1;
      toruses.scene.children[2].rotation.z = time;

      renderer.render(scene, camera);

      requestAnimationFrame(render);
    }
    requestAnimationFrame(render);
  }

  const spinerClass = "spinner-border text-primary canvas-spinner";
  const canvasClass = "canvas__bagels";

  return (
    <div className="canvas">
      <div
        className={isLoaded ? spinerClass : `${spinerClass} show`}
        role="status"></div>
      <canvas
        id="bagels"
        className={isLoaded ? `${canvasClass} show` : canvasClass}></canvas>
    </div>
  );
}

function initMidLine() {
  const vertices = [0, -0.5, 0, -1, -0.5, 0, -1, 0.5, 0, 0, 0.5, 0, 1, -0.5, 0];

  const indices = [0, 2, 1, 1, 3, 2, 1, 4, 3];

  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(vertices, 3)
  );
  geometry.setIndex(indices);
  geometry.computeVertexNormals();

  const material = new THREE.MeshStandardMaterial({
    color: 0x47c4fa,
    flatShading: true,
  });
  const mesh = new THREE.Mesh(geometry, material);

  return mesh;
}

function initTopLine() {
  const vertices = [0, 1, 0, -2, 1, 0, -2, 2, 0, 1.5, 2, 0, 2.5, 1, 0];

  const indices = [0, 2, 1, 1, 3, 2, 1, 4, 3];

  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(vertices, 3)
  );
  geometry.setIndex(indices);
  geometry.computeVertexNormals();

  const material = new THREE.MeshStandardMaterial({
    color: 0x47c4fa,
    flatShading: true,
  });
  const mesh = new THREE.Mesh(geometry, material);

  return mesh;
}

function initVerticalLine() {
  const vertices = [
    -2, -0.5, 0, -2, -1.5, 0, -1, -0.5, 0, -1, -1.5, 0, -1, -2.5, 0,
  ];

  const indices = [0, 1, 2, 1, 3, 2, 1, 4, 3];

  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(vertices, 3)
  );
  geometry.setIndex(indices);
  geometry.computeVertexNormals();

  const material = new THREE.MeshStandardMaterial({
    color: 0x00559e,
    flatShading: true,
  });
  const mesh = new THREE.Mesh(geometry, material);

  return mesh;
}

function initMidBox() {
  const vertices = [-2, 0.5, 0, -2, -1.5, 0, -1, 0.5, 0, -1, -1.5, 0];

  const indices = [0, 1, 2, 1, 3, 2];

  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(vertices, 3)
  );
  geometry.setIndex(indices);
  geometry.computeVertexNormals();

  const material = new THREE.MeshStandardMaterial({
    color: 0x00b6f8,
    flatShading: true,
  });
  const mesh = new THREE.Mesh(geometry, material);

  return mesh;
}

function checkWidth(width) {
  switch (true) {
    case width >= 1100:
      return width * 0.27291;

    case width < 1100 && width >= 900:
      return 300;

    case width < 900 && width >= 769:
      return 260;

    case width <= 768 && width >= 650:
      return width * 0.5;

    case width < 650 && width >= 550:
      return width * 0.58;

    case width < 550 && width >= 480:
      return width * 0.65;

    case width < 480 && width >= 390:
      return width * 0.78;

    case width < 390:
      return width * 0.92;

    default:
      break;
  }
}
