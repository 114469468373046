import "./PersonImg.css";

export default function PersonImg({ parentClass, picUrl }) {
  const imgClass = `${parentClass}__img`;

  return (
    <div
      className={imgClass}
      style={{
        background: `url(${picUrl}) center / cover no-repeat`,
      }}></div>
  );
}
