import { useContext } from "react";
import { StartContext } from "../../../../../contexts/StartContext";
import { StartCardsVisibleContext } from "../../../../../contexts/StartCardsVisibleContext";

import Btn from "../../../../ui/btns/btn/Btn";

import "./StartBtns.css";

export default function StartBtns({ parentClass }) {
  const { langData } = useContext(StartContext);
  const { visibleId, toggleVisibleId } = useContext(StartCardsVisibleContext);

  const btnsClass = `${parentClass}__btns`;
  const btnClass = `${parentClass}__btn-box`;
  const currentBtnClass = `${parentClass}__btn-box current`;
  const btnsId = ["courses", "startup"];

  if (Boolean(langData)) {
    return (
      <ul className={btnsClass}>
        {btnsId.map((btnId) => (
          <li
            className={visibleId === btnId ? currentBtnClass : btnClass}
            key={btnId}>
            <Btn
              parentClass={parentClass}
              onClick={() => toggleVisibleId(btnId)}>
              {langData[btnId].btnText.toUpperCase()}
            </Btn>
          </li>
        ))}
      </ul>
    );
  }

  return null;
}
