import { useState, useEffect } from "react";

import "./LazyImg.css";

export default function LazyImg({ parentClass, imgSrc, altImgText, targetId }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [maxWidth, setMaxWidth] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = imgSrc;

    img.onload = function () {
      setIsLoaded(true);

      const container = document.getElementById(targetId);
      const offsetHeight = container.offsetHeight;
      const offsetWidth = container.offsetWidth;

      switch (true) {
        case offsetWidth / offsetHeight >= img.width / img.height:
          setMaxWidth("100%");
          break;

        case offsetWidth / offsetHeight < img.width / img.height:
          setMaxWidth(null);
          break;

        default:
          break;
      }
    };
  }, [imgSrc, targetId]);

  const imgClass = "lazy-img";
  const loadedImgClass = `${parentClass}__${imgClass} loaded`;
  const containerClass = "lazy-container";
  const placeholderClass = "lazy-placeholder";
  const loadedPlaceholderClass = `${parentClass}__${placeholderClass} loaded`;

  return (
    <div className={`${parentClass}__${containerClass}`} id={targetId}>
      <div
        className={
          isLoaded
            ? loadedPlaceholderClass
            : `${parentClass}__${placeholderClass}`
        }>
        <div className="spinner-border text-primary" role="status"></div>
      </div>
      <img
        style={{
          width: maxWidth || "auto",
          height: maxWidth ? "auto" : "100%",
        }}
        className={isLoaded ? loadedImgClass : `${parentClass}__${imgClass}`}
        src={imgSrc}
        alt={altImgText}
      />
    </div>
  );
}
