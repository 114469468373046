import { useContext } from "react";
import { CompanyStartContext } from "../../../../contexts/CompanyStartContext";

import Title from "../../../topography/title/Title";
import BtnLink from "../../../ui/btns/btn-link/BtnLink";
import Body from "./body/Body";
import Img from "../../../img/Img";

import "./Start.css";

export default function Start({ parentClass }) {
  const { langData } = useContext(CompanyStartContext);

  const componentClass = "start";

  if (Boolean(langData)) {
    return (
      <section
        className={`${parentClass}__${componentClass} ${componentClass}`}
        id="companyStart">
        <Body parentClass={componentClass}>
          <Title parentClass={componentClass} index={2}>
            {langData.titleText.toUpperCase()}
          </Title>
          <BtnLink parentClass={componentClass} path={"/contact"}>
            {langData.btnText.toUpperCase()}
          </BtnLink>
        </Body>
        <Img parentClass={componentClass} />
      </section>
    );
  }

  return null;
}
