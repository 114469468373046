import "./Tag.css";

export default function Tag({ parentClass, children }) {
  const componentClass = "tag";

  return (
    <div className={`${parentClass}__${componentClass}`}>
      {children.toUpperCase()}
    </div>
  );
}
