import { createContext, useState, useContext, useEffect } from "react";
import { getLang } from "../firebase/firestore";
import { LangIdContext } from "./LangIdContext";

export const HeaderContext = createContext(null);

export function HeaderProvider({ children }) {
  const [langData, setLangData] = useState(null);
  const { langId } = useContext(LangIdContext);

  const headerId = "header";

  useEffect(() => {
    let done = false;

    if (!done) {
      getLang(setLangData, langId, headerId);
    }

    return () => {
      done = true;
    };
  }, [langId]);

  return (
    <HeaderContext.Provider
      value={{ langData, setLangData}}>
      {children}
    </HeaderContext.Provider>
  );
}
