import { useContext } from "react";
import { LangIdContext } from "../../../../contexts/LangIdContext";

import "./LangSelect.css";

export default function LangSelect({ parentClass }) {
  const { langId, setLangId } = useContext(LangIdContext);

  const componentClass = "lang-select";
  const btnClass = "btn";
  const currentClass = "current";
  const btnsId = ["uk", "en"];

  function setBtnText(btnId) {
    switch (btnId) {
      case "uk":
        return "ua";

      case "en":
        return "en";

      default:
        return "en";
    }
  }

  function handleClick(btnId) {
    document.documentElement.lang = btnId;
    setLangId(btnId);
  }

  return (
    <div className={`${parentClass}__${componentClass} ${componentClass}`}>
      {btnsId.map((btnId) => (
        <button
          key={btnId}
          className={
            btnId === langId
              ? `${componentClass}__${btnClass} ${currentClass}`
              : `${componentClass}__${btnClass}`
          }
          type="button"
          onClick={() => handleClick(btnId)}>
          {<span lang="en">{setBtnText(btnId).toUpperCase()}</span>}
        </button>
      ))}
    </div>
  );
}
