import { useContext } from "react";
import { CoursesHeroContext } from "../../../../contexts/CoursesHeroContext";
import { IsMobileContext } from "../../../../contexts/IsMobileContext";
import WebGL from "three/addons/capabilities/WebGL.js";

import Content from "./content/Content";
import BagelsTree from "../../../bagels-tree/BagelsTree";
import Bagels from "./bagels/Bagels";

import "./CoursesHero.css";

export default function CoursesHero({ parentClass }) {
  const { langData } = useContext(CoursesHeroContext);
  const { isMobile } = useContext(IsMobileContext);

  const componentClass = "hero";

  const regex = new RegExp(
    "Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini",
    "i"
  );

  if (Boolean(langData)) {
    return (
      <section
        className={`${parentClass}__${componentClass} ${componentClass}`}
        id="coursesHero">
        <Content parentClass={componentClass} />
        {WebGL.isWebGLAvailable() && !regex.test(navigator.userAgent) ? (
          <BagelsTree />
        ) : (
          <Bagels parentClass={componentClass} />
        )}
        {isMobile && <div className={`${componentClass}__bg`}></div>}
      </section>
    );
  }

  return null;
}
