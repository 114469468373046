import { useContext } from "react";
import { MenuContext } from "../../../contexts/MenuContext";

import Telephone from "../../ui/links/tel/Telephone";
import Mail from "../../ui/links/mail/Mail";
import Location from "../../ui/links/location/Location";

import "./MenuContacts.css";

export default function MenuContacts({ parentClass }) {
  const { langData, menuData } = useContext(MenuContext);

  const componentClass = "contacts";
  const itemsClass = `${componentClass}__items`;
  const itemClass = `${componentClass}__item`;
  const titleClass = `${itemClass}-title`;

  if (Boolean(langData) && Boolean(menuData)) {
    return (
      <address
        className={`${parentClass}__${componentClass} ${componentClass}`}>
        <ul className={itemsClass}>
          <li className={itemClass}>
            <h4 className={titleClass}>{langData.contactLinks.telTitle}</h4>
            <Telephone
              parentClass={itemClass}
              fullTel={menuData.contactLinks.tel}>
              {menuData.contactLinks.tel}
            </Telephone>
          </li>
          <li className={itemClass}>
            <h4 className={titleClass}>{langData.contactLinks.mailTitle}</h4>
            <Mail parentClass={itemClass} email={menuData.contactLinks.mail}>
              {menuData.contactLinks?.mail}
            </Mail>
          </li>
          <li className={itemClass}>
            <h4 className={titleClass}>
              {langData.contactLinks.address.title}
            </h4>
            <Location
              parentClass={itemClass}
              locationHref={menuData.contactLinks.addressHref}>
              {langData.contactLinks.address.location}
            </Location>
          </li>
        </ul>
      </address>
    );
  }

  return null;
}
