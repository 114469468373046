import { useState, useContext } from "react";
import { ContactCardContext } from "../../../contexts/ContactCardContext";
import { postForm } from "../../../firebase/firestore";

import Btn from "../btns/btn/Btn";
import PostPopup from "../post-popup/PostPopup";

import "./ContactCard.css";

export default function ContactCard({ parentClass, formId }) {
  const { langData } = useContext(ContactCardContext);
  const [userData, setUserData] = useState({ userName: "", userTel: "" });
  const [isLoading, seIsLoading] = useState(false);
  const [isLoaded, seIsLoaded] = useState(false);
  const [isError, setIsError] = useState({
    telIsError: false,
    nameIsError: false,
  });

  const cardClass = "contact-card";
  const formClass = "form";
  const labelClass = `${formClass}__label`;
  const inputBoxClass = `${formClass}__input-box`;
  const inputClass = `${formClass}__input`;
  const errorInputClass = `${inputClass} error`;

  function postUserData() {
    const nameValid = checkName(userData.userName);
    const telValid = checkTel(userData.userTel);

    if (!nameValid || !telValid) {
      setIsError({ nameIsError: !nameValid, telIsError: !telValid });
    }

    if (nameValid && telValid) {
      seIsLoading(true);
      postForm(userData, formId, seIsLoaded, seIsLoading);
      setUserData({ userName: "", userTel: "" });
    }
  }

  function checkName(name) {
    const namePattern = new RegExp("^[a-zA-Zа-яА-ЯІіЇїҐґЄє ]{3,}$");
    return namePattern.test(name);
  }

  function checkTel(tel) {
    const telPattern = new RegExp("^[0-9+() ]{10,}$");
    return telPattern.test(tel);
  }

  if (Boolean(langData)) {
    const namePlaceholder = langData.userName.placeholder;
    const telPlaceholder = langData.userTel.placeholder;

    return (
      <>
        <div className={`${parentClass}__${cardClass} ${cardClass}`}>
          <form
            name="contact-card"
            action="#"
            className={`${cardClass}__${formClass} ${formClass}`}>
            <div className={inputBoxClass}>
              <label className={labelClass} htmlFor="userName">
                {langData.userName.label}
              </label>
              <input
                value={userData.userName}
                className={isError.nameIsError ? errorInputClass : inputClass}
                type="text"
                name="userName"
                placeholder={namePlaceholder}
                onChange={(e) => {
                  isError.nameIsError &&
                    setIsError({ ...isError, nameIsError: false });
                  setUserData({
                    ...userData,
                    userName: e.currentTarget.value,
                  });
                }}
              />
            </div>
            <div className={inputBoxClass}>
              <label className={labelClass} htmlFor="userTel">
                {langData.userTel.label}
              </label>
              <input
                value={userData.userTel}
                className={isError.telIsError ? errorInputClass : inputClass}
                type="tel"
                name="userTel"
                placeholder={telPlaceholder}
                onChange={(e) => {
                  isError.telIsError &&
                    setIsError({ ...isError, telIsError: false });
                  setUserData({ ...userData, userTel: e.currentTarget.value });
                }}
              />
            </div>
            <Btn parentClass={formClass} onClick={postUserData}>
              {langData.btnText.toUpperCase()}
            </Btn>
          </form>
        </div>
        <PostPopup
          parentClass={formClass}
          isLoaded={isLoaded}
          isLoading={isLoading}
        />
      </>
    );
  }

  return null;
}
