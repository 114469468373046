import { useContext } from "react";
import { CompanyWhyMdsContext } from "../../../../../contexts/CompanyWhyMdsContext";

import Title from "../../../../topography/title/Title";
import Tag from "../../../../ui/tag/Tag";
import BtnLink from "../../../../ui/btns/btn-link/BtnLink";
import ContentBox from "./content-box/ContentBox";
import Img from "../../../../img/Img";

import "./Body.css";

export default function Body({ parentClass }) {
  const { langData } = useContext(CompanyWhyMdsContext);

  const componentClass = "body";

  if (Boolean(langData)) {
    return (
      <div className={`${parentClass}__${componentClass} ${componentClass}`}>
        <ContentBox parentClass={componentClass}>
          <Tag parentClass={componentClass}>
            {langData.tagText.toUpperCase()}
          </Tag>
          <Title parentClass={componentClass} index={2}>
            {langData.titleText.toUpperCase()}
          </Title>
          <ul className={`${componentClass}__items`}>
            {langData.itemsText.map((itemText, itemId) => (
              <li className={`${componentClass}__item`} key={`item-${itemId}`}>
                {itemText}
              </li>
            ))}
          </ul>
          <BtnLink parentClass={componentClass} path={"/contact"}>
            {langData.btnText.toUpperCase()}
          </BtnLink>
        </ContentBox>
        <Img parentClass={componentClass} />
      </div>
    );
  }

  return null;
}
