import { useContext } from "react";
import { PossibilitiesContext } from "../../../../../contexts/PossibilitiesContext";

import AccordItem from "./accordion-item/AccordionItem";

import "./Accordion.css";

export default function Accordion({ parentClass }) {
  const { langData } = useContext(PossibilitiesContext);

  return (
    <div className={`${parentClass}__accordion accordion`} id="accordion">
      {langData.sectionsData.map((itemsData, itemId) => (
        <AccordItem key={`accordion-item-${itemId}`} itemId={itemId} />
      ))}
    </div>
  );
}
