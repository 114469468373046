import {
  doc,
  getDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "./config";

export async function getLang(setData, langId, componentId) {
  const docRef = doc(db, langId, componentId);

  try {
    const doc = await getDoc(docRef);
    setData(doc.data());

    // console.log(`Download ${componentId} component lang data success!`);
  } catch (error) {
    console.log("Error getting cached document:", error);
  }
}

export async function getProps(setData, componentId) {
  const docRef = doc(db, "data", componentId);

  try {
    const doc = await getDoc(docRef);
    setData(doc.data());

    // console.log(`Download ${componentId} component props success!`);
  } catch (error) {
    console.log("Error getting cached document:", error);
  }
}

export async function postForm(userData, formId, seIsLoaded, seIsLoading) {
  try {
    await addDoc(collection(db, formId), {
      ...userData,
      timestamp: serverTimestamp(),
      isRead: false,
    });
    seIsLoaded(true);

    setTimeout(() => {
      seIsLoaded(false);
      seIsLoading(false);
    }, 1500);
  } catch (error) {
    console.error("Error:", error);
  }
}
