import "./Telephone.css";

export default function Telephone({ parentClass, fullTel, children }) {
  const componentClass = "tel";

  function setTelTitle(telNumber) {
    return `${telNumber.slice(0, 4)} (${telNumber.slice(
      4,
      6
    )}) ${telNumber.slice(6, 9)} ${telNumber.slice(9)}`;
  }

  return (
    <a className={`${parentClass}-${componentClass}`} href={`tel:${fullTel}`}>
      {setTelTitle(children)}
    </a>
  );
}
