import { createContext, useContext, useState, useEffect } from "react";
import { getLang } from "../firebase/firestore";
import { LangIdContext } from "./LangIdContext";

export const StartContext = createContext(null);

export function StartProvider({ children }) {
  const { langId } = useContext(LangIdContext);
  const [langData, setLangData] = useState(null);

  const startId = "start";

  useEffect(() => {
    let done = false;

    if (!done) {
      getLang(setLangData, langId, startId);
    }

    return () => {
      done = true;
    };
  }, [langId]);

  return (
    <StartContext.Provider value={{ langData, setLangData }}>
      {children}
    </StartContext.Provider>
  );
}
