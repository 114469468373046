import { useContext } from "react";
import { HeroContext } from "../../../../../contexts/HeroContext";

import ArrowTitle from "../../../../topography/arrow-title/ArrowTitle";
import Paragraph from "../../../../topography/paragraph/Paragraph";
import BtnLink from "../../../../ui/btns/btn-link/BtnLink";
import Like from "../../../../like/Like";

import "./CoursesCard.css";

export default function CoursesCard({ parentClass }) {
  const { langData } = useContext(HeroContext);

  const cardClass = "card";
  const containerClass = `courses-${cardClass}`;
  const componentClass = `${parentClass}__${cardClass} ${containerClass}`;
  const tagsClass = `${containerClass}__tags`;
  const tagClass = `${containerClass}__tag`;

  if (Boolean(langData)) {
    return (
      <div className={componentClass}>
        <ArrowTitle
          parentClass={containerClass}
          textAfter={langData.courses.title.textAfter}
          textBefore={langData.courses.title.textBefore}
        />
        <ul className={tagsClass}>
          {langData.courses.tagsText.map((tagText) => (
            <li key={tagText.toLowerCase()} className={tagClass}>
              {tagText.toUpperCase()}
            </li>
          ))}
        </ul>
        <Paragraph parentClass={containerClass}>
          {langData.courses.text}
        </Paragraph>
        <Like parentClass={containerClass} />
        <BtnLink parentClass={containerClass} path="/courses">
          {langData.courses.btnText.toUpperCase()}
        </BtnLink>
      </div>
    );
  }

  return null;
}
