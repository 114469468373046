import { useContext } from "react";
import { FooterProvider } from "../../../contexts/FooterContext";
import { LangIdContext } from "../../../contexts/LangIdContext";

import Footer from "../../footer/Footer";
import RefundEn from "./RefundEn";
import RefundUk from "./RefundUk";

import "./Refund.css";

const componentClass = "refund";

export default function Refund() {
  const { langId } = useContext(LangIdContext);

  return (
    <>
      <main className={componentClass} id={componentClass}>
        {langId === "en" && <RefundEn />}
        {langId === "uk" && <RefundUk />}
      </main>
      <FooterProvider>
        <Footer bgc={true} />
      </FooterProvider>
    </>
  );
}
