import { useContext } from "react";
import { CompanyHeroContext } from "../../../../../contexts/CompanyHeroContext";

import ColoredTitle from "../../../../topography/colored-title/ColoredTitle";
import Paragraph from "../../../../topography/paragraph/Paragraph";
import BtnLink from "../../../../ui/btns/btn-link/BtnLink";
import Rocket from "./rocket/Rocket";

import "./Body.css";

export default function Body({ parentClass }) {
  const { langData } = useContext(CompanyHeroContext);

  const componentClass = "body";
  const contentClass = "content";

  if (Boolean(langData)) {
    return (
      <div className={`${parentClass}__${componentClass} ${componentClass}`}>
        <div className={`${componentClass}__${contentClass} ${contentClass}`}>
          <div className={`${contentClass}__topography`}>
            <ColoredTitle
              parentClass={contentClass}
              textBefore={langData.coloredTitle.textBefore.toUpperCase()}
              coloredText={langData.coloredTitle.coloredText.toUpperCase()}
              textAfter={langData.coloredTitle.textAfter.toUpperCase()}
            />
            <Paragraph parentClass={contentClass}>
              {langData.paragraphText}
            </Paragraph>
          </div>
          <BtnLink parentClass={contentClass} path="/contact">
            {langData.btnText.toUpperCase()}
          </BtnLink>
        </div>
        <Rocket parentClass={componentClass} />
      </div>
    );
  }
}
