import { useContext } from "react";
import { FooterProvider } from "../../../contexts/FooterContext";
import { LangIdContext } from "../../../contexts/LangIdContext";

import Footer from "../../footer/Footer";
import TermsEn from "./TermsEn";
import TermsUk from "./TermsUk";

import "./Terms.css";

const componentClass = "terms";

export default function Terms() {
  const { langId } = useContext(LangIdContext);

  return (
    <>
      <main className={componentClass} id={componentClass}>
        {langId === "en" && <TermsEn />}
        {langId === "uk" && <TermsUk />}
      </main>
      <FooterProvider>
        <Footer bgc={true} />
      </FooterProvider>
    </>
  );
}
