import "./Rocket.css";

export default function Rocket({ parentClass }) {
  const rocketClass = "rocket";

  return (
    <div className={`${parentClass}__${rocketClass}-box`}>
      <div className={`${parentClass}__${rocketClass}-bg`}></div>
      <div className={`${parentClass}__${rocketClass}`}></div>
    </div>
  );
}
