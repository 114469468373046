import { useContext } from "react";
import { FooterContext } from "../../../contexts/FooterContext";

export default function MsgrLinks({ parentClass }) {
  const { footerData } = useContext(FooterContext);

  const componentClass = "msgr-links";

  if (Boolean(footerData)) {
    return (
      <div className={`${parentClass}__${componentClass} ${componentClass}`}>
        <ul className={`${componentClass}__items`}>
          {footerData.msgrLinks.paths.map(
            (linkPath, id) =>
              id >= 1 && (
                <li key={id} className={`${componentClass}__item`}>
                  <a
                    className={`${parentClass}__link`}
                    href={linkPath}
                    target="_blank"
                    rel="noopener noreferrer nofollow">
                    <img
                      src={footerData.msgrLinks.imgHrefs[id]}
                      alt="social network icon"
                    />
                  </a>
                </li>
              )
          )}
        </ul>
      </div>
    );
  }

  return null;
}
