import { useContext } from "react";
import { FooterContext } from "../../../contexts/FooterContext";

import Title from "../../topography/title/Title";
import Telephone from "../../ui/links/tel/Telephone";
import Mail from "../../ui/links/mail/Mail";
import Location from "../../ui/links/location/Location";

export default function ContactLinks({ parentClass }) {
  const { langData, footerData } = useContext(FooterContext);

  const componentClass = "contact-links";

  if (Boolean(langData) && Boolean(footerData)) {
    return (
      <div className={`${parentClass}__${componentClass} ${componentClass}`}>
        <Title parentClass={componentClass} index={3}>
          {langData.titles.contactTitle}
        </Title>
        <ul className={`${componentClass}__items`}>
          <li className={`${componentClass}__item`}>
            <Telephone
              parentClass={componentClass}
              fullTel={footerData.contactLinkPaths.tel}>
              {footerData.contactLinkPaths.tel}
            </Telephone>
          </li>
          <li className={`${componentClass}__item`}>
            <Mail
              parentClass={componentClass}
              email={footerData.contactLinkPaths.mail}>
              {footerData.contactLinkPaths.mail}
            </Mail>
          </li>
          <li className={`${componentClass}__item`}>
            <Location
              parentClass={componentClass}
              locationHref={footerData.contactLinkPaths.address}>
              {langData.addressText}
            </Location>
          </li>
        </ul>
      </div>
    );
  }

  return null;
}
