import "./FaqAccordion.css";

export default function FaqAccordion({ parentClass, children }) {
  const componentClass = `${parentClass}__accordion accordion accordion-flush`;

  return (
    <ul className={componentClass} id="accordionFlushExample">
      {children}
    </ul>
  );
}
