import "./Refund.css";

const langData = {
  title: "Правила повернення коштів",
  subtitle:
    "Ми прагнемо забезпечити якісне навчання та задоволення наших клієнтів. Однак, якщо з якихось причин ви не задоволені курсами або у вас виникла необхідність скасувати участь, ми пропонуємо прозору політику повернення коштів.",
  sections: [
    {
      title: "Повернення коштів за невикористані курси",
      items: [
        "Повернення коштів можливе лише за ті заняття, які ще не були пройдені користувачем.",
        "Якщо ви скасуєте свою реєстрацію на курс більше ніж за 24 години до початку першого заняття, вам буде повернено 100% сплаченої суми.",
        "Якщо ви скасуєте участь менше ніж за 24 години до початку курсу, повернення коштів не здійснюється.",
      ],
    },
    {
      title: "Часткове повернення коштів",
      items: [
        "Якщо курс складається з кількох занять, і ви вже взяли участь у частині з них, ви маєте право на часткове повернення коштів за непройдені заняття. Сума повернення розраховується пропорційно до кількості непройдених занять.",
        "Повернення коштів за пройдені заняття не здійснюється.",
      ],
    },
    {
      title: "Процедура повернення коштів",
      items: [
        "Для ініціювання повернення коштів необхідно надіслати запит на електронну адресу служби підтримки, вказавши ваше ім'я, курс, на який ви зареєструвалися, та причину скасування.",
        "Ми розглядаємо всі запити протягом 5 робочих днів з моменту їх отримання.",
        "Повернення коштів здійснюється протягом 10 робочих днів після затвердження запиту та проводиться на той самий рахунок, з якого була здійснена оплата.",
      ],
    },
    {
      title: "Відмова у поверненні коштів",
      subtitle:
        "Ми залишаємо за собою право відмовити у поверненні коштів у таких випадках:",
      items: [
        "Якщо заняття було проведено, а клієнт не відвідав його без попередження.",
        "Якщо порушено правила поведінки під час курсу.",
      ],
    },
    {
      title: "Зміни до правил повернення коштів",
      subtitle:
        "Ми маємо право вносити зміни до цих правил у будь-який час. Усі зміни будуть публікуватися на цій сторінці. Ми рекомендуємо вам регулярно переглядати правила для ознайомлення з актуальними умовами.",
    },
  ],
};

export default function RefundUk() {
  return (
    <>
      <h2 className="refund-section-title">{langData?.title}</h2>
      <p className="refund-section-subtitle">{langData?.subtitle}</p>
      <ul className="refund-section-items">
        {langData?.sections.map((sectionData, i) => (
          <li key={`refund-item-${i}`} className="refund-section-item">
            <h2 className="refund-section-item-title">
              {i + 1 + ". " + sectionData?.title}
            </h2>
            {sectionData?.subtitle && (
              <p className="refund-section-item-subtitle">
                {sectionData?.subtitle}
              </p>
            )}
            {sectionData?.items && (
              <ul className="refund-section-item-subitems">
                {sectionData?.items.map((itemText, j) => (
                  <li
                    key={`refund-subitem-${j}`}
                    className="refund-section-item-subitem">
                    {itemText}
                  </li>
                ))}
              </ul>
            )}
            {sectionData?.p && (
              <p className="refund-section-item-p">{sectionData?.p}</p>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}
