import { useContext } from "react";
import { FormatContext } from "../../../../contexts/FormatContext";

import Title from "../../../topography/title/Title";
import Item from "./item/Item";

import "./Format.css";

export default function Format({ parentClass }) {
  const { langData } = useContext(FormatContext);

  const componentClass = "format";

  if (Boolean(langData)) {
    return (
      <section
        className={`${parentClass}__${componentClass} ${componentClass}`}
        id={componentClass}>
        <Title parentClass={componentClass} index={2}>
          {langData.title.toUpperCase()}
        </Title>
        <ul className={`${componentClass}__items`}>
          {langData.items.map((itemData, id) => (
            <Item
              key={`item-${id}`}
              parentClass={componentClass}
              itemId={id}
              itemData={itemData}
            />
          ))}
        </ul>
      </section>
    );
  }

  return null;
}
