import { useState, useContext } from "react";
import { ContactFormContext } from "../../../contexts/ContactFormContext";
import { postForm } from "../../../firebase/firestore";

import Title from "../../topography/title/Title";
import Btn from "../../ui/btns/btn/Btn";
import Paragraph from "../../topography/paragraph/Paragraph";
import PostPopup from "../../ui/post-popup/PostPopup";

import "./Contact.css";

export default function Contact() {
  const { langData } = useContext(ContactFormContext);
  const [userData, setUserData] = useState({
    userName: "",
    userTel: "",
    userMail: "",
    userMsg: "",
    userCheck: false,
  });
  const [isLoading, seIsLoading] = useState(false);
  const [isLoaded, seIsLoaded] = useState(false);
  const [isError, setIsError] = useState({
    telIsError: false,
    nameIsError: false,
    mailIsError: false,
    msgIsError: false,
    checkIsError: false,
  });

  const formId = "contactPageForm";

  const componentClass = "contact";
  const formClass = `form`;
  const formContainerClass = `${componentClass}__${formClass} ${formClass}`;
  const boxClass = `${formClass}__box`;
  const labelClass = `${formClass}__label`;
  const inputBoxClass = `${formClass}__input-box`;
  const inputClass = `${formClass}__input`;
  const errorInputClass = `${inputClass} error`;
  const labelCheckClass = `${formClass}__label-checkbox`;
  const inputBoxCheckClass = `${formClass}__input-checkbox-box`;
  const inputCheckClass = `${formClass}__input-checkbox`;
  const errorInputCheckClass = `${inputCheckClass} error`;
  const checkedInputCheckClass = `${inputCheckClass} checked`;

  function setCheckboxClass(checkIsError, checkIsChecked) {
    if (checkIsError) {
      return errorInputCheckClass;
    }

    if (checkIsChecked) {
      return checkedInputCheckClass;
    }

    return inputCheckClass;
  }

  function postUserData() {
    const nameValid = checkName(userData.userName);
    const telValid = checkTel(userData.userTel);
    const mailValid = checkMail(userData.userMail);
    const msgValid = checkMsg(userData.userMsg);
    const checkValid = userData.userCheck;

    if (!nameValid || !telValid || !mailValid || !msgValid || !checkValid) {
      setIsError({
        nameIsError: !nameValid,
        telIsError: !telValid,
        mailIsError: !mailValid,
        msgIsError: !msgValid,
        checkIsError: !checkValid,
      });
    }

    if (nameValid && telValid && mailValid && msgValid && checkValid) {
      seIsLoading(true);
      postForm(userData, formId, seIsLoaded, seIsLoading);
      setUserData({
        userName: "",
        userTel: "",
        userMail: "",
        userMsg: "",
        userCheck: false,
      });

      document
        .querySelector(".form__input-checkbox")
        .removeAttribute("checked");
    }
  }

  function checkName(name) {
    const namePattern = new RegExp("^[a-zA-Zа-яА-ЯІіЇїҐґЄє ]{3,}$");
    return namePattern.test(name);
  }

  function checkTel(tel) {
    const telPattern = new RegExp("^[0-9+() ]{10,}$");
    return telPattern.test(tel);
  }

  function checkMail(mail) {
    if (mail.length === 0) {
      return true;
    } else {
      const mailPattern = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$");
      return mailPattern.test(mail);
    }
  }

  function checkMsg(msg) {
    return msg.length !== 0;
  }

  if (Boolean(langData)) {
    const namePlaceholder = langData.userName.placeholder;
    const telPlaceholder = langData.userTel.placeholder;
    const mailPlaceholder = langData.userMail.placeholder;
    const msgPlaceholder = langData.userMessage.placeholder;

    return (
      <>
        <main className={componentClass} id={componentClass}>
          <section className={formContainerClass}>
            <Title parentClass={formClass} index={1}>
              {langData.titleText.toUpperCase()}
            </Title>
            <Paragraph parentClass={formClass}>
              {langData.requiredText}
            </Paragraph>
            <form name="contact-form" action="#" className={boxClass}>
              <div className={inputBoxClass}>
                <label className={labelClass} htmlFor="userName">
                  {langData.userName.label}
                </label>
                <input
                  value={userData.userName}
                  className={isError.nameIsError ? errorInputClass : inputClass}
                  type="text"
                  name="userName"
                  placeholder={namePlaceholder}
                  onChange={(e) => {
                    isError.nameIsError &&
                      setIsError({ ...isError, nameIsError: false });
                    setUserData({
                      ...userData,
                      userName: e.currentTarget.value,
                    });
                  }}
                />
              </div>
              <div className={inputBoxClass}>
                <label className={labelClass} htmlFor="userMail">
                  {langData.userMail.label}
                </label>
                <input
                  value={userData.userMail}
                  className={isError.mailIsError ? errorInputClass : inputClass}
                  type="email"
                  name="userMail"
                  placeholder={mailPlaceholder}
                  onChange={(e) => {
                    isError.mailIsError &&
                      setIsError({ ...isError, mailIsError: false });
                    setUserData({
                      ...userData,
                      userMail: e.currentTarget.value,
                    });
                  }}
                />
              </div>
              <div className={inputBoxClass}>
                <label className={labelClass} htmlFor="userTel">
                  {langData.userTel.label}
                </label>
                <input
                  value={userData.userTel}
                  className={isError.telIsError ? errorInputClass : inputClass}
                  type="tel"
                  name="userTel"
                  placeholder={telPlaceholder}
                  onChange={(e) => {
                    isError.telIsError &&
                      setIsError({ ...isError, telIsError: false });
                    setUserData({
                      ...userData,
                      userTel: e.currentTarget.value,
                    });
                  }}
                />
              </div>
              <div className={inputBoxClass}>
                <label className={labelClass} htmlFor="userMsg">
                  {langData.userMessage.label}
                </label>
                <textarea
                  value={userData.userMsg}
                  className={isError.msgIsError ? errorInputClass : inputClass}
                  type="text"
                  name="userMsg"
                  placeholder={msgPlaceholder}
                  onChange={(e) => {
                    isError.msgIsError &&
                      setIsError({ ...isError, msgIsError: false });
                    setUserData({
                      ...userData,
                      userMsg: e.currentTarget.value,
                    });
                  }}
                />
              </div>
              {/* <div className={inputBoxCheckClass}>
                <input
                  className={setCheckboxClass(
                    isError.checkIsError,
                    userData.userCheck
                  )}
                  type="checkbox"
                  name="userCheck"
                  onClick={() => {
                    isError.checkIsError &&
                      setIsError({ ...isError, checkIsError: false });
                    setUserData({
                      ...userData,
                      userCheck: !userData.userCheck,
                    });
                  }}
                />
                <label className={labelCheckClass} htmlFor="userCheck">
                  {langData.checkboxText}
                </label>
              </div> */}
              <Btn parentClass={formClass} onClick={postUserData}>
                {langData.btnText.toUpperCase()}
              </Btn>
              <Paragraph parentClass={formClass}>
                {langData.subBtnText}
              </Paragraph>
            </form>
            <PostPopup
              parentClass={formClass}
              isLoaded={isLoaded}
              isLoading={isLoading}
            />
          </section>
        </main>
      </>
    );
  }

  return null;
}
