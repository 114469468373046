import { useContext } from "react";
import { IsMobileContext } from "../../contexts/IsMobileContext";
import { MenuContext } from "../../contexts/MenuContext";

import MenuNav from "./nav/MenuNav";
import MenuMsgrLinks from "./msgr-links/MenuMsgrLinks";
import MenuContacts from "./contacts/MenuContacts";
import LangSelect from "../ui/btns/lang-select/LangSelect";

import "./Menu.css";

export default function Menu() {
  const { isMobile } = useContext(IsMobileContext);
  const { langData } = useContext(MenuContext);

  const componentClass = "menu";

  if (Boolean(langData)) {
    return (
      <main className={componentClass}>
        <MenuNav parentClass={componentClass} />
        <MenuContacts parentClass={componentClass} />
        {isMobile && <LangSelect parentClass={componentClass} />}
        <MenuMsgrLinks parentClass={componentClass} />
      </main>
    );
  }

  return null;
}
